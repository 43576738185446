import React from "react";
import "./notes.css";
import { connect } from "react-redux";
import { getNotes } from "../../services/apiNotes";
import i18next from "i18next";
import { Row, Space, Col,  Tabs, List, Avatar } from 'antd';
import notesIcons from "../shared/utils/notesIcons";

const { TabPane } = Tabs;

class Notes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiLoading: false,
      windowSize: [0, 0],
      notes: []
    };
  }

  _getNotes() {
    if (this.props.language && !this.state.apiLoading) {
      getNotes(this.props.language)
        .then(res => { 
          this.setState({ notes: res.data });
          this.setState({ apiLoading: false });
        })
        .catch(err => {
          console.error(err)
          this.setState({ apiLoading: false });
        })
    }
  }

  componentDidMount() {
    this._useWindowSize();
    this._getNotes();
    window.addEventListener('resize', this._useWindowSize);
  }

  componentDidUpdate() {
    // this._getNotes();
  }

  _useWindowSize = () => {
    this.setState({ 
      windowSize: [window.innerWidth, window.innerHeight] 
    });
  }

  render() {

    const notes = this.state.notes;

    const isMobile = 
      this.state.windowSize[0] <= 900 ? true : false;

    return (
      <div className="patchNotes">
        <Row>
          <Col xs={24} md={8}>
            <Space direction="horizontal" 
              style={{marginBottom:32, display: "flex", alignItems: "center"}}>
              <h1 className="mainTitle">
                {i18next.t("notes.title")}
              </h1>
            </Space>
          </Col>
        </Row>

        <Tabs defaultActiveKey="0" tabPosition={`${isMobile ? "top" : "left"}`}>
          {notes.slice(0).reverse().map((item, idx) => (
            <TabPane tab={item.version} key={idx}>
              <List
                itemLayout="horizontal"
                size="large"
                header={<div>{item.header}</div>}
                footer={<div>{item.footer}</div>}
                dataSource={item.data}
                bordered
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={notesIcons(item.category)} />}
                      title={<div>({item.category}) {item.title}</div>}
                      description={item.copy}
                      className={`_${item.category.replace(' ', '')}`}
                    />
                  </List.Item>
                )}
              />

              <div style={{marginBottom:32}}>{ }</div>
            </TabPane>
          ))}
        </Tabs>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  language: state.main.language
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);