import api from "./api";
import { 
  formatStartDate, 
  formatEndDate, 
  formatDateOperationsDayHourView, 
  formatDateOperationsDayView
} from '../components/shared/utils/dateUtility';
import { getUsers } from "./apiUsersService";

export function getEnabledOrderList(exchange) {
  return new Promise((resolve) => {
    api
      .get("/exchange/getOrderList", { exchange, active: true })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        } else {
          resolve([]);
        }
      });
  });
}

export function getDisabledOrderList(exchange) {
  return new Promise((resolve) => {
    api
      .get("/exchange/getOrderList", { exchange, active: false })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        } else {
          resolve([]);
        }
      });
  });
}

export function newOrder(exchange, order) {
  //TODO: Implementar tratamento de erro para retornar ao usuário
  return new Promise((resolve) => {
    api
      .post(`/exchange/newOrder?exchange=${exchange}`, order)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201) && !!res.data) {
          resolve(res.data);
        } else {
          resolve({ error: true });
        }
      })
      .catch(() => {
        resolve({ error: true });
      });
  });
}

export function cancelOrder(exchange, partOrder) {
  return new Promise((resolve) => {
    api
      .post(`/exchange/cancelOrder?exchange=${exchange}`, partOrder)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201) && !!res.data) {
          resolve(res.data);
        } else {
          resolve({ error: true });
        }
      })
      .catch(() => {
        resolve({ error: true });
      });
  });
}

export function getOperationsFilterUsersContent(term) {
  return new Promise((resolve) => {
    api.get("/users", { term }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([])
    })
  })
}

export function getOperationsFiltersContent() {
  return new Promise(async (resolve) => {

    const filters = {
      getMap: [
        { 
          name: "bots",
          get: "/exchange/getBotNameList",
          config: { exchange: "binance" } 
        },
        { 
          name: "symbols", 
          get: "/exchange/findAllSymbol",
          config: { exchange: "binance" } 
        },
      ],
      bots: [],
      symbols: []
    };

    const _fn = (endpoint, params) => {
      return new Promise((resolve) => {
        api.get(endpoint, params).then((res) => {
          if (res && res.status === 200 && !!res.data) {
            resolve(res.data);
          }
          resolve([]);
        });
      })
    };

    const map = filters.getMap.map(async (filter) => {
      filters[filter.name] = await _fn(filter.get, filter.config);
    });

    await Promise.all(map);

    resolve(filters);
  });
}

export function getOperationsDetailsService(globalProps, params) {
  return new Promise((resolve) => {

    const { rangeDate } = globalProps.operationsFiltersApplyed;

    const { clientId, configId, symbol } = params;

    let botType;
    switch (params.bot) {
      case 'T-One':
        botType = 1;
        break;
      case 'T-Package':
        botType = 2;
        break;
      case 'T-Profit':
        botType = 3;
        break;
      case 'T-Profit Plus':
        botType = 4;
        break;
      case 'T-Profit PG':
        botType = 7;
        break;
      default:
        botType = 1;
        break;
    }

    let config = {};
    config.configId = configId;
    config.startDate = formatStartDate(rangeDate[0]);
    config.endDate = formatEndDate(rangeDate[1]);
    config.bot = botType;
    config.clients = [clientId];
    config.type = 3;
    config.symbol = symbol;
    config.exchange = "binance";

    api.post(`/reports/user?exchange=${config.exchange}`, config)
      .then((res) => {
        if (res && res.status === 201 && !!res.data) {

          const operationsListGroup = res.data.reduce((r, a) => {
            r[a.operationId] = r[a.operationId] || {};
            r[a.operationId][a.side] = r[a.operationId][a.side] || {};
            r[a.operationId][a.side] = a;
            return r;
          }, Object.create(null));
          
          const data = [];

          Object.keys(operationsListGroup).forEach(key => {
            let item = operationsListGroup[key];

            return data.push({ 
              key: item.BUY.operationId,
              buyDate: formatDateOperationsDayHourView(item.BUY.startDate),
              buyQty: item.BUY.executedQuantity,
              buyPrice: item.BUY.executedPrice,
              buyTotal: item.BUY.executedValue,

              sellDate: formatDateOperationsDayHourView(item.SELL.endDate),
              sellQty: item.SELL.executedQuantity,
              sellPrice: item.SELL.executedPrice,
              sellTotal: item.SELL.executedValue,

              resultTax: item.SELL.fee,
              resultPercent: item.SELL.profit,
              resultTotal: item.SELL.earning,
            })
          })

          resolve({data, config})
        }
        resolve([]);
      })
  })
}

export function getOperationsResumeService(globalProps, params) {
  return new Promise((resolve) => {

    const { rangeDate, bot } = globalProps.operationsFiltersApplyed;

    const { client, clientId, symbol } = params;

    
    let config = {};
    config.startDate = formatStartDate(rangeDate[0]);
    config.endDate = formatEndDate(rangeDate[1]);
    config.bot = parseInt(bot ? bot : 0);
    config.clients = [clientId];
    config.type = 2;
    config.symbol = symbol;
    config.exchange = "binance";

    api.post(`/reports/user?exchange=${config.exchange}`, config)
      .then((res) => {
        if (res && res.status === 201 && !!res.data) {

          let data = [];

          res.data.map((item, i) => 
          data.push({ 
              key: i,
              client: client,
              clientId: item.clientId,
              configId: item.configId,
              symbol: symbol,
              date: formatDateOperationsDayView(item.createdAt),
              duration: item.duration? item.duration: '-',
              exchange: config.exchange,
              bot: item.bot.name,
              investment: item.investment,
              earning: item.earning,
              profit: item.profit 
            }));

            resolve({data, config});
        }
        resolve([]);
      })
  })
}

export function getOperationsMainService(filters) {
  return new Promise((resolve) => {

    const { rangeDate, bot, symbol } = JSON.parse(filters);

    let params = {};
    params.startDate = formatStartDate(rangeDate[0]);
    params.endDate = formatEndDate(rangeDate[1]);
    params.bot = parseInt(bot ? bot : 0);
    params.type = 1; //main-view
    params.symbol = symbol ? symbol : null;
    params.exchange = "binance";
    
    api.post(`/reports/user?exchange=${params.exchange}`, params)
      .then(async (res) => {
        if (res && res.status === 201 && !!res.data) {

          if (!res.data.message){
            let usersListById = 
              res.data.reduce((list, item) => {
                list.push(item.clientId);
                return list;
              }, []);

            usersListById = [ ...new Set(usersListById) ];

            const usersByApi = await getUsers(usersListById)
              .then(users => {
                return users;
              })

            await Promise.all(usersByApi);

            let data = [];

            res.data.map((item, i) => 
            data.push({ 
              key: i, 
              clientId: item.clientId,            
              exchange: 'Binance', 
              symbol: item.symbol, 
              earning: item.earning
            }))

            resolve({data, params});
        } else {
          resolve([]);
          }
        }
        resolve([]);
      })
  })
}

export function getOperationsExport(props) {
  return new Promise((resolve) => {
    api
    .post(`/reports/user/export?exchange=binance&type=pdf`, props)
      .then(async (res) => {
        if (res && res.status === 201 && !!res.data) {
          resolve(res.data);
        }
        resolve(false);
      });
  })
}