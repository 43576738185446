import React from "react";
import "./robotEditing.css";
import { connect } from "react-redux";
import i18next from "i18next";
import { Form } from "antd";
import {
  editConfigPackageBotAsync,
  editBotAsync,
  findBotAsync,  
  changeBot,  
  clearSettings,
  updateLoading,
  updateValueParam,
  startBotAsync
} from "../robotSlice";

import { botOne, botPackage, botProfit, botProfitPlus, fieldsDefault,  editBotProfitPG, robotRegisterTypeProfitPG,
  editBotProfitPlus, editBotOne, editBotPackage, editBotProfit } from './components/robotRegisterTypes';

import { findAllBotTypeAsync } from "../../../shared/botParamSlice";
import {RobotRegisterFooter} from './components/robotRegisterFooter';

const layout = {
  labelCol: {
    span: 9,
    offset: 0,
  },
};

const mapBotComponents = {
  0: fieldsDefault,
  1: botOne,
  2: botPackage,
  3: botProfit,
  4: botProfitPlus,
  7: robotRegisterTypeProfitPG
};

const mapBotComponentsEdit = {
  1: editBotOne,
  2: editBotPackage,
  3: editBotProfit,
  4: editBotProfitPlus,
  7: editBotProfitPG
}

class RobotEditing extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.onClose = this.onClose.bind(this);
    this.setBot = this.setBot.bind(this);
    this.state = {
      windowSize: [0, 0]
    }
  }

  setBot() {
    if ((this.props.editing || this.props.duplicating) && this.formRef.current) {
      this.props.findBotAsync(
          this.props.exchangeName,
          this.props.robot.id,
          this.props.robot.bot.type)
        .then(() => {
          this.props.changeBot(this.props.robot.bot.type)          
          const robotEditing = mapBotComponentsEdit[this.props.robot.bot.type]
          robotEditing(this.formRef, this.props.packageBot);
        });
    }
  }

  componentDidMount() {
    this.setState({ windowSize: [window.innerWidth, window.innerHeight] })
    this.props.findAllBotTypeAsync(this.props.exchangeName).then(() => {
      this.setBot();
    });
  }

  componentDidUpdate(prevProps) {
    
    const prevDateTime = prevProps.date_time;
    const currentDateTime = this.props.date_time;
    const currentRobotId = (this.props.robot && this.props.robot.id) || 0;
    if (prevDateTime !== currentDateTime && currentRobotId !== 0) {
      this.setBot();
    } else {
      this.formRef.current.resetFields();
    }
  }

  onClose() {
    this.props.closeDrawer();
    this.props.clearSettings();
  }  

  onFinish = (valueParam) => {
    valueParam = {...valueParam, type: this.props.robot.bot.type, symbol: this.props.robot.symbol}
    this.props.updateValueParam(valueParam);
    this.props.updateLoading(true);
    this.props.changeBot(this.props.robot.bot.type);
    if (this.props.editing) {
      const editingValueParam = {...valueParam,  id: this.props.robot.id}
      this.props.editBotAsync(this.props.exchangeName, editingValueParam).then(() => {
      this.props.updateLoading(false);
      this.onClose();      
      });
    } else {
      if (this.props.robot.bot.type === 2 || this.props.robot.bot.type === 4) {
        valueParam = {...valueParam, investment: (valueParam.total / valueParam.quantity)}
      }
      this.props.startBotAsync(this.props.exchangeName, valueParam);      
      this.onClose();
    }
  }

  render() {
    const RobotFields = mapBotComponents[this.props.currentBot];      

    return (
      <div>
        <h2 style={{ fontSize: 15, padding: 0, margin: 0 }}>
          {this.props.duplicating ? 
          i18next.t("trades.register.label.duplicating")  : 
          i18next.t("trades.register.label.editing")} 
        </h2>
        <div style={{ height: 20 }}></div>
        <Form
          {...layout}
          ref={this.formRef}
          name="control-ref"
          onFinish={this.onFinish}
          size="small"
        >
          <RobotFields 
            props={this.props} 
            state={this.state}
            editing={this.props.packageBot}             
            refFields={this.formRef}
            />          
          <RobotRegisterFooter
            props={this.props} 
            onClose={this.onClose} />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allSymbol: state.coin.allSymbol,
  botTypeList: state.botParam.botTypeList,
  packageBot: state.robotSlice.packageBot,
  currentBot: state.robotSlice.currentBot,
  profitPgStep: state.robotSlice.profitPgStep,
  pgSimulateResults: state.robotSlice.pgSimulateResults,
  loading: state.robotSlice.loading,
  valueParam: state.robotSlice.valueParam
});

const mapDispatchToProps = (dispatch) => {
  return {
    findAllBotTypeAsync: (exchangeName) => dispatch(findAllBotTypeAsync(exchangeName)),
    editConfigPackageBotAsync: (exchangeName, robot) => dispatch(editConfigPackageBotAsync(exchangeName, robot)),
    editBotAsync: (exchangeName, robot) => dispatch(editBotAsync(exchangeName, robot)),
    findBotAsync: (exchange, id, type) => dispatch(findBotAsync(exchange, id, type)),    
    changeBot: key => dispatch(changeBot(key)),    
    updateLoading: key => dispatch(updateLoading(key)),
    clearSettings: () => dispatch(clearSettings()),
    updateValueParam: (params) => dispatch(updateValueParam(params)),   
    startBotAsync: (exchangeName, robot) => dispatch(startBotAsync(exchangeName, robot)), 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RobotEditing);