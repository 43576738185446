import i18next from "i18next";
import { convertValuesCoins } from '../../../../shared/utils/convertValuesCoins';
import { FileTextOutlined,RollbackOutlined } from "@ant-design/icons";
import { Button, Tag, Space} from "antd";
import TableViewBotOnePackagesColumns from './TableViewBotOnePackagesColumns'
import { getUTCDate } from "../../../../shared/utils/dateUtility";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  AlertOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

const TableViewBotProfitsColumns = (method) => { 

  const statusTableDetails = {
    WAITING_LIMIT: {
      caption: `${i18next.t(
        "trades.view.status_table_details.WAITING_LIMIT"
      )}`,
      color: "default",
      icon: <ClockCircleOutlined />,
    },
    INVALID_SYMBOL: {
      caption: `${i18next.t(
        "trades.view.status_table_details.INVALID_SYMBOL"
      )}`,
      color: "default",
      icon: <AlertOutlined />,
    },
    ERROR_BUY: {
      caption: `${i18next.t("trades.view.status_table_details.ERROR_BUY")}`,
      color: "default",
      icon: <AlertOutlined />,
    },
    INITIALIZING: {
      caption: `${i18next.t(
        "trades.view.status_table_details.INITIALIZING"
      )}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    STARTING: {
      caption: `${i18next.t(
        "trades.view.status_table_details.INITIALIZING"
      )}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    DONE: {
      caption: `${i18next.t("trades.view.status_table_details.DONE")}`,
      color: "default",
      icon: <CheckCircleOutlined />,
    },
    CANCELED: {
      caption: `${i18next.t("trades.view.status_table_details.CANCELED")}`,
      color: "default",
      icon: <CloseCircleOutlined />,
    },
    PROCESSING_REINVEST: {
      caption: `${i18next.t("trades.view.status_table_details.CANCELED")}`,
      color: "default",
      icon: <CloseCircleOutlined />,
    },
    STOPPED: {
      caption: `${i18next.t("trades.view.status_table_details.STOPPED")}`,
      color: "default",
      icon: <MinusCircleOutlined />,
    },
    PLACING_BUY_ORDER: {
      caption: `${i18next.t("trades.view.status_table_details.BUYING")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    BUYING: {
      caption: `${i18next.t("trades.view.status_table_details.BUYING")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    EXECUTED_BUY_ORDER: {
      caption: `${i18next.t("trades.view.status_table_details.BUYING")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    PLACING_SELL_ORDER: {
      caption: `${i18next.t("trades.view.status_table_details.SELLING")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    PLACED_SELL_ORDER: {
      caption: `${i18next.t("trades.view.status_table_details.SELLING")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    SELLING: {
      caption: `${i18next.t("trades.view.status_table_details.SELLING")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    WATCHING_MARKET: {
      caption: `${i18next.t(
        "trades.view.status_table_details.WATCHING_MARKET"
      )}`,
      color: "default",
      icon: <EyeOutlined />,
    },
    PARTIAL_REINVESTING: {
      caption: `${i18next.t(
        "trades.view.status_table_details.PARTIAL_REINVESTING"
      )}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    ERROR: {
      caption: `${i18next.t("trades.view.status_table_details.ERROR")}`,
      color: "default",
      icon: <AlertOutlined />,
    },
  };

  const columnsTableDefaultProfitDetails = [
    {
      title: `${i18next.t("trades.view.columns_table_details.status")}`,
      dataIndex: "status",
      key: "status",               
      render: (_, record) => {
        let status;
        if (record && record.status in statusTableDetails) {
          status = record.status;
        } else {
          status = "ERROR";
        }
        const caption = statusTableDetails[status].caption;
        const color = statusTableDetails[status].color;
        const icon = statusTableDetails[status].icon;
        return (
          <Tag className="tag-status-details-rb" icon={icon} color={color}>
            {caption}
          </Tag>
        );
      },
    },
    {
      title: `${i18next.t("trades.view.columns_table_details.maxPrice")}`,
      dataIndex: "maxPrice",
      key: "maxPrice",
      width: '150px',
      render: (_, record) => convertValuesCoins(record.maxPrice),
    },
    {
      title: `${i18next.t("trades.view.columns_table_details.minPrice")}`,
      dataIndex: "minPrice",
      key: "minPrice",
      render: (_, record) => convertValuesCoins(record.minPrice),
    },
    {
      title: `${i18next.t("trades.view.columns_table_details.quantity")}`,
      dataIndex: "quantity",
      key: "quantity",
      render: (_, record) => `${record.operations.quantity}`,
    },
    {
      title: `${i18next.t(
        "trades.view.columns_table_details.operations_profit"
      )}`,
      dataIndex: "operations.profit",
      key: "operations.profit",
      render: (_, record) => `${record.operations.profit}`,
    },
    {
      title: `${i18next.t(
        "trades.view.columns_table_details.operations_earning"
      )}`,
      dataIndex: "operations.earning",
      key: "operations.earning",
      render: (_, record) => `${convertValuesCoins(record.operations.earning)}`
    },
    {
      title: `${i18next.t(
        "trades.view.columns_table_details.currentProfit"
      )}`,
      dataIndex: "currentProfit",
      key: "currentProfit",
      width: '150px',
      render: (_, record) => record.currentProfit !== 0? (parseFloat(record.currentProfit) - 0.2).toFixed(2) : record.currentProfit
    }
  ]

  const columnsTableProfitPlusDetails = [
    ...columnsTableDefaultProfitDetails,
    {
      title: `${i18next.t("trades.view.columns_table_details.view")}`,
      dataIndex: "view",
      key: "view",
      align: "center",
      render: (_, record) => (
        <Space size={3}>
          <Button
            ghost
            className="table-btn"
            onClick={() => {
              window.TICODRobotView.onViewProfitPlusDetails(record)
            }}
          >
            <FileTextOutlined />
          </Button>
          {!window.TICODRobotView.getReinvestmentOperations() ? (
            <Button
              ghost
              className="table-btn"
              onClick={() => {
                window.TICODRobotView.onReinvestRecord(record);
              }}
            >
              <RollbackOutlined />
            </Button>
          ) : (
            <></>
          )}
        </Space>
      ),
    },
  ];

  const columnsTableProfitDetails = [
    ...columnsTableDefaultProfitDetails,
    {
      title: `${i18next.t("trades.view.columns_table_details.view")}`,
      dataIndex: "view",
      key: "view",
      align: "center",
      render: (_, record) => (
        <Space size={3}>
          <Button
            ghost
            className="table-btn"
            onClick={() => {
              window.TICODRobotView.onViewProfitDetails(record)
            }}
          >
            <FileTextOutlined />
          </Button>
          {!window.TICODRobotView.getReinvestmentOperations() ? (
            <Button
              ghost
              className="table-btn"
              onClick={() => {
                window.TICODRobotView.onReinvestRecord(record);
              }}
            >
              <RollbackOutlined />
            </Button>
          ) : (
            <></>
          )}
        </Space>
      ),
    },
  ];

  const columnsTableProfitPgDetails = [
    {
      title: `${i18next.t("trades.view.columns_table_details.status")}`,
      dataIndex: "status",
      key: "status",        
      render: (_, record) => {
        let status;
        if (record && record.status in statusTableDetails) {
          status = record.status;
        } else {
          status = "ERROR";
        }
        const caption = statusTableDetails[status].caption;
        const color = statusTableDetails[status].color;
        const icon = statusTableDetails[status].icon;
        return (
          <Tag className="tag-status-details-rb" icon={icon} color={color}>
            {caption}
          </Tag>
        );
      },
    },
    {
      title: `${i18next.t("trades.view.columns_table_details.maxPrice")}`,
      dataIndex: "maxPrice",
      key: "maxPrice",
      render: (_, record) => convertValuesCoins(record.maxPrice),
    },
    {
      title: `${i18next.t("trades.view.columns_table_details.minPrice")}`,
      dataIndex: "minPrice",
      key: "minPrice",
      render: (_, record) => convertValuesCoins(record.minPrice),
    },
    {
      title: `${i18next.t("trades.view.columns_table_details.quantity")}`,
      dataIndex: "quantity",
      key: "quantity",
      render: (_, record) => `${record.operations.quantity}`,
    },
    {
      title: `${i18next.t("trades.view.columns_table_details.investment")}`,
      dataIndex: "investment",
      key: "investment",
      render: (_, record) => `${record.investment}`,
    },
    {
      title: `${i18next.t(
        "trades.view.columns_table_details.operations_profit"
      )}`,
      dataIndex: "operations.profit",
      key: "operations.profit",
      render: (_, record) => `${record.operations.profit}`,
    },
    {
      title: `${i18next.t(
        "trades.view.columns_table_details.operations_earning"
      )}`,
      dataIndex: "operations.earning",
      key: "operations.earning",
      render: (_, record) => `${convertValuesCoins(record.operations.earning)}`,
    },
    {
      title: `${i18next.t(
        "trades.view.columns_table_details.currentProfit"
      )}`,
      dataIndex: "currentProfit",
      key: "currentProfit",
      render: (_, record) => record.currentProfit !== 0? (parseFloat(record.currentProfit) - 0.2).toFixed(2) : record.currentProfit
    },
    {
      title: `${i18next.t("trades.view.columns_table_details.view")}`,
      dataIndex: "view",
      key: "view",
      align: "center",
      render: (_, record) => (
        <Space size={3}>
          <Button
            ghost
            className="table-btn"
            onClick={() => {
              window.TICODRobotView.onViewPGDetails(record)
            }}
          >
            <FileTextOutlined />
          </Button>
          {!window.TICODRobotView.getReinvestmentOperations() ? (
            <Button
              ghost
              className="table-btn"
              onClick={() => {
                window.TICODRobotView.onReinvestRecord(record);
              }}
            >
              <RollbackOutlined />
            </Button>
          ) : (
            <></>
          )}
        </Space>
      ),
    },
  ];

  const columnsTableProfitPlusSetupDetails = [
    {
      title: "",
      children: [
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.status"
          )}`,
          dataIndex: "status",
          key: "status",
          render: (_, record) => {
            let status;
            if (record && record.status in TableViewBotOnePackagesColumns('statusTableOperationsDetails')) {
              status = record.status;
            } else {
              status = "ERROR";
            }
            const caption = TableViewBotOnePackagesColumns('statusTableOperationsDetails')[status].caption;
            const color = TableViewBotOnePackagesColumns('statusTableOperationsDetails')[status].color;
            const icon = TableViewBotOnePackagesColumns('statusTableOperationsDetails')[status].icon;
            return (
              <Tag className="tag-status-rb" icon={icon} color={color}>
                {caption}
              </Tag>
            );
          },
        },
      ],
      align: "left",
    },
    {
      title: `${i18next.t(
        "trades.view.columns_table_setup_details.label_compra"
      )}`,
      children: [
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.dataCompra"
          )}`,
          dataIndex: "dataCompra",
          key: "dataCompra",
          width: '250px',
          render: (_, record) => {
            return (
              <div style={{ color: "green" }}>
                {record.buyOrder && record.buyOrder.createdAt
                  ? getUTCDate(record.buyOrder.createdAt)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.quantidadeCompra"
          )}`,
          dataIndex: "quantidadeCompra",
          key: "quantidadeCompra",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "green" }}>
                {record.buyOrder && record.buyOrder.quantity
                  ? convertValuesCoins(record.buyOrder.quantity)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.precoCompra"
          )}`,
          dataIndex: "precoCompra",
          key: "precoCompra",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "green" }}>
                {record.buyOrder && record.buyOrder.price
                  ? convertValuesCoins(record.buyOrder.price)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.valorCompra"
          )}`,
          dataIndex: "valorCompra",
          key: "valorCompra",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "green" }}>
                {record.buyOrder && record.buyOrder.value
                  ? convertValuesCoins(record.buyOrder.value)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.taxaCompra"
          )}`,
          dataIndex: "taxaCompra",
          key: "taxaCompra",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "green" }}>
                {record.buyOrder && record.buyOrder.fee
                  ? convertValuesCoins(record.buyOrder.fee)
                  : ""}
              </div>
            );
          },
        },
        {
          title: "",
          dataIndex: "void",
          key: "void",
          align: "right",
          render: "",
        },
      ],
      align: "left",
    },
    {
      title: `${i18next.t(
        "trades.view.columns_table_setup_details.label_venda"
      )}`,
      children: [
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.dataVenda"
          )}`,
          dataIndex: "dataVenda",
          key: "dataVenda",
          width: '250px',
          render: (_, record) => {
            return (
              <div style={{ color: "red" }}>
                {record.sellOrder && record.sellOrder.createdAt
                  ? getUTCDate(record.sellOrder.createdAt)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.quantidadeVenda"
          )}`,
          dataIndex: "quantidadeVenda",
          key: "quantidadeVenda",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "red" }}>
                {record.sellOrder && record.sellOrder.quantity
                  ? convertValuesCoins(record.sellOrder.quantity)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.precoVenda"
          )}`,
          dataIndex: "precoVenda",
          key: "precoVenda",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "red" }}>
                {record.sellOrder && record.sellOrder.price
                  ? convertValuesCoins(record.sellOrder.price)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.valorVenda"
          )}`,
          dataIndex: "valorVenda",
          key: "valorVenda",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "red" }}>
                {record.sellOrder && record.sellOrder.value
                  ? convertValuesCoins(record.sellOrder.value)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.taxaVenda"
          )}`,
          dataIndex: "taxaVenda",
          key: "taxaVenda",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "red" }}>
                {record.sellOrder && record.sellOrder.fee
                  ? convertValuesCoins(record.sellOrder.fee)
                  : ""}
              </div>
            );
          },
        },
        {
          title: "",
          dataIndex: "void",
          key: "void",
          align: "right",
          render: "",
        },
      ],
      align: "left",
    },
    {
      title: `${i18next.t(
        "trades.view.columns_table_setup_details.label_ganho"
      )}`,
      children: [
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.lucroPercent"
          )}`,
          dataIndex: "lucro",
          key: "lucro",
          width: '150px',
          render: (_, record) => `${record.profit}`,
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.lucroValor"
          )}`,
          dataIndex: "ganho",
          key: "ganho",
          width: '150px',
          render: (_, record) => `${convertValuesCoins(record.earning)}`,
        },
      ],
      align: "left",
    },
  ];

  const columnsTableProfitPgSetupDetails = [
    {
      title: "",      
      children: [
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.status"
          )}`,
          dataIndex: "status",
          key: "status",          
          render: (_, record) => {
            let status;
            if (record && record.status in  TableViewBotOnePackagesColumns('statusTableOperationsDetails')) {
              status = record.status;
            } else {
              status = "ERROR";
            }
            const caption = TableViewBotOnePackagesColumns('statusTableOperationsDetails')[status].caption;
            const color = TableViewBotOnePackagesColumns('statusTableOperationsDetails')[status].color;
            const icon = TableViewBotOnePackagesColumns('statusTableOperationsDetails')[status].icon;
            return (
              <Tag className="tag-status-rb" icon={icon} color={color}>
                {caption}
              </Tag>
            );
          },
        },
      ],
      align: "left",
    },
    {
      title: `${i18next.t(
        "trades.view.columns_table_setup_details.label_compra"
      )}`,
      children: [
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.dataCompra"
          )}`,
          dataIndex: "dataCompra",
          key: "dataCompra",
          width: '250px',
          render: (_, record) => {
            return (
              <div style={{ color: "green" }}>
                {record.buyOrder && record.buyOrder.createdAt
                  ? getUTCDate(record.buyOrder.createdAt)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.quantidadeCompra"
          )}`,
          dataIndex: "quantidadeCompra",
          key: "quantidadeCompra",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "green" }}>
                {record.buyOrder && record.buyOrder.quantity
                  ? convertValuesCoins(record.buyOrder.quantity)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.precoCompra"
          )}`,
          dataIndex: "precoCompra",
          key: "precoCompra",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "green" }}>
                {record.buyOrder && record.buyOrder.price
                  ? convertValuesCoins(record.buyOrder.price)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.valorCompra"
          )}`,
          dataIndex: "valorCompra",
          key: "valorCompra",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "green" }}>
                {record.buyOrder && record.buyOrder.value
                  ? convertValuesCoins(record.buyOrder.value)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.taxaCompra"
          )}`,
          dataIndex: "taxaCompra",
          key: "taxaCompra",
          align: "right",
          width: "150px",
          render: (_, record) => {
            return (
              <div style={{ color: "green" }}>
                {record.buyOrder && record.buyOrder.fee
                  ? convertValuesCoins(record.buyOrder.fee)
                  : ""}
              </div>
            );
          },
        },
        {
          title: "",
          dataIndex: "void",
          key: "void",
          align: "right",
          render: "",
          // width: "150px",
        },
      ],
      align: "left",
      // width: "150px",
    },
    {
      title: `${i18next.t(
        "trades.view.columns_table_setup_details.label_venda"
      )}`,
      children: [
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.dataVenda"
          )}`,
          dataIndex: "dataVenda",
          key: "dataVenda",
          width: '250px',
          render: (_, record) => {
            return (
              <div style={{ color: "red" }}>
                {record.sellOrder && record.sellOrder.createdAt
                  ? getUTCDate(record.sellOrder.createdAt)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.quantidadeVenda"
          )}`,
          dataIndex: "quantidadeVenda",
          key: "quantidadeVenda",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "red" }}>
                {record.sellOrder && record.sellOrder.quantity
                  ? convertValuesCoins(record.sellOrder.quantity)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.precoVenda"
          )}`,
          dataIndex: "precoVenda",
          key: "precoVenda",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "red" }}>
                {record.sellOrder && record.sellOrder.price
                  ? convertValuesCoins(record.sellOrder.price)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.valorVenda"
          )}`,
          dataIndex: "valorVenda",
          key: "valorVenda",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "red" }}>
                {record.sellOrder && record.sellOrder.value
                  ? convertValuesCoins(record.sellOrder.value)
                  : ""}
              </div>
            );
          },
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.taxaVenda"
          )}`,
          dataIndex: "taxaVenda",
          key: "taxaVenda",
          align: "right",
          width: '150px',
          render: (_, record) => {
            return (
              <div style={{ color: "red" }}>
                {record.sellOrder && record.sellOrder.fee
                  ? convertValuesCoins(record.sellOrder.fee)
                  : " "}
              </div>
            );
          },
        },
        {
          title: "",
          dataIndex: "void",
          key: "void",
          align: "right",
          render: "",
          // width: "150px",
        },
      ],
      align: "left",
      // width: "150px",
    },
    {
      title: `${i18next.t(
        "trades.view.columns_table_setup_details.label_ganho"
      )}`,
      children: [
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.lucroPercent"
          )}`,
          dataIndex: "lucro",
          key: "lucro",
          width: '150px',
          render: (_, record) => `${record.profit}`,
        },
        {
          title: `${i18next.t(
            "trades.view.columns_table_setup_details.lucroValor"
          )}`,
          dataIndex: "ganho",
          key: "ganho",
          width: "150px",
          render: (_, record) => `${convertValuesCoins(record.earning)}`,
        },
      ],
      align: "left",
      // width: "150px",
    },
  ];

  switch (method) {
    case 'columnsTableProfitPlusDetails':
      return columnsTableProfitPlusDetails;
    case 'columnsTableProfitDetails':
      return columnsTableProfitDetails;
    case 'columnsTableProfitPgDetails':
      return columnsTableProfitPgDetails;
    case 'columnsTableProfitPlusSetupDetails':
      return columnsTableProfitPlusSetupDetails;
    case 'columnsTableProfitPgSetupDetails':
      return columnsTableProfitPgSetupDetails;              
    default: break;
  }
}

export default TableViewBotProfitsColumns;