
const dev = {
  URL_API: 'https://dev-api.ticod.net',
  URL_LOGIN: 'https://dev-landing.ticod.net/',
  WS_URL_API: 'https://dev-ws-binance.ticod.net',
};

const prod = {
  URL_API: 'https://api.ticod.net',
  URL_LOGIN: 'https://www.ticod.net/',
  WS_URL_API: 'https://ws-binance.ticod.net',
};

const local = {
  URL_API: 'http://localhost:3002',
  URL_LOGIN: 'http://localhost:8088',
  // WS_URL_API: 'http://localhost:3067',
  WS_URL_API: 'https://dev-ws-binance.ticod.net'
};

const config = process.env.REACT_APP_STAGE === 'local'
  ? local
  : process.env.REACT_APP_STAGE === 'development'
    ? dev 
    : prod;

export default config;