import React, { useState } from "react";
import { SafetyCertificateFilled } from '@ant-design/icons';
import { Button, Modal, Form, Row, Col, Input, Switch } from "antd";
import './TwoFactor.css'
import QRCodeSVG from "qrcode.react";
import "../settings.css";
import i18next from "i18next";
import googlePlay from '../../../assets/google-play-badge.svg'
import appleStore from '../../../assets/apple-store-logo.svg'

const TwoFactor = ({props, state, formPreferencesTwoFactorAuthRef, onFinishPreferencesTwoFactorAuth, onFinishPreferencesRemoveTwoFactorAuth}) => {  

  const layout = {
    labelCol: {
      span: "100%",
      offset: 0,
    },
  };

  const handleToggleChecked2FA = (checked) => {
    props.visibleModalSecurityAsync(true)
    props.enableTwoFactorPayload();
    props.setToggleTwoFactorAuthAsync(checked)
  }

  const handleRemove2Fa = () => {
    onFinishPreferencesRemoveTwoFactorAuth()
  }

  const { loading } = state;

  const [page, setPage] = useState(1);

  const goToNext = () => {
    setPage(page + 1)
  };

  const goToPrev = () => {
    setPage(page - 1) 
  };

  return (
    <div>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',          
      }}>
        <div style={{
            display: 'flex', 
            alignItems: 'center'}}
        >
          <SafetyCertificateFilled className="icone-security"/> 
          <span>{i18next.t("settings.profile.2fa")}</span>
        </div>
        <Switch 
          checked={props.preferences?.twoFactorAuth} 
          onChange={handleToggleChecked2FA}
        />        
      </div>

      <Modal
        title={i18next.t("settings.tab_security")}
        width={400}
        visible={props.visibleModalSecurity && props.preferences?.twoFactorAuth}
        footer={null}
        onCancel={() => { props.visibleModalSecurityAsync(false)}}
      >
        <div>
          <h1 style={{ textAlign: 'center' }}>{i18next.t("settings.profile.two_factor.disable")}</h1>
          <h3 style={{ textAlign: 'center' }}>{i18next.t("settings.profile.two_factor.disable_are_you_sure")}</h3> 
          <div style={{ textAlign: 'center' }}>
            <Button
              type="danger"
              onClick={ () => { handleRemove2Fa() } }                   
              loading={ loading ? true : false}
            >
              {i18next.t("settings.profile.two_factor.disable_button")}
            </Button>  
          </div>        
        </div>
      </Modal>
          
      <Modal
        title={i18next.t("settings.profile.two_factor.title")}
        width={500}
        visible={props.visibleModalSecurity && !props.preferences?.twoFactorAuth}
        onCancel={() => { props.visibleModalSecurityAsync(false); setPage(1); }}
        footer={[
          <Button className="left" key="back" onClick={() => goToPrev()} disabled={page === 1}>
            {i18next.t("button.prev")}
          </Button>,
          <Button className="right" key="next" type="primary" onClick={() => goToNext()} disabled={page === 4}>
            {i18next.t("button.next")}
          </Button>
          
        ]}
      >
        <div>
          {page === 1 ? 
            <div style={{ textAlign: 'center' }}>
              <h3>{i18next.t("settings.profile.two_factor.step_1")}</h3> 
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <a 
                  target="_blank" 
                  rel="noreferrer" 
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                >
                  <img alt="google-play-logo" src={googlePlay} style={{ 
                    width: 150,
                    height: 70,
                    alignSelf: 'left'
                  }} />
                </a>
                <a 
                  target="_blank" 
                  rel="noreferrer" 
                  href="https://apps.apple.com/br/app/google-authenticator/id388497605"
                >
                  <img alt="apple-logo" src={appleStore} style={{ 
                    width: 150,
                    height: 70,
                    alignSelf: 'right'
                  }} />
                </a>
              </div>
            </div>
          : null}
          {page === 2 ? 
            <div>
              <div style={{ textAlign: "center" }}>
                <h3 style={{ marginBottom: 35 }}>{i18next.t("settings.profile.two_factor.step_2")}</h3>  
                <div>
                  <QRCodeSVG value={props.enableTwoFactor.otpAuthUrl} style={{ width: 160, height: 160 }}/>
                </div>
                <h3 style={{ marginTop: 35 }}>{i18next.t("settings.profile.two_factor.if_cant_scan")}</h3>
                <h2 >{props.enableTwoFactor.base32}</h2>
              </div>
            </div>
          : null}
          {page === 3 ?
            <div>
              <div style={{ textAlign: "center" }}>
                <h3 >{i18next.t("settings.profile.two_factor.step_3")}</h3> 
                <h2>{props.enableTwoFactor.base32}</h2>
              </div>
            </div>
          : null}
          {page === 4 ? 
            <div>
              <div>
                <h3>{i18next.t("settings.profile.two_factor.step_4")}</h3>
              </div>
              <Form
                {...layout}
                ref={formPreferencesTwoFactorAuthRef}
                name="control-preferences-ref"
                onFinish={onFinishPreferencesTwoFactorAuth}
              >
                <Row>
                  <Col style={{ height: 80 }} span={24}>
                    <div>
                      <Form.Item
                      name="authCode"  
                      className="profile-format"
                      label="Auth verification code"
                      rules={[
                        {
                          required: true,
                          len: 6,
                          message: 'input auth code'
                        },
                      ]}                             
                    >
                      <Input maxLength={6} style={{ width: "100%" }} />
                    </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row >            
                  <Col span={24}>
                    <div style={{textAlign: "right"}}>
                      <Button
                        type="primary"
                        htmlType="submit"                    
                        loading={ loading ? true : false}
                      >
                        {i18next.t("settings.profile.send")}
                      </Button>
                    </div>              
                  </Col>
                </Row>
              </Form>
            </div>
          : null}
        </div>
      </Modal>   
    </div>
  )
}

export default TwoFactor;