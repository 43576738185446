import React from "react";
import i18next from "i18next";
import { Button } from "antd";

export const RobotRegisterFooter = (params) => {
    const ButtonCancel = () => {
      return (
        <Button
          style={{ marginRight: 9 }}
          onClick={() => params.onClose()}
          disabled={params.props.loading ? true : false}>
          {i18next.t("button.cancel")}
        </Button>
      )
    };

    return ( 
      <div className="menu-bar">
        <div style={{textAlign:"right"}}>
          <ButtonCancel />
          <Button
            type="primary"
            htmlType="submit"
            loading={params.props.loading ? true : false}>
            {i18next.t("button.save")}
          </Button>
        </div>
     </div>
    )    
  }