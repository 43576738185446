import { createSlice } from "@reduxjs/toolkit";
import { findAllBotType } from "../../services/apiBotParamService";

export const botParamSlice = createSlice({
  name: "botParam",
  initialState: {
    botTypeList: [],
  },
  reducers: {
    setBotTypeList: (state, action) => {
      state.botTypeList = action.payload;
    },
  },
});

const { setBotTypeList } = botParamSlice.actions;

export const findAllBotTypeAsync = (exchangeName) => (dispatch) => {
  return findAllBotType(exchangeName).then((recordList) => {
    dispatch(setBotTypeList(recordList));
  });
};

export default botParamSlice.reducer;
