import React from "react";
import "./main.css";
import logo from "../../assets/logo-ticod.png";

import { getAccountAsync }from "../exchanges/exchangeSlice";

import { initLanguage, changeLanguage } from "./mainSlice";
import { connect } from "react-redux";

import i18next from "i18next";

import {
  PageHeader,
  Menu,
  Dropdown,
  Select,
  Image,
  Space,
  Row,
  Col,
  Badge,
  Tooltip,
  Spin
} from "antd";
import {
  UserOutlined,
  ShopOutlined,
  RobotOutlined,
  FundViewOutlined,
  ClusterOutlined,
  LogoutOutlined,
  SettingOutlined, 
  QuestionCircleFilled,
  SketchOutlined,
  DollarOutlined
} from "@ant-design/icons";

import brazil from "../../assets/languages/brazil.png";
import spain from "../../assets/languages/spain.png";
import united_states from "../../assets/languages/united-states.png";

import Trades from "../trades/trades";
import Operations from "../operations/operations";
import MainPortfolio from "../portfolio/main/mainPortfolio";
import Exchanges from "../exchanges/exchanges";
import Notes from "../notes";
import Settings from "../settings/settings";
import Plans from '../plans/plans';

import IconAvatar from "../shared/utils/iconAvatar";

import { getUser } from "../../services/apiAuthService";
import { getProfileAsync, getAvatarPayload } from "../settings/settingsSlice";

const { Option } = Select;

const mapMenuComponent = {
  trades: Trades,
  operations: Operations,
  mainPortfolio: MainPortfolio,
  exchanges: Exchanges,
  notes: Notes,
  settings: Settings,
  plans: Plans,
};

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.onChangeLanguage = this.onChangeLanguage.bind(this);
    this.state = {
      current: "trades",
      contentMenu: Trades,
      height: window.innerHeight,
      user: {},
      windowSize: [0, 0],
      loadingPage: false
    };
    this.props.initLanguage();
    this.setProfile = this.setProfile.bind(this);
    this.emailFormater = this.emailFormater.bind(this);
    this.getHasExchange = this.getHasExchange.bind(this);
  }

  async componentDidMount() {
    await this.props.getAccountAsync();
    this._useWindowSize();
    window.addEventListener('resize', this._useWindowSize);
    this.setProfile();
  }

  setProfile() {
    const user = getUser();
    this.setState({ user }, () => {
      this.props.getProfileAsync(user.id).then(() => {
        this.props.getAvatarPayload(
          (this.props.profile && this.props.profile.avatarId) || 0
        );
      });
    });
  }

  _useWindowSize = () => {
    this.setState({ 
      windowSize: [window.innerWidth, window.innerHeight] 
    });
  }

  emailFormater(email) {
    let emailToShow = "***@***.com";
    if (email) {
      let userEmail = email.split("@")[0];
      emailToShow = "***@" + email.split("@")[1];
      if (userEmail.length > 2) {
        emailToShow =
          userEmail[0] + userEmail[1] + "***@" + email.split("@")[1];
      }
    }
    return emailToShow;
  }

  handleClickMenu = (e) => {
    if ((e.key === 'trades') || (e.key === 'operations')) {
      this.setState({loadingPage: true}, () => {
        setTimeout(() => {
          this.setState({loadingPage: false})
        }, 1500)
      })
    }

    this.setState({ current: e.key, contentMenu: mapMenuComponent[e.key] });
  };

  onChangeLanguage(language) {
    this.props.changeLanguage(language);
  }

  getHasExchange = () => { 
    const userWithoutExchange = (
      <div style={{ display: 'flex'}}>
      {i18next.t("main.exchanges")}
        <div style={{ 
            background: '#fff',
            borderRadius: '50%',
            height: 15.5,
            width: 13.5,
            }}>
          <Tooltip title="Informe sua chave API">
            <Badge  
              style={{ color: '#f5222d', fontSize: 17 }} 
              className="badge-exchange" 
              count={<QuestionCircleFilled />} >
            </Badge>                
          </Tooltip>  
        </div>        
      </div>
    )

    if (this.props.listAccount[0] === 1) {
      return userWithoutExchange;
    } else if ((this.props.listAccount[0] === 0) || (this.props.listAccount.length > 0)) {
      return (
        <div style={{ display: 'flex'}}>
          {i18next.t("main.exchanges")}
        </div>
      )    
    } else if (this.props.listAccount.length === 0)  {
      return userWithoutExchange;  
    }
  }

  render() {
    const ContentMenu = this.state.contentMenu;
    const isMobile = 
    this.state.windowSize[0] <= 900 ? true : false;

    const menu = (
      <Menu key="user_menu">
        <Menu.Item
          key="plans"
          icon={<DollarOutlined />}
          onClick={this.handleClickMenu}
        >
         {i18next.t("main.plans")}
        </Menu.Item>
        <Menu.Item
          key="settings"
          icon={<SettingOutlined />}
          onClick={this.handleClickMenu}
        >
          {i18next.t("main.settings")}
        </Menu.Item>
        <Menu.Item icon={<LogoutOutlined />} key="logout">
          <a rel="noopener noreferrer" href="/logout">
            {i18next.t("main.logout")}
          </a>
        </Menu.Item>
      </Menu>
    );

    //TODO
    const DropdownMenu = () => (
      <Dropdown key="more" overlay={menu}>
        <a
          href="#!"
          onClick={(e) => e.preventDefault()}
          style={{ color: "#FFFFFFD9" }}
        >
          <Space style={{ marginRight: 20}}>
            {!isMobile ? this.emailFormater(this.state.user.email) : ""}
            {this.props.avatar && this.props.avatar.id ? (
              <IconAvatar avatar={this.props.avatar} width={35} />
            ) : (
              <UserOutlined
                style={{
                  fontSize: 20,
                  verticalAlign: "top",
                }}
              />
            )}
          </Space>
        </a>
      </Dropdown>
    );

    const SelectLanguage = () => {
      return (
        !isMobile ? 
        <Select
          className="select-language"
          key="selectLanguage"
          defaultValue={this.props.language}
          showArrow={false}
          bordered={false}        
          dropdownMatchSelectWidth={false}
          onChange={this.onChangeLanguage}
        >
          <Option key="pt" value="pt">
            <Row style={{ margin: 0, padding: 0 }}>
              <Col style={{ margin: 0, padding: 0, paddingRight: 3 }} span={18}>
                {i18next.t("main.language.portuguese")}
              </Col>
              <Col style={{ margin: 0, padding: 0 }} span={6}>
                <Image key="flag_pt" width={24} src={brazil} preview={false} />
              </Col>
            </Row>
          </Option>
          <Option key="en" value="en">
            <Row style={{ margin: 0, padding: 0 }}>
              <Col style={{ margin: 0, padding: 0, paddingRight: 3 }} span={18}>
                {i18next.t("main.language.english")}
              </Col>
              <Col style={{ margin: 0, padding: 0 }} span={6}>
                <Image
                  key="flag_en"
                  width={24}
                  src={united_states}
                  preview={false}
                />
              </Col>
            </Row>
          </Option>
          <Option key="es" value="es">
            <Row style={{ margin: 0, padding: 0 }}>
              <Col style={{ margin: 0, padding: 0, paddingRight: 3 }} span={18}>
                {i18next.t("main.language.spanish")}
              </Col>
              <Col style={{ margin: 0, padding: 0 }} span={6}>
                <Image key="flag_es" width={24} src={spain} preview={false} />
              </Col>
            </Row>
          </Option>
        </Select>
        : <></>        
      )
    }

    return (
      <Spin className="screen-loader-container-main" spinning={this.state.loadingPage}>
        <div>
          <PageHeader style={{ height: '54px'}}
            theme="dark"
            title={<img src={logo} alt="content" height="50vh" />}          
            subTitle={[
              <Menu
                key="main_menu"              
                onClick={this.handleClickMenu}
                selectedKeys={this.state.current}
                mode="horizontal"
              >
                <Menu.Item key="trades" icon={<RobotOutlined />}>
                  {i18next.t("main.bot")}
                </Menu.Item>
                <Menu.Item key="operations" icon={<ShopOutlined />}>
                  {i18next.t("main.operations")}
                </Menu.Item>
                <Menu.Item key="mainPortfolio" icon={<FundViewOutlined />}>
                  {i18next.t("main.portfolio")}
                </Menu.Item>
                <Menu.Item style={{ display: 'flex', alignItems: 'baseline'}} key="exchanges" icon={<ClusterOutlined />}>
                  {this.getHasExchange()}
                </Menu.Item>
                <Menu.Item key="notes" icon={<SketchOutlined />}>
                  {i18next.t("main.notes")}
                </Menu.Item>
              </Menu>,
            ]}
            extra={[
              <SelectLanguage key="opt_menu_1" />,
              <DropdownMenu key="opt_menu_2" />,
            ]}
            className="header"
          ></PageHeader>
          <ContentMenu {...{ language: this.props.language }} />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  listAccount: state.exchanges.listAccount,  
  language: state.main.language,
  profile: state.settings.profile,
  avatar: state.settings.avatar,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountAsync: () => dispatch(getAccountAsync()),
    initLanguage: () => dispatch(initLanguage()),
    changeLanguage: (language) => dispatch(changeLanguage(language)),
    getProfileAsync: (userId) => dispatch(getProfileAsync(userId)),
    getAvatarPayload: (id) => dispatch(getAvatarPayload(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
