import { createSlice } from "@reduxjs/toolkit";
import { getBotList } from "../../services/apiTradeService";

export const tradeSlice = createSlice({
  name: "trades",
  initialState: {
    exchangeName: 'binance',
    tabActive: '',
    enabledBotList: [],
    disabledBotList: [],
    enabledBotInterval: 0,
  },
  reducers: {
    addAllEnabledBot: (state, action) => {
      state.enabledBotList = action.payload.map((bot) => {
        // TODO - Future Refactor
        if (!bot.idType){
          bot.idType = bot.id + "_" + bot.bot.type;
        }
        return bot;
      });
    },
    addAllDisabledBot: (state, action) => {
      state.disabledBotList = action.payload.map((bot) => {
        bot.idType = bot.id + "_" + bot.bot.type;
        return bot;
      });
    },
    addEnabledBotInterval: (state, action) => {
      state.enabledBotInterval = action.payload;
    },
    stopEnabledBotInterval: (state) => {
      clearInterval(state.enabledBotInterval);
    },
    setActiveTab: (state, action) => {
      clearInterval(state.enabledBotInterval);
      state.tabActive = action.payload;
    }
  },
});

const {
  addAllEnabledBot,
  addAllDisabledBot,
  addEnabledBotInterval,
  stopEnabledBotInterval,
  setActiveTab,
} = tradeSlice.actions;

export const findEnabledBotAsync = (config, callback) => (dispatch) => {
  let running = false;

  const params = { limit: '', active: true, ...config };

  dispatch(
    addEnabledBotInterval(
      setInterval(() => {
        if (!running) { running = true;
          getBotList(params).then((recordList) => { running = false;

            if (typeof callback === 'function') callback();

            dispatch(addAllEnabledBot(recordList));
          });
        }
      }, 2000)
    )
  );
};

export const findDisabledBotAsync = (config, callback) => (dispatch) => {
  let running = false;

  dispatch(addAllDisabledBot([]));

  const params = { limit: '', active: false, ...config };

  if (!running) { running = true;
    getBotList(params).then((recordList) => { running = false;

      if (typeof callback === 'function') callback();

      dispatch(addAllDisabledBot(recordList));
    })
  }
};

export const stopBotInterval = () => (dispatch) => 
  dispatch(stopEnabledBotInterval());

export const setActiveTabAsync = (tab) => (dispatch) => 
  dispatch(setActiveTab(tab));

export const updateEnabledBotListAsync = (list) => (dispatch) => 
  dispatch(addAllEnabledBot(list));

export default tradeSlice.reducer;
