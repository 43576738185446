import TableViewBotOnePackagesColumns from './TableViewBotOnePackagesColumns';
import { Table, Empty, Collapse } from "antd";
import i18next from "i18next";

const { Panel } = Collapse;

const CollapseReinvest = (props) => {
  if (props.reinvestment &&
      props.reinvestment.operations &&
      props.reinvestment.operations.length) {
    return (
      <>
        <Collapse>
          <Panel header={i18next.t("trades.view.label.reinvestment")} key="1">
            <Table
              rowKey="id"
              columns={TableViewBotOnePackagesColumns('columnsTablePackageSetupDetails')}
              dataSource={props.reinvestment.operations}
              locale={{
                emptyText: (
                  <Empty
                    description={i18next.t("message.nothing")}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ),
              }}
              size="big"
              pagination={false}
            />
          </Panel>
        </Collapse>
        <div style={{ margin: "16px 0" }} />
      </>
    )
  } else {
    return (
      <></>
    )
  }  
}

export default CollapseReinvest;