import React from "react";
import "./exchanges.css";
import { connect } from "react-redux";
import i18next from "i18next";

import { Tooltip, Button, Row, Col } from "antd";

import {PlusOutlined} from "@ant-design/icons";

import {
  getAccountAsync,
  createAccountAsync,
  editAccountAsync,
  deleteAccountAsync,
  getBalanceInfoAsync
} from "./exchangeSlice";

import { getUser } from "../../services/apiAuthService";

import NewExchange from './components/NewExchange';
import CoinsTable from './components/CoinsTable';
import ListExchange from './components/ListExchange';
import CardTicod from './components/CardTicod';

let modalTerms = null;
class Exchanges extends React.Component {
  constructor(props) {
    super(props);
    this.showRegisterDrawer = this.showRegisterDrawer.bind(this);
    this.closeRegisterDrawer = this.closeRegisterDrawer.bind(this);
    this.formRef = React.createRef();
    this.state = {
      height: window.innerHeight,
      visibleRegisterDrawer: false,
      acceptedTerms: false,
      editingId: 0,
      exchangeName: "",
      user: null,
    };
  }

  componentDidMount() {
    this.setState({ user: getUser() }, () => {
      this.props.getAccountAsync();
      this.props.getBalanceInfoAsync(this.state.user.id);
    });
  }

  showRegisterDrawer = () => {
    this.setState({
      visibleRegisterDrawer: true,
    });
  };

  closeRegisterDrawer = () => {
    this.setState({
      visibleRegisterDrawer: false,
    });
    this.props.getAccountAsync();
  };

  onDelete(id) {
    this.props.deleteAccountAsync(id);
    setTimeout(() => {
      this.props.getAccountAsync();
    }, 1000) 
  }

  getExchangeId(exchangeName) {
    const exchange = this.props.allExchange.find(
      (exchange) => exchange.name === exchangeName
    );
    return (exchange && exchange.id) || "none";
  }

  registerExchange = (account) => {
    this.setState(
      {
        editingId: account.id,
        exchangeName: this.getExchangeId(account.name),
      },
      () => {
        if (this.formRef.current) {
          this.formRef.current.resetFields();
        }
        this.showRegisterDrawer();
      }
    );
  }

  render() {
    if (modalTerms) {
      modalTerms.update({
        okButtonProps: { disabled: !this.state.acceptedTerms },
      });
    }

    return (
      <>
        <div
          className="exchanges-container"
          style={{height: "100%", maxWidth: 1150, margin: "0 auto"}}
        >
          <Row className="exchanges-container-cards" gutter={[9, 9]}> 
            <Col            
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 7, order: 1 }}
              lg={{ span: 7, order: 1 }}
              xl={{ span: 7, order: 1 }}
            >            
              <CardTicod marginTop={10}>   
                <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                  <Tooltip mouseEnterDelay="1" title={i18next.t("exchange.hint.add")}>
                    <Button
                      type="primary"
                      size="large"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        this.setState({ editingId: 0, exchangeName: "" }, () => {
                          if (this.formRef.current) {
                            this.formRef.current.resetFields();
                          }
                          this.showRegisterDrawer();
                        });
                      }}>
                      {i18next.t("exchange.button.add")}
                    </Button>
                  </Tooltip>
                </div>
                <ListExchange
                  props={this.props} 
                  getExchangeId={(exchangeName) => this.getExchangeId(exchangeName)} 
                  onDelete={(id) => this.onDelete(id)} 
                  showViewDrawer={this.showViewDrawer} 
                  registerExchange={(account) => this.registerExchange(account)}
                />
              </CardTicod>
            </Col>
            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: 24, order: 2 }}
              md={{ span: 17, order: 2 }}
              lg={{ span: 17, order: 2 }}
              xl={{ span: 17, order: 2 }}
            >  
              <CardTicod marginTop={10} >
                <CoinsTable props={this.props} getExchangeId={(exchangeName) => this.getExchangeId(exchangeName)}  />
              </CardTicod>
            </Col>
          </Row>

          <NewExchange 
            props={this.props} 
            state={this.state} 
            closeRegisterDrawer={this.closeRegisterDrawer}
            formRef={this.formRef} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  listAccount: state.exchanges.listAccount,
  balance: state.exchanges.balance,
  allExchange: state.exchangeList.allExchange, //TODO: NÃO TÁ LEGAL
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountAsync: () => dispatch(getAccountAsync()),
    createAccountAsync: (exchange, account) =>
      dispatch(createAccountAsync(exchange, account)),
    editAccountAsync: (exchange, account) =>
      dispatch(editAccountAsync(exchange, account)),
    deleteAccountAsync: (id) => dispatch(deleteAccountAsync(id)),
    getBalanceInfoAsync: (userId) => dispatch(getBalanceInfoAsync(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Exchanges);
