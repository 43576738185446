import React, { Fragment, useEffect } from "react";

import "../trades.css";
import i18next from "i18next";
import { convertValuesCoins } from "../../shared/utils/convertValuesCoins";
import TableBotsColumns from "./TableBotsColumns";
import SubscribeTradesWs from "./TradesWsBotList";

import { Table, Button, Row, Col, Empty, Carousel, Badge } from "antd";

import {
  StepBackwardOutlined,
  StepForwardOutlined,
  WalletOutlined,
  RiseOutlined,
} from "@ant-design/icons";

const TableBotsActivedWs = (props) => {
  useEffect(() => {
    SubscribeTradesWs(props);
  }, [props]);

  return (
    <Fragment>
      <Table
        style={{ height: 425 }}
        rowKey={(record) => record.id}
        id="usrTableActive"
        className="usrTableActive"
        columns={TableBotsColumns("enabledBotColumns", {
          allSymbol: props.allSymbol,
        })}
        dataSource={props.enabledBotList}
        locale={{
          emptyText: (
            <Empty
              description={i18next.t("message.nothing")}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        size="small"
        pagination={false}
        scroll={{ x: 1200, y: "300px" }}
        onRow={(record) => {
          return {
            onClick: (e) => {
              const clickedRow =
                e.target.outerHTML.slice(0, 3) === "<td" ||
                e.target.outerHTML.slice(0, 3) === "<di";
              window.TICOD.updateCoin(
                record.symbol,
                FILTER_COIN_NAME,
                clickedRow
              );
            },
          };
        }}
        footer={() => {
          let listTotal = [];
          props.enabledBotList.forEach(({ investment, earning }) => {
            const arrayInvestment = investment.split(" ");
            const arrayEarning = earning.split(" ");

            const objIndex = listTotal.findIndex(
              (obj) => obj.coin === arrayInvestment[1]
            );

            let decimalInvestment = 0;
            if (arrayInvestment[0].includes(".")) {
              decimalInvestment = arrayInvestment[0].split(".")[1].length;
            }

            let decimalEarning = 0;
            if (arrayEarning[0].includes(".")) {
              decimalEarning = arrayEarning[0].split(".")[1].length;
            }

            if (objIndex > -1) {
              listTotal[objIndex].investment = (
                parseFloat(listTotal[objIndex].investment) +
                parseFloat(arrayInvestment[0])
              ).toFixed(decimalInvestment);
              listTotal[objIndex].earning = (
                parseFloat(listTotal[objIndex].earning) +
                parseFloat(arrayEarning[0])
              ).toFixed(decimalEarning);
            } else {
              listTotal.push({
                coin: arrayInvestment[1],
                investment: parseFloat(arrayInvestment[0]).toFixed(
                  decimalInvestment
                ),
                earning: parseFloat(arrayEarning[0]).toFixed(decimalEarning),
              });
            }
          });

          listTotal.sort((a, b) => {
            if (a.coin < b.coin) {
              return -1;
            } else if (a.coin > b.coin) {
              return 1;
            } else {
              return 0;
            }
          });

          return listTotal.length > 0 ? (
            <>
              <Row key="row_total" style={{ maxWidth: "100%" }}>
                <Col key="col_pev" span={1} style={{ textAlign: "left" }}>
                  <Button size="small" onClick={carouselPrev}>
                    <StepBackwardOutlined />
                  </Button>
                </Col>
                <Col key="col_carousel" span={22}>
                  <Carousel
                    ref={carouselRef}
                    autoplay
                    autoplaySpeed={4500}
                    dots={false}
                    className="trade-carousel"
                    infinite={true}
                    slidesToShow={listTotal.length >= 3 ? 3 : listTotal.length}
                    slidesToScroll={1}
                    pauseOnHover={true}
                    variableWidth={listTotal.length >= 3 ? false : true}
                  >
                    {listTotal.map((item) => (
                      <div key={item.coin} style={{ marginRight: 12 }}>
                        <Row>
                          <Col>
                            <Badge status="processing" />
                          </Col>
                          <Col style={{ marginRight: 18 }}>{item.coin}</Col>
                          <Col>
                            <WalletOutlined style={{ marginRight: 6 }} />
                          </Col>
                          <Col style={{ marginRight: 12 }}>
                            {convertValuesCoins(item.investment)}
                          </Col>
                          <Col>
                            <RiseOutlined style={{ marginRight: 6 }} />
                          </Col>
                          <Col>{convertValuesCoins(item.earning)}</Col>
                        </Row>
                      </div>
                    ))}
                  </Carousel>
                </Col>
                <Col key="col_next" span={1} style={{ textAlign: "end" }}>
                  <Button size="small" onClick={carouselNext}>
                    <StepForwardOutlined />
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <> </>
          );
        }}
      />
    </Fragment>
  );
};

const FILTER_COIN_NAME = 2;

const carouselRef = React.createRef();

const carouselNext = () => {
  if (carouselRef.current) {
    carouselRef.current.next();
  }
};

const carouselPrev = () => {
  if (carouselRef.current) {
    carouselRef.current.prev();
  }
};

// const _updateEnabledBotList = (update) => {

//   const existList =
//     globalEnabledBotList &&
//     globalEnabledBotList.length ?
//     globalEnabledBotList : null
//   ;

//   const newItem =
//   update &&
//   update.message[0] ?
//   update.message[0] : null
//   ;

//   if (!existList || !newItem)
//     return;

//   let newList = [];

//   const message = newItem

//   let earning = 0;

//   if (message.event === 'NEW_BOT'){

//     delete newItem.event;
//     delete newItem.userId;
//     existList.push(newItem);
//     newList = existList;

//   } else {

//     existList.map((item) => {
//       if (item.id === newItem.id && item.bot.type === newItem.bot.type) {

//         if (newItem.event === 'SELL_MARKET'){
//           return true;

//         } else if (newItem.event === 'BUY') {
//           return newList.push(item);

//         } else if(newItem.event === 'SELL') {
//           let itemUpdate = {
//             ...item,
//             earning: item.earning + newItem.earning,
//             operations: item.operations + 1
//           };
//           return newList.push(itemUpdate);
//         }
//       } else {
//         //nothing here
//       }

//       return newList.push(item);
//     })
//   }

//   Notifications({event: message.event, earning})

//   return globalEnabledBotList = newList;
// }

export default TableBotsActivedWs;
