import api from "./api";

export function login(token) {
  localStorage.setItem("x-access-token", token);
}

export function logout() {
  //TODO: Ainda não há rota para cancelar o token
  localStorage.clear();
  sessionStorage.clear();
}

export function isAuthenticated() {
  return new Promise((resolve) => {
    api.get("/validate").then((res) => {
      if (res && res.status === 200 && !!res.data) {
        localStorage.setItem("user", JSON.stringify(res.data.user));
        resolve(true);
      }
      resolve(false);
    });
  });
}

export function getUser() { 
  if(localStorage.getItem("user")) {
    return JSON.parse(localStorage.getItem("user"));
  }

  return false;
}
