import React from "react";
import i18next from "i18next";

import { notification } from "antd";

import { RobotOutlined } from "@ant-design/icons";

/*
Config:
params: { event, earning, rowIndex }

Rules:
Novo robo event: NEW_BOT
Editar robo event: EDIT_BOT
Compra event: BUY
Venda event: SELL_MARKET || event: SELL (param.earning)
Cancelamento event: CANCEL
*/

const delay = 10;
const delayRow = 50;

const Notifications = (params) => {
  const { event, earning, rowIndex, newItem } = params;

  const classes = i18next.t(`trades.websocket.notifications.${event}.class`);

  let message = i18next.t(`trades.websocket.notifications.${event}.message`);

  let description = i18next.t(
    `trades.websocket.notifications.${event}.description`
  );

  description = description.replace("{earning}", earning);

  description = description.replace("{par}", `${newItem.symbol}`);

  description = description.replace("{price}", `${newItem.price}`);

  if (event === "BUY") {
    description = description.replace("{coin}", newItem.symbol.split("/")[1]);
  }

  message = message.replace("{name}", newItem.bot.name);
  message = message.replace("{par}", newItem.symbol);

  notification.open({
    message,
    duration: delay,
    description,
    className: classes,
    icon: <RobotOutlined />,
  });

  return rowEffect({ rowIndex, event });
  // return true;
};

const rowEffect = (params) => {
  /* VANILLAO */

  let { event, rowIndex } = params;

  if (typeof rowIndex === "undefined" || rowIndex === null) {
    return false;
  } else if (typeof rowIndex !== "number") {
    rowIndex = parseInt(rowIndex);
  }
  const usrTableActive = document.getElementById("usrTableActive");

  if (!usrTableActive) {
    console.log(document);
    return false;
  }
  const row = usrTableActive.querySelectorAll(".ant-table-row")[rowIndex];
  if (row) {
    row.classList.add(`highlight--${event}`);
    // fixed.classList.add(`highlight--${event}`);

    window.setTimeout(() => {
      row.classList.remove(`highlight--${event}`);
    }, delayRow * 1000);

    if (event === "SELL" || event === "SELL_MARKET") {
      const rowEarning = row.querySelectorAll(".earnFlash")[1];

      rowEarning.classList.add(`uow`);

      window.setTimeout(() => {
        rowEarning.classList.remove(`uow`);
      }, 800);
    }
  }
};

// window.TICOD.notifications({
//   event: "BUY",
//   earning: "0.12 USDT",
//   newItem: {
//     id: 222,
//     bot: {
//       name: "T-Profit Plus",
//       type: 4,
//     },
//     createdAt: "21-10-14 19:34:36",
//     updatedAt: null,
//     exchange: "Binance",
//     symbol: "SHIB/USDT",
//     status: "STOPPED",
//     investment: "22.00 USDT",
//     earning: "2.18 USDT",
//     operations: "14",
//     active: "true", 
//     limits: {
//       botProfitPlusConfigId: 222,
//       limitPrice: 1,
//       maxPrice: 0.0000283829,
//       minPrice: 0.000027531399609870277,
//     },
//   },
//   rowIndex: 0,
// });
const notifyInvoke = setInterval(() => {
  if (window.TICOD) {
    window.TICOD.notifications = Notifications || {};
    clearInterval(notifyInvoke);
  }
}, 500);

export default Notifications;
