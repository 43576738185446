import {   
  wsBotConnect
} from '../../../services/apiWebSocket';
import { getUser } from "../../../services/apiAuthService";

import Notifications from "./TradesNotifications";

let loading = false;

let globalProps = {};

const SubscribeTradesWs = (props) => {
  const userInfo = getUser();

  const _Subs = (props, callback = {}) => {
    if (userInfo) {
      const wsBot = wsBotConnect(userInfo)
      loading = true;
      wsBot.on(`welcome__${userInfo.id}`, (data) => {        
        if (!data) 
          return console.error('Ws Offline');
    
        props.updateEnabledBotListAsync(data);      
      });
      wsBot.on(`upEnabledBots`, update => {
        if (!update) 
          return console.error('Ws Offline');
        UpdateBotList('enabledBotList', update); 
      });
    }
  }
  
  if (userInfo && userInfo.id && props.enabledBotList.length === 0 && !loading) {    
    _Subs(props, () => { loading = false });
  }

  return globalProps = props;
}

const UpdateBotList = (listType, update) => {

  let existList = 
    globalProps[listType] && 
    globalProps[listType].length ? 
    globalProps[listType] : null
  ;

  let newItem = 
    update && 
    update.message[0] ? 
    update.message[0] : null
  ;

  if (!newItem)
    return;

  let newList = [];

  let message = newItem;
  let coinEarning = ['', ''];
  
  if (message.event === 'NEW_BOT'){
    newList.push(newItem);
    if (existList){
      existList.forEach(item => {
        newList.push(item);
      })
    }
  } else {
    existList.forEach((item) => {
      if (item.id === newItem.id && item.bot.type === newItem.bot.type) {

        if (newItem.event.includes(['SELL_MARKET', 'CANCEL_BUY_ERROR'])){
          return true;
        
        } else if (newItem.event === 'BUY') {
            newList.push(item)
          return null;
          
        } else if(newItem.event === 'SELL') {
          coinEarning = item.earning.split(' ')
          let itemUpdate = { 
            ...item,
            earning: parseFloat(coinEarning[0]) + newItem.earning + ` ${coinEarning[1]}`,
            operations: parseInt(item.operations) + 1 
          };
          newList.push(itemUpdate)
          return null ;
        }
  
      } else {
        //nothing here
        return newList.push(item);
      }
    })
  }

  
  globalProps.updateEnabledBotListAsync(newList);
  const index = newList.findIndex(item => item.id === newItem.id && item.bot.type === newItem.bot.type);
  setTimeout(() => {
    Notifications({event: message.event, earning: newItem.earning + ` ${coinEarning[1]}`, newItem, rowIndex: index});
  }, 300)

  return true;
}

export default SubscribeTradesWs;