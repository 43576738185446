import React from "react";
import { isAuthenticated } from "../../services/apiAuthService";
import { Redirect } from "react-router-dom";
import Main from "../main/main";
import { Spin } from "antd";
import "./protected.css";

export class Protected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Component: (
        <div className="screen-loader-container">
          <Spin />
        </div>
      ),
    };
  }

  componentDidMount() {
    isAuthenticated().then((authenticated) => {
      this.setState({
        Component: authenticated ? (
          <Main {...this.props} />
        ) : (
          <Redirect to="/login" />
        ),
      });
    });
  }

  render() {
    return this.state.Component;
  }
}
