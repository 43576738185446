import { useEffect, useState } from "react";
import i18next from "i18next";
import { Button, Menu, Dropdown } from "antd";

import { 
  FileDoneOutlined,
  DownOutlined,
  CloudDownloadOutlined  
} from '@ant-design/icons';

/* TYPE 1: GERAL */

export const ExportButtonMain = (props) => {

  const [globalProps, setGlobalProps] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingFully, setLoadingFully] = useState(false);

  const handleExport = () => {
    setLoading(true);
    const filters = globalProps.operationsParamsType1;
    globalProps.setOperationsExportAsync(filters)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const handleExportFully = () => {
    setLoadingFully(true);
    const filters = globalProps.operationsParamsType1;
    globalProps.setOperationsExportAsync({...filters, fully: true})
      .then(() => setLoadingFully(false))
      .catch(() => setLoadingFully(false))
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={handleExport} onLoad={() => loading} key="details"> 
        {i18next.t("portfolio.operations.buttons.exportButton2")}        
      </Menu.Item>
      <Menu.Item onClick={handleExportFully} onLoad={() => loadingFully} key="resume">      
        {i18next.t("portfolio.operations.buttons.exportButton1")}
      </Menu.Item>
    </Menu>
  );  

  useEffect(() => { 
    setGlobalProps(props.data);
  }, [props])

  return (
    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
      <Dropdown overlay={menu} placement="bottomCenter" arrow key="1">
        <Button 
        icon={<FileDoneOutlined />}        
        type="primary">
          {i18next.t("portfolio.operations.buttons.export")} <DownOutlined />
        </Button> 
      </Dropdown>
    </div>
  )
}

export const ExportMiniButtonMain = (props) => {

  const [globalProps, setGlobalProps] = useState(false);

  const { record, global } = props;

  const [loading, setLoading] = useState(false);

  const handleExportClick = (params) => { 
    setLoading(true);

    params = JSON.parse(params);
    params.onlyExport = true;

    globalProps.setOperationsResumeAsync(globalProps, params)
      .then((res) => {
        setTimeout(() => {
          const filters = res.payload;
          globalProps.setOperationsExportAsync(filters)
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
        }, 1000)
      });
  }

  useEffect(() => { 
    setGlobalProps(global);
  }, [props, global]);

  return (
    <Button 
      loading={loading}
      type="secondary" 
      style={{ width: '28px'}} 
      onClick={() => handleExportClick(JSON.stringify(record))} 
      icon={<CloudDownloadOutlined />} 
    />
  )
}

/* TYPE 2: RESUMIDO */

export const ExportButtonResume = (props) => {

  const [globalProps, setGlobalProps] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleExport = () => {
    setLoading(true);
    const filters = globalProps.operationsParamsType2;
    globalProps.setOperationsExportAsync(filters)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  useEffect(() => { 
    setGlobalProps(props.data);
  }, [props.data]);

  return (
    <div>
      <Button loading={loading} 
        onClick={handleExport}
        icon={<FileDoneOutlined />} 
        type="primary" 
        className="exportButtonResume">
        {i18next.t("portfolio.operations.buttons.exportButton")}
      </Button>
    </div>
  )
}

export const ExportMiniButtonResume = (props) => {

  const [globalProps, setGlobalProps] = useState(false);

  const { record, global } = props;

  const [loading, setLoading] = useState(false);

  const handleExportClick = (params) => { 
    setLoading(true);

    params = JSON.parse(params);
    params.onlyExport = true;

    globalProps.setOperationsDetailsAsync(globalProps, params)
      .then((res) => {
        setTimeout(() => {
          const filters = res.payload;
          globalProps.setOperationsExportAsync(filters)
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
        }, 1000)
      });
  }

  useEffect(() => { 
    setGlobalProps(global);
  }, [props, global]);

  return (
    <Button 
      loading={loading}      
      style={{ width: '28px'}} 
      type="secondary" 
      onClick={() => handleExportClick(JSON.stringify(record))} 
      icon={<CloudDownloadOutlined />} 
    />
  )
}

/* TYPE 3: DETALHADO */

export const ExportButtonDetails = (props) => {

  const [globalProps, setGlobalProps] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleExport = () => {
    setLoading(true);
    const filters = globalProps.operationsParamsType3;    
    globalProps.setOperationsExportAsync(filters)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  useEffect(() => { 
    setGlobalProps(props.data);
  }, [props.data]);

  return (
    <div>
      <Button loading={loading} 
        onClick={handleExport}
        icon={<FileDoneOutlined />} 
        type="primary" 
        // style={{float:"right"}}
        className="exportButtonDetails">
        {i18next.t("portfolio.operations.buttons.exportButton")}
      </Button>
    </div>
  )
}