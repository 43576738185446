import axios from "axios";

const urlApi = "https://api.binance.com/api/v3";

const axiosInstance = axios.create({
  baseURL: urlApi,
});

export const apiBinance = {
  get(endpoint, params) {
    if (arguments.length === 1) {
      return axiosInstance.get(endpoint);
    } else if (arguments.length === 2) {
      return axiosInstance.get(endpoint, { params });
    }
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
};
