import React from "react";
import i18next from "i18next";
import { Button } from "antd";
import { registerBotsGrades } from "../utils/persistData";

export const RobotCreateFooter = (params) => {
  const ButtonBack = () => {
    return (
      <Button
        style={{ marginRight: 9 }}
        onClick={params.prev}
        visible={params.props.currentStepRegister === 0}>
          {i18next.t("button.back")}
      </Button>
    )
  };

  /* step 1: submit simulate */

  if (params.props.profitPgStep === 1) {
    return (
      <div>
        <div style={{textAlign:"right"}}>
          <Button
            type="primary"
            onClick={() => {
                if (params.next(false)) {
                  registerBotsGrades(params.props, params.refFields, params.onClose, false, params.props.currentBot);
                }          
              }
            }
            disabled={params.props.currentStepRegister !== 0}
            loading={params.props.loading ? true : false}>
            {i18next.t("button.simulate")}
          </Button>
        </div>
      </div>
    )
  }

  /* step 2: show simulate */

  if (params.props.profitPgStep === 2 && 
      params.props.currentStepRegister === 0) {
    return (
      <div>
        <div style={{textAlign:"right"}}>          
          <Button
            onClick={() => {
              params.props.updatePgSimulateResults([]);
              params.props.updatePlusSimulateResults([]);
              params.props.updatePackageSimulateResults([]);
              registerBotsGrades(params.props, params.refFields, params.onClose, true, params.props.currentBot)}
            }
            style={{ marginRight: 9 }}            
            loading={params.props.loading ? true : false}>
            {i18next.t("button.newSimulate")}
          </Button>
          <a 
            href="#!" 
            className="ant-btn ant-btn-primary ant-btn-sm"
            disabled={params.props.loading ? true : false}
            onClick={() => params.next()}
          >
            {i18next.t("button.nextStep")}
          </a>
        </div>
      </div>
    )
  }

  if (params.props.profitPgStep === 2 && 
    params.props.currentStepRegister === 1) {
    return (
      <div>
        <div style={{textAlign:"right"}}>
          <ButtonBack />
          <a 
            href="#!" 
            className="ant-btn ant-btn-primary ant-btn-sm"
            disabled={params.props.loading ? true : false}
            onClick={() => {                                     
              params.next();
            }}
          >
              {i18next.t("button.nextStep")}
          </a>
        </div>
      </div>
    )
  }  

  /* step 3: save bot */

  if (params.props.profitPgStep === 3) {
    return (
      <div>
        <div style={{textAlign:"right"}}>
          <ButtonBack />
          <Button
            type="primary"
            onClick={() => registerBotsGrades(params.props, params.refFields, params.onClose, false, params.props.currentBot)}
            loading={params.props.loading ? true : false}>
            {i18next.t("button.save")}
          </Button>
        </div>
      </div>
    )
  }  
}