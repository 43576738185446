import { createSlice } from "@reduxjs/toolkit";
import { findAllExchange } from "../../services/apiExchangeListService";

export const exchangeListSlice = createSlice({
  name: "exchangeList",
  initialState: {
    allExchange: [],
  },
  reducers: {
    addAllExchange: (state, action) => {
      state.allExchange = action.payload;
    },
  },
});

const { addAllExchange } = exchangeListSlice.actions;

export const findAllExchangeAsync = () => (dispatch) => {
  return findAllExchange().then((recordList) => {
    dispatch(addAllExchange(recordList));
  });
};

export default exchangeListSlice.reducer;
