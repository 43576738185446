import { useEffect, useState } from "react";
import i18next from "i18next";
import "./tables.css"
import { Table, Row, Col, Card } from "antd";
import { tableResumeColumns } from "./columnsConfig"
import { convertValuesCoins } from "../../../shared/utils/convertValuesCoins";
import { ExportButtonResume } from "./exportButton";

let globalProps = {};

const TableResume = (props) => {

  const [opacity, setOpacity] = useState(0);
  const [columns, setColumns] = useState(false);
  const [userInfo, setUserInfo] = useState(false);

  useEffect(() => {
    globalProps = props.data;
    setColumns(tableResumeColumns(globalProps));
    setUserInfo(globalProps.operationsInfo2);
    globalProps.changeLoading(false);
    const interval = setInterval(() => setOpacity(1), 100)
    return () => clearInterval(interval);
  }, [props.data])

  return (
    <div className="opContent" style={{ opacity: opacity }}>    
      <Card
        size="small"
        bordered={false}
        style={{ background: '#141414', borderRadius: 8, marginBottom: 10, height: 70}}
      >
        <Row>
          <Col span={18}>
            
            <Row className="infoTable">
              <Col xs={10} lg={6}>
                <div className="infoTable__item">
                  <span>{i18next.t("portfolio.operations.tableResume.infoTable.symbol")}</span> <br /> 
                  <p style={{ fontSize: '14px'}}>{userInfo.symbol}</p>
                </div>
              </Col>
              
              <Col xs={14} lg={6}>
                <div className="infoTable__item">
                  <span>{i18next.t("portfolio.operations.tableResume.infoTable.result")}</span> <br /> 
                  <p style={{ fontSize: '14px'}}>{convertValuesCoins(userInfo.earning)}</p>
                </div>
              </Col>
            </Row>
            
          </Col>
          <Col span={6} style={{ marginTop: 5}}>
            <ExportButtonResume {...props} />
          </Col>
        </Row>
      </Card>              

      <Card
        size="small"
        bordered={false}
        style={{ background: '#141414', borderRadius: '8px', minHeight: 400, maxHeight: 500}}
      >
        <Table 
          className="opTable"
          columns={columns} 
          dataSource={props.data.operationsResume} 
          size="small"
          rowClassName={() => "rowClass"}
          scroll={{ x: "none", y: "370px" }}
          pagination={{ pageSize: 20 }}
        />
      </Card>           
    </div>
  )
}

export default TableResume;