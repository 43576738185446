import { createSlice } from "@reduxjs/toolkit";
import { findAllSymbol } from "../../services/apiCoinService";

export const coinSlice = createSlice({
  name: "coin",
  initialState: {
    allSymbol: [],
  },
  reducers: {
    addAllSymbol: (state, action) => {
      state.allSymbol = action.payload;
    },
  },
});

const { addAllSymbol } = coinSlice.actions;

export const findAllSymbolAsync = (exchangeName) => (dispatch) => {
  return findAllSymbol(exchangeName).then((recordList) => {
    dispatch(addAllSymbol(recordList));
  });
};

export default coinSlice.reducer;
