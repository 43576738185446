import { createSlice } from "@reduxjs/toolkit";
import { getActiveBots, getActiveOperations, getAccompaniments, getAccompanimentsDetails } from "../../../services/apiPlansService";

export const plansSlice = createSlice({
  name: "overview",
  initialState: {
    activeBots: [],
    activeOperations: [],
    accompanimentsList: [],
    accompanimentsDetailList: [],
  },
  reducers: {
    addActiveBots: (state, action) => {
      state.activeBots = action.payload;
    },  
    addActiveOperations: (state, action) => {
      state.activeOperations = action.payload;
    },    
    addAccompaniments: (state, action) => {
      state.accompanimentsList = action.payload;
    },
    addAccompanimentsDetail: (state, action) => {
      state.accompanimentsDetailList = action.payload;
    },
  },
});

const {
  addActiveBots,
  addActiveOperations,
  addAccompaniments,
  addAccompanimentsDetail
} = plansSlice.actions;

export const setTActiveBotsAsync = (exchangeName) => (dispatch) => {
  return new Promise((resolve) => {
    getActiveBots(exchangeName).then((res) => {
      dispatch(addActiveBots(res));    
    });    
    let running = false;
    setInterval(() => {
      if (!running) {   
        getActiveBots(exchangeName).then((res) => {
          dispatch(addActiveBots(res));
          running = false;
          resolve();
        });
      };
    }, 30000);
  });
}

export const setActiveOperationsAsync = (exchangeName) => (dispatch) => {
  return new Promise((resolve) => {
    getActiveOperations(exchangeName).then((res) => {
      dispatch(addActiveOperations(res));
    });
    let running = false;    
    setInterval(() => {
      if (!running) {   
        getActiveOperations(exchangeName).then((res) => {
          dispatch(addActiveOperations(res));
            running = false;
            resolve();
        });
      };
    }, 30000);
  });
}

export const findAccompanimentsAsync = (exchangeName) => async (dispatch) => {
  return getAccompaniments(exchangeName).then((record) => {
    dispatch(addAccompaniments(record));
  });
};

export const findAccompanimentsDetailAsync = (exchangeName, id) => async (dispatch) => {
  return getAccompanimentsDetails(exchangeName, id).then((record) => {
    dispatch(addAccompanimentsDetail(record));
  });
};

export default plansSlice.reducer;