import api from "./api";

export function getUsers(list = "" || [], term = "") {
  return new Promise((resolve) => {

    if (list && typeof list === "object") {
      list = list.join(",");
    }

    if (term && typeof term !== "string") {
      term = term.toString();
    }

    api.get("/users", { list, term }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([])
    })
  })
}