import { configureStore } from "@reduxjs/toolkit";
import mainSlice from "./components/main/mainSlice";
import walletSlice from "./components/shared/walletSlice";
import orderBookSlice from "./components/shared/orderBookSlice";
import exchangeListSlice from "./components/shared/exchangeListSlice";
import coinSlice from "./components/shared/coinSlice";
import tradeSlice from "./components/trades/tradeSlice";
import botParamSlice from "./components/shared/botParamSlice";
import robotViewerSlice from "./components/trades/robot/robotViewer/robotViewerSlice";
import robotSlice from "./components/trades/robot/robotSlice";
import robotStopSlice from "./components/trades/robot/robotStop/robotStopSlice";
import exchangesSlice from "./components/exchanges/exchangeSlice";
import settingsSlice from "./components/settings/settingsSlice";
import operationsSlice from "./components/operations/operationsSlice";
import mainPortfolioSlice from './components/portfolio/main/mainPortfolioSlice';
import operationsResultsSlice from './components/portfolio/results/operationsResultsSlice';
import plansSlice from './components/plans/plansSlice';
import overviewSlice from './components/portfolio/overview/overviewSlice';

export default configureStore({
  reducer: {
    main: mainSlice,
    wallet: walletSlice,
    orderBook: orderBookSlice,
    exchangeList: exchangeListSlice,
    coin: coinSlice,
    trades: tradeSlice,
    botParam: botParamSlice,
    robotViewer: robotViewerSlice,
    robotSlice: robotSlice,
    robotStop: robotStopSlice,
    exchanges: exchangesSlice,
    settings: settingsSlice,
    operations: operationsSlice,
    mainPortfolio: mainPortfolioSlice,
    operationsResultsSlice: operationsResultsSlice,
    plans: plansSlice,
    overview: overviewSlice,
  },
});
