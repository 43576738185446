import React from "react";

import { 
  setOperationsFiltersContentAsync,
  setOperationsMainAsync,
  setOperationsResumeAsync,
  setOperationsDetailsAsync,
  setOperationsExportAsync,
  setOperationsInfo2Async,
  setOperationsInfo3Async,
  setOperationsComponentAsync
} from "./operationsResultsSlice";
import { connect } from "react-redux";

import Filters from "./filters";
import TableMain from "./components/tableMain";
import TableResume from "./components/tableResume";
import TableDetails from "./components/tableDetails";

const mapOperationsComponent = {
  main: TableMain,
  resume: TableResume,
  details: TableDetails
};

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.props.setOperationsFiltersContentAsync();
  }

  render() {
    // console.log("PROPS", this.props);

    const ContentOperations = 
      this.props.operationsComponent ? 
      mapOperationsComponent[this.props.operationsComponent] : Empty
    ;

    return (
      <div>
        <Filters {...this.props} />
  
        <ContentOperations data={this.props} />
      </div>
    )
  }
}

const Empty = () => {
  return <div></div>
};

const mapStateToProps = (state) => ({
  operationsFiltersContent: state.operationsResultsSlice.operationsFiltersContent,
  operationsMain: state.operationsResultsSlice.operationsMain,
  operationsMainTotal: state.operationsResultsSlice.operationsMainTotal,
  operationsResume: state.operationsResultsSlice.operationsResume,
  operationsDetails: state.operationsResultsSlice.operationsDetails,
  operationsFiltersApplyed: state.operationsResultsSlice.operationsFiltersApplyed,
  operationsInfo2: state.operationsResultsSlice.operationsInfo2,
  operationsInfo3: state.operationsResultsSlice.operationsInfo3,
  operationsComponent: state.operationsResultsSlice.operationsComponent,
  operationsParamsType1: state.operationsResultsSlice.operationsParamsType1,
  operationsParamsType2: state.operationsResultsSlice.operationsParamsType2,
  operationsParamsType3: state.operationsResultsSlice.operationsParamsType3
});

const mapDispatchToProps = (dispatch) => {
  return {
    setOperationsFiltersContentAsync: () => 
      dispatch(setOperationsFiltersContentAsync()),

    setOperationsMainAsync: filters => 
      dispatch(setOperationsMainAsync(filters)),

    setOperationsResumeAsync: (globalProps, params) => 
      dispatch(setOperationsResumeAsync(globalProps, params)),

    setOperationsDetailsAsync: (globalProps, params) => 
      dispatch(setOperationsDetailsAsync(globalProps, params)),

    setOperationsExportAsync: (params) => 
      dispatch(setOperationsExportAsync(params)),

      setOperationsInfo2Async: (info) => 
      dispatch(setOperationsInfo2Async(info)),

    setOperationsInfo3Async: (info) => 
      dispatch(setOperationsInfo3Async(info)),

    setOperationsComponentAsync: key => 
      dispatch(setOperationsComponentAsync(key)),
  };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Results);