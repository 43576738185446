import api from "./api";

export function getBalance(exchange, symbol) {
  return new Promise((resolve) => {
    api.get("/exchange/getBalance", { exchange, symbol }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}
