import { Card } from 'antd';
import i18next from "i18next";

export const CardTicod = ({children, title}) => {
  return (
    <Card
      title={i18next.t(title)}
      size="small"                
      bordered={false}
      className="card-editing"
      style={{ background: '#141414', borderRadius: '8px', marginTop: 10}}
    >    
      {children}
    </Card>
    )
  }