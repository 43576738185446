import i18next from "i18next";
import { convertValuesCoins } from '../../../../shared/utils/convertValuesCoins';
import TableViewBotOnePackagesColumns from './TableViewBotOnePackagesColumns';
import {
  Descriptions,
  Table,
  Empty,
  PageHeader,
  Spin 
} from "antd";

import "../robotViewer.css";

const PartialReinvestmentDOM = ({props, state}) => {
  if (
    props.partialReinvestment &&
    props.partialReinvestment.config
  ) {
    return (
      <Spin
      spinning={state.loadingDrawer}
      >
        <div>
          <PageHeader
            className="setup-page-header"
            onBack={() => {
              props.showReinvestingDetails(false);
            }}
            title={
              <h2 style={{ fontSize: 15, padding: 0, margin: 0 }}>
                {i18next.t("trades.view.reinvest.description_caption")}
              </h2>
            }
          />
          <div className="space-setup-page-header"></div>
          <Descriptions size="small">
            <Descriptions.Item
              label={i18next.t(
                "trades.view.reinvest.description.initialLoss"
              )}
            >
              {props.partialReinvestment.config.initialLoss}
            </Descriptions.Item>
            <Descriptions.Item
              label={i18next.t(
                "trades.view.reinvest.description.totalEarning"
              )}
            >
              {props.partialReinvestment.config.earning}
            </Descriptions.Item>
            <Descriptions.Item
              label={i18next.t("trades.view.reinvest.description.loss")}
            >
              {props.partialReinvestment.config.loss}
            </Descriptions.Item>
            <Descriptions.Item
              label={i18next.t("trades.view.reinvest.description.investment")}
            >
              {props.partialReinvestment.investment}
            </Descriptions.Item>
            <Descriptions.Item
              label={i18next.t("trades.view.reinvest.description.profit")}
            >
              {props.partialReinvestment.profit}
            </Descriptions.Item>
            <Descriptions.Item
              label={i18next.t("trades.view.reinvest.description.earning")}
            >
              {props.partialReinvestment.config.gain}
            </Descriptions.Item>
            <Descriptions.Item
              label={i18next.t("trades.view.reinvest.description.quantity")}
            >
              {`${props.partialReinvestment.config.filled}/${
                props.partialReinvestment.config.filled +
                props.partialReinvestment.config.quantityLeft
                }`}
            </Descriptions.Item>
          </Descriptions>
          <div className="space-setup-page-header"></div>
          <Table
            rowKey="id"
            columns={TableViewBotOnePackagesColumns('columnsTablePackageSetupDetails')}
            dataSource={
              props.partialReinvestment &&
                props.partialReinvestment.operations &&
                props.partialReinvestment.operations.length > 0
                ? props.partialReinvestment.operations
                : []
            }
            locale={{
              emptyText: (
                <Empty
                  description={i18next.t("message.nothing")}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              ),
            }}
            size="small"
            summary={(pageData) => {
              let totalEarning = 0;
              pageData.forEach(({ earning }) => {
                totalEarning = parseFloat(totalEarning) + parseFloat(earning);
              });
              totalEarning = totalEarning.toFixed(4);
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={13}></Table.Summary.Cell>
                    <Table.Summary.Cell>Total:</Table.Summary.Cell>
                    <Table.Summary.Cell>{convertValuesCoins(totalEarning)}</Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      </Spin>
    );
  } else {
    return null;
  }
};

export default PartialReinvestmentDOM;