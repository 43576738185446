import React from "react";

import { useParams } from "react-router-dom";
import { login, logout } from "../../services/apiAuthService";
import { Redirect } from "react-router-dom";

export function Auth(props) {
  let { token } = useParams();

  if(props.login) {
    login(token);
  } else {
    logout();
  }

  return <Redirect to="/" />;
}
