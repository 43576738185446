import i18next from "i18next";
import React from "react";
import "../robotViewer.css";
import { convertValuesCoins } from '../../../../shared/utils/convertValuesCoins';
import { Card, PageHeader } from "antd";
import { loadConvertCoin } from './HeaderDetails';

const HeaderOperations = ({createCard, propsRobot, isProfit}) => {

  const getDataHeader1 = createCard ? (
    <div className="header-cab-1">
        <p className="data-details-operations">{propsRobot.robotFather.bot.name}</p>
        <p className="data-details-operations">{propsRobot.robotOperations.symbol}</p>
        <p className="data-details-operations">{propsRobot.robotOperations.status}</p>
        <p className="data-details-operations">{`${convertValuesCoins(propsRobot.robotOperations.maxPrice)} - 
        ${convertValuesCoins(propsRobot.robotOperations.minPrice)}`}
        </p>
        <p className="data-details-operations">{createCard ? isProfit ? propsRobot.robotOperations.minProfit : propsRobot.robotFather.profit : ''}</p>          
    </div>
    ) : (
      <></>
    )

  const getDataHeader2 = createCard ? (
    <div className="header-cab-1">
        <p className="data-details-operations">{loadConvertCoin(propsRobot.robotOperations.investment)}</p>
        <p className="data-details-operations">{propsRobot.robot.operations.profit}</p>
        <p className="data-details-operations">{convertValuesCoins(propsRobot.robot.operations.earning)}</p>
        <p className="data-details-operations">{propsRobot.robot.operations.quantity}</p>
        <p className="data-details-operations">{isProfit && createCard ? propsRobot.robotOperations.currentProfit : ""}</p>          
    </div>
  ) : (
    <></>
  )

  const getMainHeaders = createCard ? (
    <div className="header-mobile">
      <div className="header-cab-1">
          <h5 className="title-label-details-operations">{i18next.t("operations.select.robot")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("operations.select.coin")}</h5>      
          <h5 className="title-label-details-operations">{i18next.t("trades.view.columns_table_setup_details.status")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("operations.table_operations.operation_range")}</h5>               
          <h5 className="title-label-details-operations">{i18next.t(isProfit ? "trades.view.label.minProfit" : "operations.table_operations.target_profit")}</h5>        
      </div>

      {getDataHeader1}

      <div className="header-cab-1">
          <h5 className="title-label-details-operations">{i18next.t("trades.view.label.investment")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("trades.view.columns_table_details.operations_profit")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("trades.view.columns_table_details.operations_earning")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("operations.table_operations.operations_performed")}</h5>
          <h5 className="title-label-details-operations">{isProfit && createCard ? i18next.t("trades.view.columns_table_details.currentProfit") : "⠀"}</h5>            
      </div>  

      {getDataHeader2}        
    </div>
  ) : (
    <></>
  )
    
  return (
    <>         
      <PageHeader
        style={{ position: "relative", marginTop: "-25px", paddingBottom: "0"}}
        className="setup-page-header"
        title={
          <h2 style={{ fontSize: 18, padding: 0, margin: 0 }}>
          {i18next.t("operations.table_operations.grade_information")}
          </h2>
        }
      />
      <Card
        size="small"        
        bordered={false}
        className="card-header"
        style={{ background: '#141414', borderRadius: '8px', padding: "6px" }}
      >
        {getMainHeaders}
      </Card>  
    </>  
  )    
};

export default HeaderOperations;