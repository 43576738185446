import React from "react";
import { Carousel, Card, Button, message } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import './plans.css';
import logo from "../../assets/rocket.png";
import { connect } from "react-redux";
import { setPlanUserAsync, findPlansAsync, getPlansUserAsync } from './plansSlice'
import i18next from "i18next";

class Plans extends React.Component {

  async onSelectPlan(idPlan, name) {
    await this.props.setPlanUserAsync(idPlan);
    // this.props.getPlansUserAsync();
    message.success(`Plano ${name} adquirido!`);
  }

  componentDidMount() {
    this.props.findPlansAsync();
    // this.props.getPlansUserAsync();
  }

  render() {
    return (
      <div className="plan-container">
        <Card
          className="card-detail-main"
          size="small"
          bordered={false}
          style={{ marginRight: 10 }}
        >
          <div className="title-container">
            {i18next.t("plans.info.ourPlan")}
          </div>
          <div style={{
            height: 127,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 8,
            marginBottom: 30
          }}>
            <img src={logo} alt="Ticod" height="110vh" />
          </div>
          <div className="bots-container">
            <h2 style={{ marginBottom: 0 }}>
              {i18next.t("plans.info.totalInvestment")}
            </h2>
          </div>
          <div className="bots-container"
            style={{ background: '#242323' }}>
            <h2 style={{ marginBottom: 0 }}>
              {i18next.t("plans.info.quantityBots")}
            </h2>
          </div>
          <div className="bots-container">
            <h2 style={{ marginBottom: 0 }}>
              T-One
            </h2>
          </div>
          <div className="bots-container"
            style={{ background: '#242323' }}>
            <h2 style={{ marginBottom: 0 }}>
              T-Package
            </h2>
          </div>
          <div className="bots-container">
            <h2 style={{ marginBottom: 0 }}>
              T-Profit
            </h2>
          </div>
          <div className="bots-container"
            style={{ background: '#242323' }}>
            <h2 style={{ marginBottom: 0 }}>
              T-Profit Plus
            </h2>
          </div>
          <div className="bots-container">
            <h2 style={{ marginBottom: 0 }}>
              T-Profit PG
            </h2>
          </div>
        </Card>

        <Card
          size="small"
          bordered={false}
          style={{
            background: '#141414',
            borderRadius: '8px',
            width: '78%',
            height: 695,
            marginRight: 10
          }}
        >
          <Carousel
            className="carousel-plan"
            style={{
              background: '#141414',
              borderRadius: 8,
              height: 680
            }}
          >
            {
              this.props.plansList.length > 0 ?
                this.props.plansList.map((pages) => {
                  return (
                    <div>
                      <div className="content-style">
                        {
                          pages.map((element) => {
                            return (
                              <Card
                                className="card-detail"
                                size="small"
                                bordered={false}
                                style={{ height: 625, marginRight: 10, border: `${this.props.plansUser.plan?.id === element.id ? '1px #0E83D8 solid' : ''}` }}
                              >
                                <div style={{ width: 318 }}>
                                  <div style={{ height: 15, width: 100, margin: 10 }}></div>
                                  <p style={{ marginBottom: 5, fontSize: 30 }}>{element.name}</p>
                                  <p className="value-plan">{element.value === null ? '-' : element.value + ' ' + element.symbol}</p>
                                  <p style={{ marginBottom: 5 }}>{i18next.t("plans.info.participation")}</p>
                                  <p className="profit">{element.percentualValue === null ? '0%' : element.percentualValue + '%'}</p>
                                  <div className="data-details">{element.totalInvestments === null ? 'Ilimitado' :
                                    element.totalInvestments + ' ' + element.symbol}</div>
                                  <div className="data-details" style={{ background: '#242323' }}>
                                    {element.botQuantity}
                                  </div>
                                  <div className="data-details">
                                    {element.botOne === true ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}
                                  </div>
                                  <div className="data-details" style={{ background: '#242323' }}>
                                    {element.botPackage === true ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}
                                  </div>
                                  <div className="data-details">
                                    {element.botProfit === true ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}
                                  </div>
                                  <div className="data-details" style={{ background: '#242323' }}>
                                    {element.botProfitPlus === true ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}
                                  </div>
                                  <div className="data-details" style={{ marginBottom: 15 }}>
                                    {element.botProfitPg === true ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}
                                  </div>
                                </div>
                                <Button
                                  style={{ width: 120 }}
                                  disabled
                                  type="primary"
                                  onClick={(e) => {
                                    this.onSelectPlan(element.id, element.name);
                                  }}
                                >
                                  {i18next.t("plans.info.assign")}
                                </Button>
                              </Card>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
                : <></>
            }
          </Carousel>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  plansList: state.plans.plansList,
  plansUser: state.plans.plansUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setPlanUserAsync: (planId) =>
      dispatch(setPlanUserAsync(planId)),
    findPlansAsync: () => dispatch(findPlansAsync()),
    getPlansUserAsync: () => dispatch(getPlansUserAsync()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plans);