import React from "react";
import "./robotStop.css";
import { connect } from "react-redux";
import {
  stopBotAsync,
  stopBotReinvestAsync,
  simulateReinvestAsync,
  setProfitAsync,
} from "./robotStopSlice";

import { DescriptionModal } from './components/DescriptionModal';
import RobotStopDetail from './components/RobotStopDetail';

import { Modal } from "antd";
import i18next from "i18next";
const { confirm } = Modal;
 
class RobotStop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      action: "STOP",
      loading: false,
      calculating: false,
      modal: null,
    };
    this.formRef = React.createRef();

    this.onChangeOption = this.onChangeOption.bind(this);
    this.onProfitChange = this.onProfitChange.bind(this);    
    this.onFinish = this.onFinish.bind(this);
  }

  onChangeOption = (e) => {
    this.setState({
      action: e.target.value,
    });
  };

  onProfitChange = (profit) => {
    this.props.setProfitAsync(profit);
  };

  onFinishStop(valueParam) {
    this.setState({ calculating: true }, () => {
      this.props
        .simulateReinvestAsync(
          this.props.robot.id,
          this.props.robot.bot.type,
          valueParam.profit
        )
        .then(() => {
          this.setState({ calculating: false });
        });
    });
  }

  onFinish = () => {
    const { action } = this.state;
    this.setState({ loading: true }, () => {
      if (action === "REINVEST") {
        const Description = DescriptionModal(this.formRef, this.props, this.state, this.onProfitChange, this.onFinishStop)
        this.setState({
          modal: confirm({
            icon: <></>,
            content: Description,
            width: 750,
            okText: i18next.t("trades.stop.options.btn_recovery"),
            cancelText: i18next.t("button.cancel"),
            onOk: () => {
              this.setState({ modal: null }, () => {
                this.props
                  .stopBotReinvestAsync(
                    this.props.robot.id,
                    action,
                    this.props.robot.bot.type,
                    this.props.profit
                  )
                  .then(() => {
                    this.setState({ loading: false }, () => {
                      this.props.closeDrawer();
                    });
                  });
              });
            },
            onCancel: () => {
              this.setState({ loading: false, modal: null });
            },
          }),
        });
      } else {
        this.props
          .stopBotAsync(this.props.robot.id, action, this.props.robot.bot.type)
          .then(() => {
            this.setState({ loading: false }, () => {
              this.props.closeDrawer();
            });
          });
      }
    });
  };

  render() {
    if (this.state.modal) {
      const Description = DescriptionModal(this.formRef, this.props, this.state, this.onProfitChange, this.onFinishStop)
      this.state.modal.update((prevConfig) => ({
        ...prevConfig,
        content: Description,
      }));
    }

    const { action, loading } = this.state;
    return (
      <RobotStopDetail 
        props={this.props} 
        action={action} 
        loading={loading}
        onChangeOption={this.onChangeOption} 
        onFinish={this.onFinish} />
    );
  }
}

const mapStateToProps = (state) => ({
  reinvestInfo: state.robotStop.reinvestInfo,
  profit: state.robotStop.profit,
});

const mapDispatchToProps = (dispatch) => {
  return {
    stopBotAsync: (robotId, action, type) =>
      dispatch(stopBotAsync(robotId, action, type)),
    stopBotReinvestAsync: (id, action, type, profit) =>
      dispatch(stopBotReinvestAsync(id, action, type, profit)),
    simulateReinvestAsync: (id, type, profit) =>
      dispatch(simulateReinvestAsync(id, type, profit)),
    setProfitAsync: (profit) => dispatch(setProfitAsync(profit)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RobotStop);
