import { Component } from 'react';
import ApexCharts from "react-apexcharts";
import i18next from "i18next";

let languageOld = ''
class ActivateOperations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seriesCompare: [{
        name: '', data: ['']
      }],
      series: [{
        name: '', data: ['']
      }],
      options: {
        xaxis: {
          categories: [''],
        },
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#92D100', '#FF0000'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            opacity: 0.5
          },
        },
        yaxis: {
          // max: 50
        },
        theme: {
          mode: 'dark',
        },
      },
    }
  }

  componentDidUpdate() {
    this.updateChart();
  }

  updateChart() {
    if (this.props.props.activeOperations &&
      this.props.props.activeOperations.series &&
      this.props.props.activeOperations.series.length > 0) {
      if (((this.state.seriesCompare) !== (this.props.props.activeOperations.series)) ||
        (languageOld !== this.props.props.language)) {
        languageOld = this.props.props.language;
        const legendLanguage = this.props.props.activeOperations.series.map((item) => {
          if (item.name === 'Buy') {
            if (this.props.props.language === "pt" || this.props.props.language === "es") {
              return { name: "Compra", data: item.data }
            } else {
              return { name: item.name, data: item.data }
            }
          }

          if (item.name === 'Sell') {
            if (this.props.props.language === "pt" || this.props.props.language === "es") {
              return { name: "Venda", data: item.data }
            } else {
              return { name: item.name, data: item.data }
            }
          }
          return item;
        })

        let config = this.state.options;
        config.xaxis = { categories: this.props.props.activeOperations.categories };
        config.yaxis = { max: this.props.props.activeOperations.max };

        this.setState({ options: config });
        this.setState({ series: legendLanguage });
        this.setState({ seriesCompare: this.props.props.activeOperations.series });
      }

    }
  }

  render() {
    const show = this.state.series[0] && (this.state.series[0].data.length > 0 || this.state.series[1].data.length > 0)
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {this.state.series[0].name !== '' && show ?
          <ApexCharts
            options={this.state.options}
            series={this.state.series}
            type="line"
            height={265}
            style={{ height: 250, width: 450 }}
          />
          : <>{i18next.t("dashboard.overview.noOrder")} </>}
      </div>
    )
  }
}

export default ActivateOperations;