import { createSlice } from "@reduxjs/toolkit";
import { getBalance } from "../../services/apiWalletService";

export const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    balance: [],
    balanceSearch: 0,
  },
  reducers: {
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    addBalanceSearch: (state, action) => {
      state.balanceSearch = action.payload;
    },
    stopBalanceSearch: (state) => {
      clearInterval(state.balanceSearch);
    },
  },
});

const { setBalance, addBalanceSearch, stopBalanceSearch } = walletSlice.actions;

export const findBalanceAsync = (exchangeName, symbol) => (dispatch) => {
  return new Promise((resolve) => {
    let running = false;
    dispatch(stopBalanceSearch());
    getBalance(exchangeName, symbol).then((recordList) => {
      dispatch(setBalance(recordList));      
    });
    dispatch(
      addBalanceSearch(
        setInterval(() => {
          if (!running) {
            running = true;
            getBalance(exchangeName, symbol).then((recordList) => {
              dispatch(setBalance(recordList));
              running = false;
            });
          }
        }, 6000)
      )
    );
    resolve();    
  });
};

export const stopBalanceSearchInterval = () => (dispatch) => {
  dispatch(stopBalanceSearch());
};

export default walletSlice.reducer;
