import React from 'react';
import './trades.css';
import { connect } from 'react-redux';
import i18next from 'i18next';

import {
  Tabs,
  Button,
  Drawer,
  Row,
  Col,
  Select,
  notification,
  Checkbox,
  Modal,
  Spin
} from 'antd';

import {
  ArrowRightOutlined,
  RobotOutlined
} from '@ant-design/icons';

import Draggable from 'react-draggable';

import {
  findEnabledBotAsync,
  findDisabledBotAsync,
  stopBotInterval,
  setActiveTabAsync,
  updateEnabledBotListAsync
} from './tradeSlice';

import {getAccountAsync} from "../exchanges/exchangeSlice";
import { findAllExchangeAsync } from '../shared/exchangeListSlice';
import { findAllSymbolAsync } from '../shared/coinSlice';
import {
  findBalanceAsync,
  stopBalanceSearchInterval,
} from '../shared/walletSlice';
import {
  findBookAsync,
  stopBookSearchInterval,
} from '../shared/orderBookSlice';

import { showChild, showReinvestingDetails, stopBotDetailInterval } from './robot/robotViewer/robotViewerSlice';
import RobotViewer from './robot/robotViewer/robotViewer';
import RobotEditing from './robot/robotEditing/robotEditing';
import { clearSettings } from './robot/robotSlice';
import RobotStop from './robot/robotStop/robotStop';
import RobotCreate from './robot/robotCreate/robotCreate';

// import TableBotsActived from './components/TableBotsActived'; //legacy
import TableBotsActivedWs from './components/TableBotsActivedWs';
import TableBotsHistory from './components/TableBotsHistory';
import TableBotsOrders from './components/TableBotsOrders';
import TradingWidget from './components/TradingWidget';

const { TabPane } = Tabs;
const { Option } = Select;

const FILTER_COIN_ID = 1;
const FILTER_COIN_NAME = 2;

let isMountedComponent = true;
class Trades extends React.Component {  
  constructor(props) {
    super(props);
    this.showDrawer = this.showDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.duplicate = this.duplicate.bind(this);
    this.stringSorter = this.stringSorter.bind(this);
    this.onChangeExchangeSelect = this.onChangeExchangeSelect.bind(this);
    this.updateCoin = this.updateCoin.bind(this);
    this.onChangeCoinSelect = this.onChangeCoinSelect.bind(this);
    this.tradesOpenRobotViewer = this.tradesOpenRobotViewer.bind(this);
    this.tradesOpenRobotRegister = this.tradesOpenRobotRegister.bind(this);
    this.tradesStopRobot = this.tradesStopRobot.bind(this);
    

    this.state = {
      height: window.innerHeight,
      visibleDrawer: false,
      exchangeSelected: {},
      coinSelected: {},
      drawerProps: {},
      drawnComponent: "",
      drawnComponentProps: {},
	    drawnComponentPropsModal: {},
      loadingPage: false,
      loadingPageCoin: false,
	    visibleModalBot: false,
      bounds: { left: 0, top: 0, bottom: 0, right: 0 },
      disabled: true,
      windowSize: [0, 0]   
    };
  }


  componentWillUnmount() {
    isMountedComponent = false;
    this.props.stopBotInterval();
    this.props.stopBookSearchInterval();
    this.props.stopBalanceSearchInterval();    
  }

  async componentDidMount() {
    this._useWindowSize();
    window.addEventListener('resize', this._useWindowSize);

    isMountedComponent = true;  
    await this.props.getAccountAsync();
    let indexCoin = {};

    const coinName = JSON.parse(localStorage.getItem("coinName"));
    this.setState({ loadingPage: true }, () => {   
      this.props.findAllExchangeAsync().then(() => {
        if (isMountedComponent) {        
          this.setState({ exchangeSelected: this.props.allExchange[0] }, () => {
            const exchangeName = this.state.exchangeSelected.id;
            
              this.props.findAllSymbolAsync(exchangeName).then(() => {

                if (coinName) {
                  this.props.allSymbol.forEach((element) => {
                    if (element.id === coinName.id) {
                      indexCoin = element
                    }
                  });
                } else {
                  indexCoin = this.props.allSymbol[0];
                }
                if (isMountedComponent) {
                  this.setState({ coinSelected: indexCoin }, () => {            
                    this.props
                      .findBalanceAsync(exchangeName, this.state.coinSelected.name)
                      .then(() => {
                        this.props
                          .findBookAsync(
                            exchangeName,
                            this.state.coinSelected.pair,
                            this.state.coinSelected.price_precision,
                            this.state.coinSelected.quantity_precision,
                            this.state.coinSelected.fee_precision
                          )
                          .then(() => {
                            this.setState({ loadingPage: false });                          
                          });
                      });                  
                  });
                } else {
                  this.setState({ loadingPage: false });
                }
              });
          });
        } else {
          this.setState({ loadingPage: false });
        }
      });  
    });      
    
    this.props.setActiveTabAsync('tabBotsActive');
    //tabBotsActive //tabBotsHistory

    window.TICOD = {};
    window.TICOD.tradesOpenRobotViewer = this.tradesOpenRobotViewer;
    window.TICOD.tradesOpenRobotRegister = this.tradesOpenRobotRegister;
    window.TICOD.tradesStopRobot = this.tradesStopRobot;
    window.TICOD.updateCoin = this.updateCoin;
  }

  _useWindowSize = () => {
    this.setState({ 
      windowSize: [window.innerWidth, window.innerHeight] 
    });
  }

  showDrawer = () => {
    this.setState({
      visibleDrawer: true,
    });
    this.props.stopBotInterval();
  }

  showModalBot = () => {
    this.setState({
      visibleModalBot: true,
    });
  };

  closeModalBot = () => {
    this.props.clearSettings();    
    this.setState({
      visibleModalBot: false,
    });    
  }  

  closeDrawer = () => {    
    if (this.props.childVisible) {
      this.props.showChild(false);
    } else {
      // this.props.findEnabledBotAsync({exchange: this.state.exchangeSelected.id});
      // this.props.findDisabledBotAsync(this.state.exchangeSelected.id);
      this.props.clearSettings(); // Limpa as configurações para cadastrar robos
      this.props.stopBotDetailInterval(); // Finaliza os endpoits de detalhes dos robos
      this.setState({
        visibleDrawer: false,
      });    
    }
  }

  onChangeExchangeSelect(value) {
    this.setState({
      exchangeSelected: this.props.allExchange.find(
        (item) => item.id === value
      ),
    });
  }

  updateCoin(value, type, loading = true) {
    return new Promise((resolve) => {      
      const exchangeName = this.state.exchangeSelected.id;
      if (loading) {
        this.setState({loadingPageCoin: true});
      }
      this.setState(
        {
          coinSelected: this.props.allSymbol.find((item) => {
            if (type === FILTER_COIN_ID) {
              return item.id === value;
            } else if (type === FILTER_COIN_NAME) {
              return item.name === value;
            } else {
              return false;
            }
          }),
        },          
        () => {            
        this.props
          .findBalanceAsync(exchangeName, this.state.coinSelected.name)
          .then(() => {
            this.props
              .findBookAsync(
                exchangeName,
                this.state.coinSelected.pair,
                this.state.coinSelected.price_precision,
                this.state.coinSelected.quantity_precision,
                this.state.coinSelected.fee_precision
              )
              .then(() => {
                if (!(JSON.stringify(this.state.coinSelected) === JSON.stringify({}))) {
                  localStorage.setItem("coinName", JSON.stringify(this.state.coinSelected));
                }
                if (loading) {
                  this.setState({ loadingPageCoin: false })
                }
                resolve();                    
              });
            });
          });
        }
      );
  }

  onChangeCoinSelect(value) {
    this.updateCoin(value, FILTER_COIN_ID);
  }

  stringSorter(a, b) {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }

  duplicate(parentBot) {
    this.updateCoin(parentBot.symbol, FILTER_COIN_NAME, false);
    this.setState(
      {
        drawerProps: {
          width: `${this.state.windowSize[0] <= 900 ? '100%' : '390px'}`,
        },
        drawnComponent: RobotEditing,
        drawnComponentProps: {
          language: this.props.language,
          closeDrawer: this.closeDrawer,
          exchangeName: this.state.exchangeSelected.id,
          robot: parentBot,
          duplicating: true,
          date_time: JSON.stringify(new Date().getTime()),
        },
      },
      () => {
        this.showDrawer();
      }
    );
  }

  tradesOpenRobotViewer(record) {
    // this.updateCoin(record.symbol, FILTER_COIN_NAME);
    this.setState({
      drawerProps: {
        width: `${this.state.windowSize[0] <= 900 ? '100%' : '65%'}`,
        afterVisibleChange: () => {
          if (this.props.childVisible) {
            this.props.showChild(false);
          }
          if (this.props.reinvestingDetailsVisible) {
            this.props.showReinvestingDetails(false);
          }
        },
      },
      drawnComponent: RobotViewer,
      drawnComponentProps: {
        language: this.props.language,
        showOptions: false,
        closeDrawer: this.closeDrawer,
        duplicate: this.duplicate,
        exchangeName: this.props.exchangeName,
        robot: record,
        date_time: JSON.stringify(new Date().getTime()),
      },
    }, () => { this.showDrawer() });
  }

  tradesOpenRobotRegister(record) {
   // this.updateCoin(record.symbol, FILTER_COIN_NAME, false);
    this.setState(
      {
        drawerProps: {
          width: `${this.state.windowSize[0] <= 900 ? '100%' : '390px'}`,
        },
        drawnComponent: RobotEditing,
        drawnComponentProps: {
          language: this.props.language,
          closeDrawer: this.closeDrawer,
          exchangeName: this.props.exchangeName,
          robot: record,
          editing: true,
          date_time: JSON.stringify(new Date().getTime()),
        },
      },
      () => {
        this.showDrawer();
      }
    );
  }

  tradesStopRobot(record) {
    this.setState(
      {
        drawerProps: {
          width: `${this.state.windowSize[0] <= 900 ? '100%' : '33%'}`,
        },
        drawnComponent: RobotStop,
        drawnComponentProps: {
          language: this.props.language,
          closeDrawer: this.closeDrawer,
          robot: record,
        },
      },
      () => {
        this.showDrawer();
      }
    );
  }

  tabsChange = activeKey => 
    this.props.setActiveTabAsync(activeKey);
	
  onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = this.draggleRef?.current?.getBoundingClientRect();    
    this.setState({
      bounds: {
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      },
    });
  };	

  render() {
    const exchangeName = this.state.exchangeSelected.id;
    const { bounds, disabled } = this.state;

    const operations = (
      <>
        <ArrowRightOutlined
          className="arrow-robo"
          style={{ paddingRight: 12 }}
        />
        <Button
          type="primary"
          size="small"
          onClick={() => {
            const key = `open_${Date.now()}`;
            let notShowAgain = false;
            const msg = (
              <div>
                <p>{i18next.t("trades.warning.message")}</p>
                <Row key="row_warning_createbot">
                  <Col
                    key="col_notshowagain_warningcreatebot"
                    span={20}
                    style={{ textAlign: "left" }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        notShowAgain = e.target.checked;
                      }}
                    >
                      {i18next.t("trades.warning.not_show_again")}
                    </Checkbox>
                  </Col>
                  <Col
                    key="col_btnok_warningcreatebot"
                    span={4}
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        if (notShowAgain) {
                          localStorage.setItem(
                            "notshowagain-warningcreatebot",
                            notShowAgain
                          );
                        }
                        notification.close(key);
                      }}
                    >
                      Ok
                    </Button>
                  </Col>
                </Row>
              </div>
            );

            if (this.props.listAccount[0] === 1) {
              notification.error({
                key,
                message: i18next.t("trades.warning.title"),
                description: i18next.t("trades.warning.message_warning"),
                duration: 0,
                className: "message-without-exchange"
              });
            } else {
              const notShowAgainStored = localStorage.getItem(
                "notshowagain-warningcreatebot"
              );
  
              if (!notShowAgainStored) {
                notification.open({
                  key,
                  message: i18next.t("trades.warning.title"),
                  description: msg,
                  duration: 0,
                });
              }

              this.setState(
                {
                  drawerProps: {
                    width: `${this.state.windowSize[0] <= 900 ? '100%' : '33%'}`,
                  },
                  drawnComponent: RobotCreate,
                  drawnComponentPropsModal: {
                    language: this.props.language,
                    closeModalBot: this.closeModalBot,
                    exchangeName,
                    coinSelected: this.state.coinSelected,
                    newBot: true,                    
                  },
                },
                () => {                
                  this.showModalBot();
                }
              );              
            }
          }}
        >
          <RobotOutlined /> {i18next.t("trades.button.register_bot")}
        </Button>
      </>
    );

    const drawerProps = this.state.drawerProps;
    const DrawnComponent = this.state.drawnComponent;
    const drawnComponentProps = this.state.drawnComponentProps;
    const drawnComponentPropsModal = this.state.drawnComponentPropsModal;    

    const exchangeChartParam =
      this.state.exchangeSelected.id === "novadax"
        ? "mercado"
        : this.state.exchangeSelected.id;

    const tabActive = this.props.tabActive;

    return (        
      <Spin className="screen-loader-container-trades-coins" spinning={this.state.loadingPageCoin}>
        <Spin className="screen-loader-container-trades" spinning={this.state.loadingPage}>
          <div className="trades-container" style={{ maxWidth: 1680, margin: "0 auto" }}>        
            <Row gutter={[9, 9]}>
              <Col
                xs={{ span: 25, order: 1 }}
                sm={{ span: 25, order: 1 }}
                md={{ span: 25, order: 1 }}
                lg={{ span: 18, order: 1 }}
                xl={{ span: 18, order: 1 }}
              >
                <TradingWidget {...{ state: this.state, exchangeChartParam  }} />
                
                <div className="line"></div>

                <div className="tab-container">
                  <Tabs 
                    onChange={this.tabsChange} 
                    tabBarExtraContent={operations}
                    activeKey={tabActive}
                    >
                    <TabPane className="table-bots-container" tab={i18next.t("trades.tab.active")} key="tabBotsActive">
                    {/* legacay */}
                      {/* <TableBotsActived {...this.props} /> //  */}
                      <TableBotsActivedWs {...this.props} />
                    </TabPane>
                    <TabPane className="table-bots-container" tab={i18next.t("trades.tab.log")} key="tabBotsHistory">
                      <TableBotsHistory {...this.props} />
                    </TabPane>
                  </Tabs>
                </div>
              </Col>
              <Col
                  xs={{ span: 25, order: 2 }}
                  sm={{ span: 25, order: 2 }}
                  md={{ span: 25, order: 2 }}
                  lg={{ span: 6, order: 2 }}
                  xl={{ span: 6, order: 2 }}
                >
                  <Select
                    showSearch
                    placeholder="Exchange"
                    optionFilterProp="children"
                    style={{ width: "50%" }}
                    onChange={this.onChangeExchangeSelect}
                    defaultValue={"binance"}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.props.allExchange.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    showSearch
                    placeholder="Moeda"
                    optionFilterProp="children"
                    style={{ width: "50%", paddingLeft: 9 }}
                    onChange={this.onChangeCoinSelect}
                    value={this.state.coinSelected.id}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={this.state.loadingPageCoin ? true : false}
                  >
                    {this.props.allSymbol.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>

                  {this.props.balance && this.props.balance.length > 0 ? (
                    <>
                      <div className="line"></div>
                      <div className="tab-container">
                        <h4 style={{ paddingTop: 9 }}>
                          {i18next.t("trades.label.wallet")}
                        </h4>
                        {this.props.balance.map((item) => (
                          <Row key={`row_${item.asset}`}>
                            <Col key={`col_caption_${item.asset}`} span={12}>
                              {item.asset}
                            </Col>
                            <Col
                              key={`col_value_${item.asset}`}
                              span={12}
                              style={{ textAlign: "right" }}
                            >
                              {item.available}
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="line"></div>

                  <TableBotsOrders props={this.props} state={this.state} />
                  
                </Col>          
            </Row>        

            {
            this.state.visibleDrawer ?
            <Drawer
              placement="left"
              onClose={this.closeDrawer}
              visible={this.state.visibleDrawer}
              bodyStyle={{ padding: 11, background: '#0e0e0e' }}
              {...drawerProps}
            >
              {<DrawnComponent {...drawnComponentProps} />}
            </Drawer> :
            <Modal          
              destroyOnClose={true}
              title={
              <div
                style={{
                  width: '100%',
                  cursor: 'move',
                }}
                onMouseOver={() => {
                  if (disabled) {
                    this.setState({
                      disabled: false,
                    });
                  }
                }}
                onMouseOut={() => {
                  this.setState({
                    disabled: true,
                  });
                }}            
              >
                {i18next.t("trades.register.label.title")}
              </div>
              }
              visible={this.state.visibleModalBot}          
              width={900}        
              footer={null}  
              onCancel={this.closeModalBot}          
              bodyStyle={{ background: '#131212',padding: 15 }}
              maskClosable={false}
              modalRender={modal => (
                <Draggable
                  disabled={disabled}
                  bounds={bounds}
                  onStart={(event, uiData) => this.onStart(event, uiData)}
                >
                  <div ref={this.draggleRef}>{modal}</div>
                </Draggable>
              )}          
              >
              {<DrawnComponent {...drawnComponentPropsModal} />}
            </Modal>
            }
          </div>
        </Spin>
      </Spin>            
    );
  }
}

const mapStateToProps = (state) => ({
  allExchange: state.exchangeList.allExchange,
  allSymbol: state.coin.allSymbol,
  balance: state.wallet.balance,
  bookBuy: state.orderBook.bookBuy,
  bookSell: state.orderBook.bookSell,
  exchangeName: state.trades.exchangeName,
  tabActive: state.trades.tabActive,
  tradesState: state.trades.tradesState,
  enabledBotList: state.trades.enabledBotList,
  disabledBotList: state.trades.disabledBotList,
  childVisible: state.robotViewer.childVisible,
  reinvestingDetailsVisible: state.robotViewer.reinvestingDetailsVisible,
  listAccount: state.exchanges.listAccount,
});

const mapDispatchToProps = (dispatch) => {
  return {
    findAllExchangeAsync: () => dispatch(findAllExchangeAsync()),

    findAllSymbolAsync: (exchangeName) =>
      dispatch(findAllSymbolAsync(exchangeName)),

    findBalanceAsync: (exchangeName, symbol) =>
      dispatch(findBalanceAsync(exchangeName, symbol)),

    stopBalanceSearchInterval: () => dispatch(stopBalanceSearchInterval()),

    findBookAsync: (
      exchangeName,
      symbol,
      pricePrecision,
      amountPrecision,
      totalPrecision
    ) =>
      dispatch(
        findBookAsync(
          exchangeName,
          symbol,
          pricePrecision,
          amountPrecision,
          totalPrecision
        )
      ),

    stopBookSearchInterval: () => 
      dispatch(stopBookSearchInterval()),

    findEnabledBotAsync: (params, callback) =>
      dispatch(findEnabledBotAsync(params, callback)),

    findDisabledBotAsync: (params, callback) =>
      dispatch(findDisabledBotAsync(params, callback)),

    stopBotInterval: () => dispatch(stopBotInterval()),

    stopBotDetailInterval: () => dispatch(stopBotDetailInterval()),

    showChild: (show) => dispatch(showChild(show)),

    showReinvestingDetails: (reinvestingDetailsVisible) =>
      dispatch(showReinvestingDetails(reinvestingDetailsVisible)),

    clearSettings: () => dispatch(clearSettings()),

    setActiveTabAsync: (tab) => 
      dispatch(setActiveTabAsync(tab)),

    updateEnabledBotListAsync: (list) => 
      dispatch(updateEnabledBotListAsync(list)),
      
    getAccountAsync: () => dispatch(getAccountAsync()),   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trades);
