import { wsUrlApi } from "./api";
import { io } from "socket.io-client";
import { getUser } from "./apiAuthService";

export const userInfo = getUser();

export const wsBotConnect = (props) => {
  const xTudo = localStorage.getItem("x-access-token");

  const config = {
    transports: ["websocket"],
    path: '/ws/socket.io',
    query: { uid: props.id, jwt: xTudo },
  };

  if (xTudo) {
    return io.connect(`${wsUrlApi}`, config);
  }
    
  return null;
}




  