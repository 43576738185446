import { apiBinance } from "./apiBinance";

export function getBook(exchange, symbol) {
  return new Promise((resolve) => {
    if (exchange === "binance") {
      apiBinance.get("/depth", { symbol, limit: 20 }).then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
    } else {
      resolve([]);
    }
  });
}

export function getCurrentPriceCoin(symbol) {
  return new Promise((resolve) => {    
      apiBinance.get("/ticker/price", { symbol }).then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

//TODO: pegar o preço atual daqui: https://api.binance.com/api/v3/ticker/price
