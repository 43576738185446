import { createSlice } from "@reduxjs/toolkit";
import {
  getProfile,
  saveProfile,
  findAllAvatar,
  getAvatar,
  findAllCountry,
  findAllCoin,
  getPreferences,
  savePreferences,
  changePassword,
  deleteAccount,
  getTwoFactor,
  enableTwoFactor,
  disableTwoFactor,
  verifySixPassTwoFactor,
  enableTwoFactorVerify,
} from "../../services/apiSettingsService";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    profile: {},
    avatar: null,
    avatarList: [],
    countryList: [],
    coinList: [],
    preferences: {},
    accountDeleted: false,
    twoFactor: {},
    enableTwoFactor: {},
    disableTwoFactor: {},
    verifySixPassTwoFactor: {},
    enableTwoFactorVerify: {},
    toggleTwoFactor: false,
    toggleTwoFactorAuth: false,
    visibleModalSecurity: false
  },
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setAvatarList: (state, action) => {
      state.avatarList = action.payload;
    },
    setCountryList: (state, action) => {
      state.countryList = action.payload;
    },
    setCoinList: (state, action) => {
      state.coinList = action.payload;
    },
    setPreferences: (state, action) => {
      state.preferences = action.payload;
    },
    setAccountDeleted: (state, action) => {
      state.accountDeleted = action.payload;
    },
    setTwoFactor: (state, action) => {
      state.twoFactor = action.payload;
    }, 
    setEnableTwoFactor: (state, action) => {
      state.enableTwoFactor = action.payload;
    },
    setDisableTwoFactor: (state, action) => {
      state.disableTwoFactor = action.payload;
    },
    setVerifySixPassTwoFactor: (state, action) => {
      state.verifySixPassTwoFactor = action.payload;
    },  
    setEnableTwoFactorVerify: (state, action) => {
      state.enableTwoFactorVerify = action.payload
    },
    setToggleTwoFactor: (state, action) => {
      state.toggleTwoFactor = action.payload;
    }, 
    setToggleTwoFactorAuth: (state, action) => {
      state.toggleTwoFactorAuth = action.payload;
    },  
    setVisibleModalSecurity: (state, action) => {
      state.visibleModalSecurity = action.payload;
    },             
  },
});

const {
  setProfile,
  setAvatar,
  setAvatarList,
  setCountryList,
  setCoinList,
  setPreferences,
  setAccountDeleted,
  setTwoFactor,
  setEnableTwoFactor,
  setDisableTwoFactor,
  setVerifySixPassTwoFactor,
  setEnableTwoFactorVerify,
  setToggleTwoFactor,
  setToggleTwoFactorAuth,
  setVisibleModalSecurity
} = settingsSlice.actions;

export const getProfileAsync = (userId) => (dispatch) => {
  return getProfile(userId).then((record) => {
    dispatch(setProfile(record));
  });
};

export const setToggleTwoFactorAsync = (checked) => (dispatch) => {
  return dispatch(setToggleTwoFactor(checked))  
};

export const setToggleTwoFactorAuthAsync = (checked) => (dispatch) => {
  return dispatch(setToggleTwoFactorAuth(checked))  
};

export const visibleModalSecurityAsync = (visible) => (dispatch) => {
  return dispatch(setVisibleModalSecurity(visible))  
};

export const getTwoFactorAsync = () => (dispatch) => {
  return getTwoFactor().then((record) => {
    dispatch(setTwoFactor(record));
  });
};

export const enableTwoFactorPayload = () => (dispatch) => {
  return enableTwoFactor().then((record) => {
    dispatch(setEnableTwoFactor(record));
  })
}

export const disableTwoFactorPayload =() => (dispatch) => {
  return disableTwoFactor().then((record) => {
    dispatch(setDisableTwoFactor(record));
  })
}

export const verifySixPassTwoFactorAsync = (passInfo) => (dispatch) => {
  return verifySixPassTwoFactor(passInfo).then((record) => {
    dispatch(setVerifySixPassTwoFactor(record));
  });
};

export const enableTwoFactorVerifyAsync = (params) => (dispatch) => {
  return enableTwoFactorVerify(params).then((record) => {
    dispatch(setEnableTwoFactorVerify(record));
  });
};

export const saveProfileAsync = (profile, avatar) => (dispatch) => {
  return new Promise((resolve) => {
    saveProfile(profile).then((record) => {
      const message = record.message;
      const error = record.error;
      if (!!error) {
        resolve({ error, message });
      } else {
        dispatch(setProfile(record));
        if (avatar && avatar.id) {
          dispatch(setAvatar(avatar));
        }
      }
      resolve();
    });
  });
};

export const findAllAvatarPayload = () => (dispatch) => {
  return findAllAvatar().then((recordList) => {
    dispatch(setAvatarList(recordList));
  });
};

export const getAvatarPayload = (id) => (dispatch) => {
  return getAvatar(id).then((record) => {
    dispatch(setAvatar(record));
  });
};

export const findAllCountryPayload = () => (dispatch) => {
  return findAllCountry().then((recordList) => {
    dispatch(setCountryList(recordList));
  });
};

export const findAllCoinPayload = () => (dispatch) => {
  return findAllCoin().then((recordList) => {
    dispatch(setCoinList(recordList));
  });
};

export const getPreferencesAsync = (userId) => (dispatch) => {
  return getPreferences(userId).then((record) => {
    dispatch(setPreferences(record));
  });
};

export const savePreferencesAsync = (preferences) => (dispatch) => {
  return new Promise((resolve) => {
    savePreferences(preferences).then((record) => {
      const message = record.message;
      const error = record.error;
      if (!!error) {
        resolve({ error, message });
      } else {
        dispatch(setPreferences(record));
      }
      resolve();
    });
  });
};

export const changePasswordAsync = (userPass) => () => {
  return new Promise((resolve) => {
    changePassword(userPass).then((record) => {
      const message = record.message;
      const error = record.error;
      if (!!error) {
        resolve({ error, message });
      }
      resolve();
    });
  });
};

export const deleteAccountAsync = (email) => (dispatch) => {
  return deleteAccount(email).then((record) => {
    dispatch(setAccountDeleted(record));
  });
};

export default settingsSlice.reducer;
