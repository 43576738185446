import React, { Fragment, useCallback, useEffect, useState } from 'react';
import TableBotsColumns from './TableBotsColumns';
import i18next from 'i18next';
import moment from 'moment';
import '../trades.css';

import {
  Table,
  Empty,
  DatePicker,
  Spin
} from 'antd';

const { RangePicker } = DatePicker;

const TableBotsHistory = (props) => {

  const [tab, setTab] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleDatePicker = (dates, dateStrings) => {

    if (!dates || !dates[0]) {
      return false;
    }

    const startDate = 
      moment(dates[0]).format('YYYY-MM-DD');

    const endDate = 
      moment(dates[1]).format('YYYY-MM-DD');

    return setFindAsync({ startDate, endDate });
  };
  
  const setFindAsync = useCallback(params => {
    setLoading(true);

    props.findDisabledBotAsync({ 
      exchange: props.exchangeName, ...params 
    }, () => setLoading(false));
  }, [props]);

  useEffect(() => {
    setTab(props.tabActive);

    if ((tab !== props.tabActive) && ((props.listAccount[0] !== 1) && (props.listAccount[0] !== 0))
        && (props.tabActive === 'tabBotsHistory')
      ) {
      setFindAsync({
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      });
    }
  }, [props, setFindAsync, tab]);
  

  return (
    <Fragment>

        { loading ? <div className="mainLoading"><Spin /></div> : '' }

      <div style={{ marginBottom: 10, display: 'flex'}}>
        {/* Pesquisar: &nbsp; */}

        <RangePicker 
          onChange={handleDatePicker}
          format="DD/MM/YY"
          disabledDate={(current) => current < moment().subtract(90, 'days') || current > moment() }
          
          ranges={{
            [`${i18next.t("portfolio.operations.filters.placeholders.today")}`]: [moment(), moment()],
            [`${i18next.t("portfolio.operations.filters.placeholders.1week")}`]: [moment().subtract(7, 'days'), moment()],
            [`${i18next.t("portfolio.operations.filters.placeholders.1month")}`]: [moment().subtract(31, 'days'), moment()],
            [`${i18next.t("portfolio.operations.filters.placeholders.3months")}`]: [moment().subtract(90, 'days'), moment()],
            [`${i18next.t("portfolio.operations.filters.placeholders.6months")}`]: [moment().subtract(6, 'months'), moment()],
            [`${i18next.t("portfolio.operations.filters.placeholders.1year")}`]: [moment().subtract(12, 'months'), moment()],
          }}
          
          placeholder={[
            i18next.t("portfolio.operations.filters.placeholders.startDate"),
            i18next.t("portfolio.operations.filters.placeholders.endDate")
          ]}
          />
      </div>

      <Table
        style={{ minHeight: 383, maxHeight: 383 }}
        className="usrTableHistory" 
        rowKey={(record) => record.id}
        columns={TableBotsColumns('disabledBotColumns')}
        dataSource={props.disabledBotList}
        locale={{
          emptyText: (
            <Empty
              description={i18next.t("message.nothing")}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        size="small"
        pagination={false}
        footer={() => props.disabledBotList.length > 0 ? `Results: ${props.disabledBotList.length}` : <></>}
        scroll={{ x: "none", y: "310px" }}
      />
    </Fragment>
  )
}

export default TableBotsHistory;