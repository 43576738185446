import api from "./api";

export function createConfigPackageBot(exchange, robot) {
  return new Promise((resolve) => {
    api
      .post(`/exchange/createConfigPackageBot?exchange=${exchange}`, robot)
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function startBot(exchange, robot) {
  return new Promise((resolve) => {
    api
      .post(`/exchange/startBot?exchange=${exchange}`, robot)
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function editConfigPackageBot(exchange, robot) {
  return new Promise((resolve) => {
    api
      .post(`/exchange/editConfigPackageBot?exchange=${exchange}`, robot)
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function editBot(exchange, robot) {
  return new Promise((resolve) => {
    api
      .post(`/exchange/editBot?exchange=${exchange}`, robot)
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function getBot(exchange, id, type) {
  return new Promise((resolve) => {
    api.get("/exchange/getBot", { exchange, id, type }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function simulateProfitPgGrids(exchange, robot) {

  const { investment, quantity, symbol, drop } = robot;

  return new Promise((resolve) => {
    api
      .get(`/exchange/simulateProfitPgGrids`, { exchange, quantity, investment, symbol, drop })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function simulatePackageGrids(exchange, robot) {

  const { investment, quantity, symbol, drop } = robot;

  return new Promise((resolve) => {
    api
      .get(`/exchange/simulatePackageGrids`, { exchange, quantity, investment, symbol, drop })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function simulateProfitPlusGrids(exchange, robot) {

  const { investment, quantity, symbol, drop } = robot;

  return new Promise((resolve) => {
    api
      .get(`/exchange/simulateProfitPlusGrids`, { exchange, quantity, investment, symbol, drop })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}