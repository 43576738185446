import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import config from "./config"

import { Result, Button } from "antd";
import { Auth } from "./components/auth/auth";
import { Protected } from "./components/protected/protected";

export function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/auth/:token">
          <Auth login={true} />
        </Route>
        <Route path="/logout">
          <Auth login={false} />
        </Route>
        <Route
          exact
          path="/login"
          component={() => {
            window.location.href = config.URL_LOGIN;
            return null;
          }}
        />
        <Route exact path="/">
          <Protected />
        </Route>
        <Route path="*">
          <Result
            status="404"
            title="404"
            subTitle="Desculpe, a página que você visitou não existe."
            extra={
              <Button href="/" type="primary">
                Página Inicial
              </Button>
            }
          />
        </Route>
      </Switch>
    </Router>
  );
}
