import { createSlice } from "@reduxjs/toolkit";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import commonPt from "../locales/pt/common.json";
import commonEn from "../locales/en/common.json";
import commonEs from "../locales/es/common.json";

export const mainSlice = createSlice({
  name: "main",
  initialState: {
    language: "",
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

const { setLanguage } = mainSlice.actions;

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

const resources = {
  pt: { common: commonPt },
  en: { common: commonEn },
  es: { common: commonEs },
};

export const changeLanguage = (language) => (dispatch) => {
  return i18next.changeLanguage(language, (err, t) => {
    if (err) return console.log("something went wrong changing", err);
    dispatch(setLanguage(language));
    localStorage.setItem("language", language);
  });
};

export const initLanguage = () => (dispatch) => {
  return i18next.use(LanguageDetector).init(
    {
      detection: options,
      resources,
      ns: ["common"],
      defaultNS: "common",
      fallbackLng: "en",
      supportedLngs: ["pt", "en", "es"],
      interpolation: {
        escapeValue: false,
      },
      debug: false,
    },
    (err, t) => {
      if (err) return console.log("something went wrong initializing", err);
      const language = localStorage.getItem("language");
      if (language) {
        if (language !== t("language")) {
          i18next.changeLanguage(language, (err, t) => {
            if (err) return console.log("something went wrong changing", err);
            dispatch(setLanguage(language));
            localStorage.setItem("language", language);
          });
        } else {
          dispatch(setLanguage(language));
        }
      } else {
        localStorage.setItem("language", t("language"));
        dispatch(setLanguage(t("language")));
      }
    }
  );
};

export default mainSlice.reducer;
