import i18next from "i18next";
import moment from 'moment';

const formatValue = (value) => {
  if (value < 10) {
    return "0" + value;
  } else {
    return value;
  }
};

export const getUTCDate = (stringDateTimeParam) => {
  let formattedDate = "";
  try {
    const arrayStringDateTime = stringDateTimeParam.split(" ");
    const arrayStringDate = arrayStringDateTime[0].split("-");
    const arrayStringTime = arrayStringDateTime[1].split(":");

    //TODO: Gambi pra evitar mudança no backend
    const year = "20".concat(arrayStringDate[0]);
    const month = arrayStringDate[1] - 1;
    const day = arrayStringDate[2];

    const hour = arrayStringTime[0];
    const minute = arrayStringTime[1];
    const second = arrayStringTime[2];

    //retornando data para que seja formatada conforme idioma
    const newDate = new Date(
      new Date(year, month, day, hour, minute, second, 0) -
        new Date().getTimezoneOffset() * 60000
    );

    formattedDate = i18next.t("time.format", {
      day: formatValue(newDate.getDate()),
      month: formatValue(newDate.getMonth() + 1),
      year: formatValue(newDate.getFullYear().toString().substr(-2)),
      hour: formatValue(newDate.getHours()),
      minute: formatValue(newDate.getMinutes()),
      second: formatValue(newDate.getSeconds()),
    });
  } catch (_) {}

  return formattedDate;
};

export const getUTCTime = (createdAtDateParam, updatedAtDateParam) => {
  let formattedTime = "";
  try {
    const getDateTime = (stringDateTimeParam) => {
      const arrayStringDateTime = stringDateTimeParam.split(" ");
      const arrayStringDate = arrayStringDateTime[0].split("-");
      const arrayStringTime = arrayStringDateTime[1].split(":");

      //TODO: Gambi pra evitar mudança no backend
      const year = "20".concat(arrayStringDate[0]);
      const month = arrayStringDate[1] - 1;
      const day = arrayStringDate[2];

      const hour = arrayStringTime[0];
      const minute = arrayStringTime[1];
      const second = arrayStringTime[2];

      return new Date(
        new Date(year, month, day, hour, minute, second, 0) -
          new Date().getTimezoneOffset() * 60000
      );
    };

    const createdAtDate = getDateTime(createdAtDateParam);
    const updatedAtDate = updatedAtDateParam
      ? getDateTime(updatedAtDateParam)
      : new Date();

    const diffTime = Math.abs(updatedAtDate - createdAtDate);

    let daysT = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    let hoursT = Math.floor(diffTime / (1000 * 60 * 60));
    hoursT = Math.floor(hoursT - daysT * 24);
    let minutesT = Math.floor(diffTime / (1000 * 60));
    minutesT = Math.floor(minutesT - hoursT * 60);
    let secondsT = Math.floor(diffTime / 1000);
    secondsT = Math.floor(secondsT - minutesT * 60);

    if (daysT >= 1) {
      formattedTime = "" + daysT + "d " + hoursT + "h";
    } else if (hoursT >= 1) {
      formattedTime = "" + hoursT + "h " + minutesT + "m";
    } else if (minutesT >= 1) {
      formattedTime = "" + minutesT + "m " + secondsT + "s";
    } else {
      formattedTime = "" + secondsT + "s";
    }

  } catch (_) {}

  return formattedTime;
};

export const formatDateApi = (date) => {
  const formated = moment(date).format("YYYY-MM-DD HH:mm");
  return formated;
}

export const formatDateOperationsDayHourView = (date) => {
  const myDate = `20${date}`;
  const formated = moment(myDate).format("DD/MM/YY HH:mm");
  return formated;
}

export const formatDateOperationsDayView = (date) => {
  const myDate = `20${date}`;
  const formated = moment(myDate).format("DD/MM/YY");
  return formated;
}

export const formatStartDate = (startDate) => {
  const date = moment(startDate).format("YYYY-MM-DD");
  return `${date} 00:00`;
}

export const formatEndDate = (endDate) => {
  const date = moment(endDate).format("YYYY-MM-DD");
  return `${date} 23:59`;
}