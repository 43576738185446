import React, { useLayoutEffect, useState } from "react";
import "../main.css";

import { Layout, Menu } from "antd";
import { 
  EyeOutlined,
  DollarCircleOutlined 
} from "@ant-design/icons";

export const MainSidebar = (props) => {
  
  const { Sider } = Layout;  

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState([0, 0]);

    useLayoutEffect(() => {
      const _updateSize = () => 
        setWindowSize([window.innerWidth, window.innerHeight]);

      return () => window.removeEventListener('resize', _updateSize);
    }, []);
    return windowSize;
  };

  const [width] = useWindowSize();

  const isMobile = width <= 900 ? true : false;

  const isCollapsed = 
    isMobile || props.currentMenu === "operations" ? true : false;

  return (
    <Sider       
      collapsed={isCollapsed}
      className="mainSidebar">
      <Menu
        mode="inline"
        onClick={(e) => props.changeMenu(e.key)}
        defaultSelectedKeys={props.currentMenu}
        defaultOpenKeys={["consults"]}
        className="mainMenu"
        style={{ height: "100%", borderRight: 0 }}
      >

        <Menu.Item 
          icon={<EyeOutlined />} key="overview"
          title="Visão Geral" >
            Visão Geral
        </Menu.Item>

        <Menu.Item 
          icon={<DollarCircleOutlined />} key="results"
          title="Consultas" >
            Consultas
        </Menu.Item>        
      </Menu>
    </Sider>
  )
}
