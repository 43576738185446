import { convertValuesCoins } from '../../../../../shared/utils/convertValuesCoins';
import TableViewBotProfitsColumns from '../TableViewBotProfitsColumns';
import PartialReinvestmentDOM from '../PartialReinvestmentDOM';
import HeaderDetails from '../HeaderDetails';
import HeaderOperations from '../HeaderOperations';
import { Table, Empty, Spin, Card } from "antd";
import i18next from "i18next";
import "../../robotViewer.css";
import CollapseReinvest from '../CollapseReinvest';

const TProfitPG = ({props, state, options, profitOptions}) => { 

  if (!props.childVisible) {
    if (props.reinvestingDetailsVisible) {
      return <PartialReinvestmentDOM props={props} state={state}/>;
    } else {
      return (
        <Spin
          spinning={state.loadingDrawer}
        >
          <div> 
            <HeaderDetails props={props} 
                          createCard={props.profitPgDetails && props.botDetails ? true : false}
                          propsRobot={props.profitPgDetails} 
                          isProfit={true}
                          options={options}
                          profitOptions={profitOptions}
            />               
            <CollapseReinvest {...props}/>
            <Card
              size="small"                  
              bordered={false}
              style={{ background: '#141414', borderRadius: '8px'}}
            >
              <Table className="opTable"
                rowKey="id"
                columns={TableViewBotProfitsColumns('columnsTableProfitPgDetails')}
                scroll={{ y: "0px", x: '200px' }}
                dataSource={props.profitPgDetails.botSetupList}
                locale={{
                  emptyText: (
                    <Empty
                      description={i18next.t("message.nothing")}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  ),
                }}
                size="small"
                summary={(pageData) => {
                  let totalEarning = 0;
                  pageData.forEach(({ operations }) => {
                    if (operations) {
                      totalEarning =
                        parseFloat(totalEarning) +
                        parseFloat(operations.earning);
                    }
                  });
                  totalEarning = totalEarning.toFixed(4);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
                        <Table.Summary.Cell>Total:</Table.Summary.Cell>
                        <Table.Summary.Cell colSpan={3}>
                          {convertValuesCoins(totalEarning)}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Card>
          </div>
        </Spin>
      );
    }
  } else {
    return (
      <div>
        <div className="space-setup-page-header-operation"></div>
        <HeaderOperations 
                createCard={props.profitPgSetupDetails &&
                  props.profitPgSetupDetails.robotOperations &&
                  props.profitPgSetupDetails.robotOperations.operations &&
                  props.profitPgSetupDetails.robotOperations.operations.length > 0
                  ? props.profitPgSetupDetails.robotOperations.operations
                  : false}
                propsRobot={props.profitPgSetupDetails}
                isProfit={true}
        />
        <div className="space-setup-page-header-operation"></div>

          <Card
            size="small"
            bordered={false}
            style={{ background: '#141414', borderRadius: '8px'}}
          >
            <Table className="opTable"
            rowKey="id"
            columns={TableViewBotProfitsColumns('columnsTableProfitPgSetupDetails')} 
            scroll={{ y: "none", x: '500px' }}         
            dataSource={
              props.profitPgSetupDetails &&
              props.profitPgSetupDetails.robotOperations &&
                props.profitPgSetupDetails.robotOperations.operations &&
                props.profitPgSetupDetails.robotOperations.operations.length > 0
                ? props.profitPgSetupDetails.robotOperations.operations
                : []
            }
            locale={{
              emptyText: (
                <Empty
                  description={i18next.t("message.nothing")}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              ),
            }}
            size="small"
            summary={(pageData) => {
              let totalEarning = 0;
              pageData.forEach(({ earning }) => {
                totalEarning = parseFloat(totalEarning) + parseFloat(earning);
              });
              totalEarning = totalEarning.toFixed(4);
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={13}></Table.Summary.Cell>
                    <Table.Summary.Cell>Total:</Table.Summary.Cell>
                    <Table.Summary.Cell>{convertValuesCoins(totalEarning)}</Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Card>
      </div>
    );
  }
}

export default TProfitPG;