export const validateNextStep = (params, formRef) => {  
  if (params.currentBot === 1) {
    if (params.currentStepRegister === 0) {
      if ((formRef.current.getFieldValue('investment') === '') || 
          (formRef.current.getFieldValue('investment') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('profit') === '') || 
          (formRef.current.getFieldValue('profit') === null)) {
        return true
      }      
    } else if (params.currentStepRegister === 1) {
      if ((formRef.current.getFieldValue('maxPrice') === '') || 
          (formRef.current.getFieldValue('maxPrice') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('buyPosition') === '') || 
          (formRef.current.getFieldValue('buyPosition') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('waitBuyLimit') === '') || 
          (formRef.current.getFieldValue('waitBuyLimit') === null)) {
        return true
      }    
    }
  } else if (params.currentBot === 2) {
    if (params.currentStepRegister === 0) {
      if ((formRef.current.getFieldValue('quantity') === '') || 
          (formRef.current.getFieldValue('quantity') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('drop') === '') || 
          (formRef.current.getFieldValue('drop') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('investment') === '') || 
         (formRef.current.getFieldValue('investment') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('profit') === '') || 
        (formRef.current.getFieldValue('profit') === null)) {
        return true
      }
    } else if (params.currentStepRegister === 1) {
      if ((formRef.current.getFieldValue('maxPrice') === '') || 
          (formRef.current.getFieldValue('maxPrice') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('waitBuyLimit') === '') || 
          (formRef.current.getFieldValue('waitBuyLimit') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('buyPosition') === '') || 
         (formRef.current.getFieldValue('buyPosition') === null)) {
        return true
      }
    }  
  } else if (params.currentBot === 3) {
    if (params.currentStepRegister === 0) {
      if ((formRef.current.getFieldValue('investment') === '') || 
          (formRef.current.getFieldValue('investment') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('minProfit') === '') || 
          (formRef.current.getFieldValue('minProfit') === null)) {
        return true
      } 
    } else if (params.currentStepRegister === 1) {
      if ((formRef.current.getFieldValue('maxPrice') === '') || 
          (formRef.current.getFieldValue('maxPrice') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('waitBuyLimit') === '') || 
          (formRef.current.getFieldValue('waitBuyLimit') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('buyPosition') === '') || 
          (formRef.current.getFieldValue('buyPosition') === null)) {
        return true
      }
    }  
  } else if (params.currentBot === 4 ) {
    if (params.currentStepRegister === 0) {
      if ((formRef.current.getFieldValue('quantity') === '') || 
          (formRef.current.getFieldValue('quantity') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('drop') === '') || 
          (formRef.current.getFieldValue('drop') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('investment') === '') || 
         (formRef.current.getFieldValue('investment') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('minProfit') === '') || 
        (formRef.current.getFieldValue('minProfit') === null)) {
        return true
      }
    } else if (params.currentStepRegister === 1) {
      if ((formRef.current.getFieldValue('maxPrice') === '') || 
          (formRef.current.getFieldValue('maxPrice') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('waitBuyLimit') === '') || 
          (formRef.current.getFieldValue('waitBuyLimit') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('buyPosition') === '') || 
          (formRef.current.getFieldValue('buyPosition') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('priceDrop') === '') || 
        (formRef.current.getFieldValue('priceDrop') === null)) {
        return true
      }
    }  
  } else if (params.currentBot === 7 ) {
    if (params.currentStepRegister === 0) {
      if ((formRef.current.getFieldValue('quantity') === '') || 
          (formRef.current.getFieldValue('quantity') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('drop') === '') || 
          (formRef.current.getFieldValue('drop') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('investment') === '') || 
         (formRef.current.getFieldValue('investment') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('minProfit') === '') || 
        (formRef.current.getFieldValue('minProfit') === null)) {
        return true
      }
    } else if (params.currentStepRegister === 1) {
      if ((formRef.current.getFieldValue('maxPrice') === '') || 
          (formRef.current.getFieldValue('maxPrice') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('waitBuyLimit') === '') || 
          (formRef.current.getFieldValue('waitBuyLimit') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('buyPosition') === '') || 
          (formRef.current.getFieldValue('buyPosition') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('buyPriceDrop') === '') || 
        (formRef.current.getFieldValue('buyPriceDrop') === null)) {
        return true
      } else if ((formRef.current.getFieldValue('priceDrop') === '') || 
        (formRef.current.getFieldValue('priceDrop') === null)) {
        return true
      }
    }
  }
  return false;
}