import i18next from "i18next";
import {
  Button,
  Radio,
  Typography,
  Divider
} from "antd";

const { Paragraph } = Typography;
const RobotStopDetail = ({props, action, loading, onChangeOption, onFinish}) => { 
  
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  return (
    <div>
      <h2 style={{ fontSize: 15, padding: 0, margin: 0 }}>
        {i18next.t("trades.stop.options.label")}
      </h2>
      <div className="line"></div>
      <Radio.Group onChange={onChangeOption} value={action}>
        <Radio
          style={radioStyle}
          value={"STOP"}
          disabled={
            props.robot.status === "STOPPING" ||
            props.robot.status === "CANCELING"
          }
        >
          {i18next.t("trades.stop.options.finalize")}
        </Radio>
        <Radio
          style={radioStyle}
          value={"SELL_MARKET"}
          disabled={
            props.robot.status === "SELLING_MARKET" ||
            props.robot.status === "CANCELING" ||
            props.robot.status === "REINVESTING"
          }
        >
          {i18next.t("trades.stop.options.sell_market")}
        </Radio>
        <Radio
          style={radioStyle}
          value={"REINVEST"}
          disabled={
            props.robot.status === "SELLING_MARKET" ||
            props.robot.status === "CANCELING" ||
            props.robot.status === "REINVESTING"
          }
        >
          {i18next.t("trades.stop.options.reinvest")}
        </Radio>
        <Radio
          style={radioStyle}
          value={"CANCEL"}
          disabled={props.robot.status === "CANCELING"}
        >
          {i18next.t("trades.stop.options.cancel")}
        </Radio>
      </Radio.Group>

      <div className="line"></div>

      <Divider orientation="left">
        <h2 style={{ fontSize: 15, padding: 0, margin: 0 }}>
          {i18next.t("trades.stop.descriptions.obs")}
        </h2>
      </Divider>

      {action === "STOP" ? (
        <div>
          <Paragraph>
            {i18next.t("trades.stop.descriptions.stop_1")}
          </Paragraph>
          <Paragraph>
            <ul>
              <li>{i18next.t("trades.stop.descriptions.stop_2")}</li>
              <li>{i18next.t("trades.stop.descriptions.stop_3")}</li>
            </ul>
          </Paragraph>
          <Paragraph>
            {i18next.t("trades.stop.descriptions.stop_4")}
          </Paragraph>
        </div>
      ) : null}
      {action === "SELL_MARKET" ? (
        <div>
          <Paragraph>
            {i18next.t("trades.stop.descriptions.sell_market_1")}
          </Paragraph>
          <Paragraph>
            {i18next.t("trades.stop.descriptions.sell_market_2")}
          </Paragraph>
          <Paragraph>
            {i18next.t("trades.stop.descriptions.sell_market_3")}
          </Paragraph>
        </div>
      ) : null}
      {action === "REINVEST" ? (
        <div>
          <Paragraph>
            {i18next.t("trades.stop.descriptions.reinvest_1")}
          </Paragraph>
          <Paragraph>
            <ul>
              <li>{i18next.t("trades.stop.descriptions.reinvest_2")}</li>
              <li>{i18next.t("trades.stop.descriptions.reinvest_3")}</li>
            </ul>
          </Paragraph>
          <Paragraph>
            {i18next.t("trades.stop.descriptions.reinvest_4")}
          </Paragraph>
          <Paragraph>
            {i18next.t("trades.stop.descriptions.reinvest_5")}
          </Paragraph>
        </div>
      ) : null}
      {action === "CANCEL" ? (
        <div>
          <Paragraph>
            {i18next.t("trades.stop.descriptions.cancel")}
          </Paragraph>
        </div>
      ) : null}

      <div className="menu-bar">
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            style={{ marginRight: 9 }}
            disabled={loading ? true : false}
            onClick={props.closeDrawer}
          >
            {i18next.t("button.cancel")}
          </Button>
          <Button
            type="primary"
            danger
            loading={loading ? true : false}
            onClick={onFinish}
          >
            {i18next.t("button.stop")}
          </Button>
        </div>
      </div>
    </div>
    )
  }

  export default RobotStopDetail;