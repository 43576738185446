import api from "./api";

export function getPackageDetails(exchange, id) {
  return new Promise((resolve) => {
    api.get("/exchange/getPackageDetails", { exchange, id }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function getPackageSetupDetails(exchange, id) {
  return new Promise((resolve) => {
    api
      .get("/exchange/getPackageSetupDetails", { exchange, id })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function getProfitSetupDetails(exchange, id) {
  return new Promise((resolve) => {
    api.get("/exchange/getProfitSetupDetails", { exchange, id }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function getProfitDetails(exchange, id) {
  return new Promise((resolve) => {
    api.get("/exchange/getProfitDetails", { exchange, id }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function getProfitPlusDetails(exchange, id) {
  return new Promise((resolve) => {
    api.get("/exchange/getProfitPlusDetails", { exchange, id }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function getProfitPlusSetupDetails(exchange, id) {
  return new Promise((resolve) => {
    api
      .get("/exchange/getProfitPlusSetupDetails", { exchange, id })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function getProfitPgDetails(exchange, id) {
  return new Promise((resolve) => {
    api.get("/exchange/getProfitPgDetails", { exchange, id }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function getProfitPgSetupDetails(exchange, id) {
  return new Promise((resolve) => {
    api
      .get("/exchange/getProfitPgSetupDetails", { exchange, id })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function getBot(exchange, id, botType, type) {
  return new Promise((resolve) => {
    api.get("/exchange/getBot", { exchange, id, botType, type }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function getBotDetails(exchange, id, type) {
  return new Promise((resolve) => {
    api.get("/exchange/getBot", { exchange, id, type }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function getOneDetails(exchange, id) {
  return new Promise((resolve) => {
    api.get("/exchange/getOneDetails", { exchange, id }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function getOneSetupDetails(exchange, id) {
  return new Promise((resolve) => {
    api
      .get("/exchange/getOneSetupDetails", { exchange, id })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function getBotPartialReinvest(exchange, id, setupId, botType, type = 6) {
  return new Promise((resolve) => {
    api.get("/exchange/getBot", { exchange, id, setupId, botType, type }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}
