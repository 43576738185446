import { createSlice } from "@reduxjs/toolkit";
import {
  stopBot,
  simulateReinvest,
  stopBotReinvest,
  simulatePartialReinvest,
  stopBotPartialReinvest,
} from "../../../../services/apiRobotStopService";

export const robotStopSlice = createSlice({
  name: "robotStop",
  initialState: {
    reinvestInfo: {},
    partialReinvestInfo: {},
    profit: 0,
    investment: 0,
  },
  reducers: {
    setReinvestInfo: (state, action) => {
      state.reinvestInfo = action.payload;
    },
    setPartialReinvestInfo: (state, action) => {
      state.partialReinvestInfo = action.payload;
    },
    setProfit: (state, action) => {
      state.profit = action.payload;
    },
    setInvestment: (state, action) => {
      state.investment = action.payload;
    },
  },
});

const {
  setReinvestInfo,
  setPartialReinvestInfo,
  setProfit,
  setInvestment,
} = robotStopSlice.actions;

export const stopBotAsync = (robotId, action, type) => () => {
  return stopBot(robotId, action, type);
};

export const setProfitAsync = (profit) => (dispatch) => {
  dispatch(setProfit(profit));
};

export const setInvestmentAsync = (investment) => (dispatch) => {
  dispatch(setInvestment(investment));
};

export const simulateReinvestAsync = (id, type, profit) => (dispatch) => {
  return simulateReinvest(id, type, profit).then((recordList) => {
    dispatch(setReinvestInfo(recordList));
  });
};

export const stopBotReinvestAsync = (id, action, type, profit) => () => {
  return stopBotReinvest(id, action, type, profit);
};

export const simulatePartialReinvestAsync = (
  id,
  type,
  setupId,
  investment,
  profit
) => (dispatch) => {
  return new Promise((resolve) => {
    simulatePartialReinvest(id, type, setupId, investment, profit).then((record) => {
      const message = record.message;
      const error = record.error;
      if (!!error) {
        resolve({ error, message });
      } else {
        dispatch(setPartialReinvestInfo(record));
        resolve();
      }
    });
  });
};

export const stopBotPartialReinvestAsync = (
  id,
  action,
  type,
  setupId,
  investment,
  profit
) => () => {
  return new Promise((resolve) => {
    stopBotPartialReinvest(id, action, type, setupId, investment, profit).then((record) => {
      const message = record.message;
      const error = record.error;
      if (!!error) {
        resolve({ error, message });
      } else {
        resolve();
      }
    });
  });
};

export default robotStopSlice.reducer;
