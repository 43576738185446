import { createSlice } from "@reduxjs/toolkit";
import { setPlanUser, getPlans, getPlansUser } from "../../services/apiPlansService";

export const plansSlice = createSlice({
  name: "plans",
  initialState: {
    plansList: [],
    plansUser: 0
  },
  reducers: {
    addPlans: (state, action) => {
      state.plansList = action.payload;
    },
    addPlansUser: (state, action) => {
      state.plansUser = action.payload;
    },
  },
});

const {
  addPlans,
  addPlansUser
} = plansSlice.actions;

export const setPlanUserAsync = (planId) => () => {
  return setPlanUser(planId);
};

export const findPlansAsync = () => async (dispatch) => {
  return getPlans().then((record) => {
    dispatch(addPlans(record));
  });
};

export const getPlansUserAsync = () => async (dispatch) => {
  return getPlansUser().then((record) => {
    dispatch(addPlansUser(record));
  });
};

export default plansSlice.reducer;