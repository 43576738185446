import api from "./api";

export function getNotes(language) {
  return new Promise((resolve) => {
    api.get(`/patch-notes/${language}`).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}
