import React, {useLayoutEffect, useState} from "react";
import "../exchanges.css";
import i18next from "i18next";
import {Table,Empty } from "antd";

import binance from "../../../assets/exchanges/binance.png";
import mercado from "../../../assets/exchanges/mercadobitcoin.jpeg";
import novadax from "../../../assets/exchanges/novadax.jpeg";
import coin from "../../../assets/icons/coin.png";

const CoinsTable = ({props, getExchangeId}) => {

  const listLogoExchanges = {
    binance,
    mercado,
    novadax,
  }; 

  const [windowSize, setWindowSize] = useState([0, 0]); 

  useLayoutEffect(() => {
    setWindowSize([window.innerWidth, window.innerHeight]);

      // return () => window.removeEventListener('resize', _updateSize);
  }, []);

  const columnsTableWallet = [
    {
      title: `${i18next.t("exchange.details.table.asset")}`,
      dataIndex: "asset",
      key: "asset",
      align: "left",
      render: (_, record) => {
        return (
          <div>
            <img
              className="image-symbol"
              src={`https://cryptoicons.org/api/icon/${record.asset.toLowerCase()}/200`}
              alt="Symbol"
              onError={(e) => {e.target.src = coin}}/>
             {record.asset} 
          </div>
        );
      },
    },
    {
      title: `${i18next.t("exchange.details.table.available")}`,
      dataIndex: "available",
      key: "available",
      align: "right",
    },
    {
      title: `${i18next.t("exchange.details.table.hold")}`,
      dataIndex: "hold",
      key: "hold",
      align: "right",
    },
    {
      title: `${i18next.t("exchange.details.table.total")}`,
      dataIndex: "total",
      key: "total",
      align: "right",
    },
  ];  

  const columnsTableWalletMobile = [
    {
      title: `${i18next.t("exchange.details.table.asset")}`,
      dataIndex: "asset",
      key: "asset",
      align: "left",
      render: (_, record) => {
        return (
          <div>
            <img
              className="image-symbol"
              src={`https://cryptoicons.org/api/icon/${record.asset.toLowerCase()}/200`}
              alt="Symbol"
              onError={(e) => {e.target.src = coin}}/>
             {record.asset} 
          </div>
        );
      },
    },
    {
      title: `${i18next.t("exchange.details.table.total")}`,
      dataIndex: "total",
      key: "total",
      align: "right",
    },
  ];  
  
  const isMobileColumns = windowSize[0] <= 800 ? columnsTableWalletMobile : columnsTableWallet;    

  return( 
    <> 
     {      
      props.listAccount[0] !== 0 && props.listAccount[0] !== 1 ? 
      <div style={{ display: 'flex', alignItems: 'center', gap: 5, marginBottom: 10}}>
        <img src={listLogoExchanges[getExchangeId(props.listAccount[0]?.name)]} alt="Symbol" />
        <h2>{props.listAccount[0]?.name}</h2>
      </div> 
      : <></>
     }
      <div>
        <Table
          className="opTable-coins"
          rowKey="asset"
          columns={isMobileColumns}
          dataSource={props.balance.balances}
          scroll={{ x: false, y: '700px'}}
          rowClassName={() => "rowClassExchange"}
          locale={{
            emptyText: (
              <Empty
                description={i18next.t("message.nothing")}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ),
          }}
          size="small"
          pagination={false}
        />
      </div>
    </>
  )
}

export default CoinsTable;