import React, { useState } from "react";
import "../settings.css";
import i18next from "i18next";

import {
  Button,
  Select,
  Form,
  Row,
  Col,
  Checkbox,
  Modal
} from "antd";

import { 
  SettingFilled
} from '@ant-design/icons';

const Preferences = ({props, state, formPreferencesRef, onFinishPreferences, onUndo, setProfile}) => {

  const layout = {
    labelCol: {
      span: "100%",
      offset: 0,
    },
  };

  const { loading } = state;
  const { Option } = Select;

  const [visibleModal, setVisibleModal] = useState(false);

  const handleSetVisibleModal = () => {
    setVisibleModal(true)
    setProfile()
  }

  return (
    <>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',          
      }}>
        <div style={{
            display: 'flex', 
            alignItems: 'center'}}
        >
          <SettingFilled className="icone-preference"/> 
          <span>{i18next.t("settings.preferences.description")}</span>
        </div>
        <Button onClick={() => handleSetVisibleModal()}>
         {i18next.t("settings.change")} 
        </Button>
      </div>

      <Modal
        title={i18next.t("settings.tab_preferences")}
        width={400}
        visible={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
      >
        <Form
          {...layout}
          ref={formPreferencesRef}
          name="control-preferences-ref"
          onFinish={onFinishPreferences}
        >
          <Row>
            <Col style={{ height: 200}} span={24}>
              <div>
                <Form.Item
                  name="defaultCoin"
                  label={i18next.t("settings.preferences.default_coin")}
                  style={{ width: '100%'}}
                  className="preference-format"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {props.coinList.map((item) => (
                      <Option key={item.name} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="diaryBalanceMail" valuePropName="checked">
                  <Checkbox>
                    {i18next.t("settings.preferences.diary_balance_mail")}
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name="diaryOperationMail"
                  valuePropName="checked"
                >
                  <Checkbox>
                    {i18next.t("settings.preferences.diary_operation_mail")}
                  </Checkbox>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row >
            <Col span={24}>
              <div>
                <div style={{textAlign: "right"}}>
                  <Button
                    style={{ marginRight: 9 }}
                    disabled={loading ? true : false}
                    onClick={onUndo}
                  >
                    {i18next.t("button.undo")}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading ? true : false}   
                    onClick={() => setVisibleModal(false)}                 
                  >
                    {i18next.t("button.save")}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>   
    </>
  )
}

export default Preferences;
