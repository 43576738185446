import React, {useState, useEffect} from "react";
import "../exchanges.css";
import i18next from "i18next";

import {
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Divider,
  message
} from "antd";

const layout = {
  labelCol: {
    span: 8,
    offset: 0,
  },
};

const { Option } = Select;

const NewExchange = ({props, state, closeRegisterDrawer, formRef}) => {

  const [loading, setLoading] = useState(false)
  const [windowSize, setWindowSize] = useState([0, 0]);

  const { editingId, exchangeName } = state;
  const editing = editingId !== 0 ? true : false;

  useEffect(() => {
    _useWindowSize();
    window.addEventListener('resize', _useWindowSize());
  }, [props])

  const _useWindowSize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])     
  }

  const onFinish = (valueParam) => {
    setLoading(true);

    const exchange = valueParam.exchange;
    if (state.editingId !== 0) {
      const account = {
        id: state.editingId,
        apiKey: valueParam.apiKey,
        secretKey: valueParam.secretKey,
      };
      props.editAccountAsync(exchange, account).then(() => {
        setLoading(false);
        closeRegisterDrawer(); 
      });
    } else {
      const account = {
        apiKey: valueParam.apiKey,
        secretKey: valueParam.secretKey,
      };
      /*
      let onOk = () => {
        this.props.createAccountAsync(exchange, account).then((response) => {
          this.closeRegisterDrawer();
          this.setState({ loading: false }, () => {
            if (response && response.error) {
              message.error(response.message.toString());
            }
            this.setState(
              {
                acceptedTerms: false,
              },
              () => {
                modalTerms = null;
              }
            );
          });
        });
      };

      let onCancel = () => {
        this.closeRegisterDrawer();
        this.setState({ loading: false }, () => {
          this.setState(
            {
              acceptedTerms: false,
            },
            () => {
              modalTerms = null;
            }
          );
        });
      };

      onOk = onOk.bind(this);
      onCancel = onCancel.bind(this);

      modalTerms = confirm({
        width: 600,
        title: <h2>{i18next.t("terms.title")}</h2>,
        icon: <></>,
        content: (
          <div style={{ overflowY: "scroll", height: 300 }}>
            <h3>{i18next.t("terms.paragraph1.title")}</h3>
            <p>{i18next.t("terms.paragraph1.content")}</p>
            <h3>{i18next.t("terms.paragraph2.title")}</h3>
            <p>{i18next.t("terms.paragraph2.content")}</p>
            <h3>{i18next.t("terms.paragraph3.title")}</h3>
            <p>{i18next.t("terms.paragraph3.content")}</p>
            <p>
              <Checkbox
                onChange={(e) => {
                  this.setState({
                    acceptedTerms: e.target.checked,
                  });
                }}
              >
                {i18next.t("terms.declare")}
              </Checkbox>
            </p>
          </div>
        ),
        okText: i18next.t("terms.agree"),
        cancelText: i18next.t("terms.disagree"),
        okButtonProps: { disabled: !this.state.acceptedTerms },
        onOk,
        onCancel,
      });
      */

      //TODO: apagar o bloco abaixo e descomentar o bloco acima já com a mensagem correta dos termos de uso quando a recebermos
      props.createAccountAsync(exchange, account).then((response) => {
        closeRegisterDrawer();
        setLoading(false);
        if (response && response.error) {
          message.error(response.message.toString());
        }
      });
    }
  };  

  return (
    <Drawer
      placement="left"
      onClose={closeRegisterDrawer}
      visible={state.visibleRegisterDrawer}
      bodyStyle={{ paddingBottom: 9 }}
      width={windowSize[0] > 900 ? '390px' : '100%'}                 
    >
      <h2 style={{ fontSize: 15, padding: 0, margin: 0 }}>
        {i18next.t("exchange.label.register")}
      </h2>
      <div className="spc-header-exchanges"></div>
      <Form
        {...layout}
          layout={'vertical'}
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}              
      >
        <Form.Item                
          name="exchange"                
          label={i18next.t("exchange.label.exchange")}
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={exchangeName || null}
          className="exchange-align"
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            disabled={editing}                  
          >
            {props.allExchange.map((item) => (
              <Option
                disabled={item.id !== "binance" ? true : false}
                key={item.id}
                value={item.id}
              >
                {item.name}
              </Option>
            ))}
          </Select>
          </Form.Item>
          <Form.Item
            name="apiKey"
            label={i18next.t("exchange.label.api_key")}
            rules={[
              {
                required: true,
              },
            ]}
            className="exchange-align"
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="secretKey"
            label={i18next.t("exchange.label.secret_key")}
            rules={[
              {
                required: true,
              },
            ]}
            className="exchange-align"
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>

          <div className="menu-bar">
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                style={{ marginRight: 9 }}
                onClick={closeRegisterDrawer}
                disabled={loading ? true : false}
              >
                {i18next.t("button.cancel")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading ? true : false}
              >
                {i18next.t("button.save")}
              </Button>
            </div>
          </div>
      </Form>                      

      <Divider orientation="left">{i18next.t("exchange.label.important")}</Divider>  

      <span style={{ color:"14px", fontWeight: "500"}}> 
        {i18next.t("exchange.warning.text_1")}
        <br/><br/>
        <ol>
          <li>{i18next.t("exchange.warning.text_2")} <u>52.15.73.109</u></li>
          <li>{i18next.t("exchange.warning.text_3")} <u>{i18next.t("exchange.label.unchecked")}</u></li>
        </ol>
                
        {i18next.t("exchange.warning.text_4")}
      </span>        
    </Drawer>
  )
}

export default NewExchange;