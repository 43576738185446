import { useEffect } from 'react'
import { Card, Table } from 'antd'
import i18next from "i18next";

import {
  FieldInvestment,
  FieldQuantity,
  FieldMinProfit,
  FieldPriceDrop,
  FieldBuyPriceDrop,
  FieldDrop,  
  FieldMaxPrice,
  FieldWaitBuyLimit,
  FieldProfit,
  FieldSymbol,
  FieldTypeBot
  // FieldInvestmentTotal
} from "./robotCreateFields";

export const FieldsDefault = (params) => {  
  return (
    <>
      <div className="header-steps" style={{ display: 'flex'}}>
        <FieldTypeBot props={params.props} onChangeTypeBot={params.onChangeTypeBot}/>
        <FieldSymbol props={params.props} />        
      </div>
    </>
  )
}

export const BotOne = (params) => {

  const props = params.props;

  useEffect(() => {    
  }, [props])

  const steps = () => {
    if (props.currentStepRegister === 0) {
      return (
        <Card
          title={i18next.t("main.settings")}
          size="small"                
          bordered={false}
          style={{ background: '#0E0E0E', borderRadius: '8px'}}
        >      
          <div style={{ display: 'flex'}}>
            <FieldInvestment props={props} /> 
            <FieldProfit props={props}/>
          </div> 
        </Card>
      )
    } else if (props.currentStepRegister === 1) {
      return (
        <Card
          title={i18next.t("trades.view.columns_table_setup_details.label_compra")}
          size="small"                
          bordered={false}
          style={{ background: '#0E0E0E', borderRadius: '8px'}}
        >        
          <div style={{ display: 'flex'}}>
            <FieldMaxPrice  props={props} />            
            <FieldWaitBuyLimit props={props} />
          </div> 
        </Card>
      )
    } else {
      return (
        <div style={{ display: 'flex', width: 855}}>
          <Card
            title={i18next.t("main.settings")}
            size="small"                
            bordered={false}
            style={{ background: '#0E0E0E', borderRadius: '8px', width: 855}}
          >  
            <div style={{ display: 'flex'}}>
              <FieldInvestment props={props} /> 
              <FieldProfit props={props}/>
            </div>
          </Card>

          <Card
            title={i18next.t("trades.view.columns_table_setup_details.label_compra")}
            size="small"                
            bordered={false}
            style={{ background: '#0E0E0E', borderRadius: '8px', marginLeft: '10px', width: 855}}
          >          
            <div style={{ display: 'flex'}}>
              <FieldMaxPrice  props={props} />              
              <FieldWaitBuyLimit props={props} />
            </div>                   
          </Card>           
        </div>        
      )
    }
  }
  
  const GetStepsOne = steps;
  return (
    <div>
        <GetStepsOne />
    </div>
  )
}

export const BotPackages = (params) => {
  const props = params.props;

  const columnsTableProfitPackageSimulateResult = [
    { 
      title: `${i18next.t("trades.view.columns_table_profit_pg_simulate.grade")}`,
      key: "position",
      dataIndex: "position",
      width: "100px",
    }, 
    { 
      title: `${i18next.t("trades.view.columns_table_details.maxPrice")}`,
      key: "maxPrice",
      dataIndex: "maxPrice",
      align: "right",   
      width: "50px",   
    },      
    { 
      title: `${i18next.t("trades.view.columns_table_details.minPrice")}`,
      key: "minPrice",
      dataIndex: "minPrice",
      align: "right",      
    },  
    { 
      title: `
        ${i18next.t("trades.view.columns_table_profit_pg_simulate.investment")} 
        - ${params.props.coinSelected ? params.props.coinSelected.name.split(/\//)[1] : ''}
        `,
      key: "investment",
      dataIndex: "investment",
      align: "right",      
    }
  ];

  useEffect(() => {    
  }, [props])

  const steps = () => {
    if (props.currentStepRegister === 0) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', width: 855}}>
          <div style={{ display: 'flex', flexDirection: 'column'}}>
          <Card
              title={i18next.t("main.settings")}
              size="small"                
              bordered={false}              
              style={{ background: '#0E0E0E', borderRadius: '8px', marginBottom: 10}}
            >              
              <div style={{ display: 'flex'}}>
                <FieldInvestment props={params.props} />
                <FieldProfit props={params.props}/>          
              </div>
            </Card>            
            <Card
              title="Grade"
              size="small"                
              bordered={false}
              style={{ background: '#0E0E0E', borderRadius: '8px'}}
            >      
              <div style={{ display: 'flex'}}>
                <FieldQuantity props={params.props}/>      
                <FieldDrop props={params.props}/>
              </div> 
            </Card>
          </div>  
          <div style={{ display: 'flex', flexDirection: 'column'}}>
          <Card
              size="small"                
              bordered={false}
              style={{ background: '#0E0E0E', 
                      borderRadius: '8px', 
                      marginLeft: 10,
                      height: '17.8rem',
                      width: '31rem'}}
            >    
              <div className="grades-simulateResults">
                <h3 className="grades-simulateResultsTitle">
                  {i18next.t("trades.register.label.resultTitle")}
                </h3>
                <Table                     
                  className="opTableCreateBot"
                  size="small"
                  dataSource={params.props.packageSimulateResults} 
                  pagination={false}                  
                  columns={columnsTableProfitPackageSimulateResult} 
                  scroll={{ y: 190, x: 180 }}                  
                />
              </div>  
            </Card>
            
          </div>
        </div>      
      )
    } else if (props.currentStepRegister === 1) {
      return (
        <div style={{ height: 284, display: 'flex', flexDirection: 'column'}}>
          <Card
            title={i18next.t("trades.view.columns_table_setup_details.label_compra")}
            size="small"                
            bordered={false}
            style={{ background: '#0E0E0E', borderRadius: '8px', marginBottom: 10}}
          >      
          <div style={{ display: 'flex'}}>
            <FieldMaxPrice props={params.props} />      
            <FieldWaitBuyLimit props={params.props} />                        
          </div> 
        </Card>
      </div>  
      )
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
          <div style={{ display: 'flex', flexDirection: 'row', width: 855}}>
            <Card
              title="Grade"
              size="small"                
              bordered={false}
              style={{ width: '150rem', background: '#0E0E0E', borderRadius: '8px', marginBottom: 10}}
            >      
              <div style={{ display: 'flex'}}>
                <FieldQuantity props={params.props}/>      
                <FieldDrop props={params.props}/>
              </div> 
            </Card>
            <Card
              title={i18next.t("main.settings")}
              size="small"                
              bordered={false}
              style={{ width: '150rem', background: '#0E0E0E', borderRadius: '8px', marginLeft: 10, marginBottom: 10}}
            >              
              <div style={{ display: 'flex'}}>
                <FieldInvestment props={params.props} />
                <FieldProfit props={params.props}/>   
                {/* <FieldInvestmentTotal props={params.props}/>          */}
              </div>
            </Card>
          </div>  
          <div style={{ display: 'flex', flexDirection: 'row', width: 855}}>
            <Card
              title={i18next.t("trades.view.columns_table_setup_details.label_compra")}
              size="small"                
              bordered={false}
              style={{ background: '#0E0E0E', borderRadius: '8px', width: 855}}
            >      
              <div style={{ display: 'flex'}}>
                <FieldMaxPrice props={params.props} />      
                <FieldWaitBuyLimit props={params.props} />                                
              </div> 
            </Card>
          </div>  
        </div>      
      )
    }
  }

  const GetStepsPackage = steps;
  return (
    <div>
        <GetStepsPackage />
    </div>
  )
}

export const BotProfitPG = (params) => {
  const props = params.props;

  useEffect(() => {    
  }, [props])

  const columnsTableProfitPgSimulateResult = [
    { 
      title: `${i18next.t("trades.view.columns_table_profit_pg_simulate.grade")}`,
      key: "position",
      dataIndex: "position",
      align: "left",  
      width: "3rem",    
    }, 
    { 
      title: `${i18next.t("trades.view.columns_table_details.maxPrice")}`,
      key: "maxPrice",
      dataIndex: "maxPrice",
      align: "right",      
    }, 
    { 
      title: `${i18next.t("trades.view.columns_table_details.minPrice")}`,
      key: "minPrice",
      dataIndex: "minPrice",
      align: "right",      
    }, 
    { 
      title: `
        ${i18next.t("trades.view.columns_table_profit_pg_simulate.investment")} 
        - ${params.props.coinSelected ? params.props.coinSelected.name.split(/\//)[1] : ''}
        `,
      key: "investment",
      dataIndex: "investment",
      align: "right",
    }
  ];

  const steps = () => {
    if (props.currentStepRegister === 0) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', width: 855}}>
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <Card
              title={i18next.t("main.settings")}
              size="small"                
              bordered={false}
              style={{ background: '#0E0E0E', borderRadius: '8px', marginBottom: 10}}
            >              
              <div style={{ display: 'flex'}}>
                <FieldInvestment props={params.props} />
                <FieldMinProfit props={params.props}/>          
              </div>
            </Card>
            <Card
              title="Grade"
              size="small"                
              bordered={false}
              style={{ background: '#0E0E0E', borderRadius: '8px'}}
            >      
              <div style={{ display: 'flex'}}>
                <FieldQuantity props={params.props}/>      
                <FieldDrop props={params.props}/>
              </div> 
            </Card>
          </div>  
          <div style={{ display: 'flex', flexDirection: 'column'}}>
          <Card
            size="small"                
            bordered={false}
            style={{ background: '#0E0E0E', 
                    borderRadius: '8px', 
                    marginLeft: 10,
                    height: '17.8rem',
                    width: '31rem'}}
            >    
              <>
                <div className="grades-simulateResults">
                  <h3 className="grades-simulateResultsTitle">
                    {i18next.t("trades.register.label.resultTitle")}
                  </h3>
                  <Table                     
                    className="opTableCreateBot"
                    dataSource={params.props.pgSimulateResults} 
                    pagination={false}
                    columns={columnsTableProfitPgSimulateResult} 
                    scroll={{ y: 200, x: false }}
                  />
                </div>                
              </>
            </Card>
            
          </div>
        </div>      
      )
    } else if (props.currentStepRegister === 1) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', width: 855}}>
          <Card
            title={i18next.t("trades.view.columns_table_setup_details.label_compra")}
            size="small"                
            bordered={false}
            style={{ background: '#0E0E0E', borderRadius: '8px', marginBottom: 10}}
          >      
          <div style={{ display: 'flex'}}>
            <FieldMaxPrice props={params.props} />      
            <FieldWaitBuyLimit props={params.props} />                        
            <FieldBuyPriceDrop props={params.props} /> 
          </div> 
        </Card>
        <Card
          title="Venda"
          size="small"                
          bordered={false}
          style={{ background: '#0E0E0E', borderRadius: '8px'}}
        >              
          <div style={{ display: 'flex'}}>
          <FieldPriceDrop props={params.props}/>    
          </div>
        </Card>
      </div>  
      )
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', width: 855}}>
          <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Card
              title="Grade"
              size="small"                
              bordered={false}
              style={{ width: '30rem', background: '#0E0E0E', borderRadius: '8px', marginBottom: 10}}
            >      
              <div style={{ display: 'flex'}}>
                <FieldQuantity props={params.props}/>      
                <FieldDrop props={params.props}/>
              </div> 
            </Card>
            <Card
              title={i18next.t("main.settings")}
              size="small"                
              bordered={false}              
              style={{ width: '30rem', background: '#0E0E0E', borderRadius: '8px', marginLeft: 10, marginBottom: 10}}
            >              
              <div style={{ display: 'flex'}}>
                <FieldInvestment props={params.props} />
                <FieldMinProfit props={params.props}/>                
              </div>
            </Card>
          </div>  
          <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Card
              title={i18next.t("trades.view.columns_table_setup_details.label_compra")}
              size="small"                
              bordered={false}
              style={{ background: '#0E0E0E', borderRadius: '8px',width: '250%'}}
            >      
              <div style={{ display: 'flex'}}>
                <FieldMaxPrice props={params.props} />      
                <FieldWaitBuyLimit props={params.props} />
                <FieldBuyPriceDrop props={params.props} />                
              </div> 
            </Card>
            <Card
              title="Venda"
              size="small"                
              bordered={false}
              style={{ background: '#0E0E0E', borderRadius: '8px', marginLeft: 10, width: '100%'}}
            >              
              <div style={{ display: 'flex'}}>
                <FieldPriceDrop props={params.props}/>    
              </div>
            </Card>
          </div>  
        </div>      
      )
    }
  }

  const GetStepsPackage = steps;
  return (
    <div>
        <GetStepsPackage />
    </div>
  )
}

export const BotProfit = (params) => {
  const props = params.props;

  useEffect(() => {    
  }, [props])

  const steps = () => {
    if (props.currentStepRegister === 0) {
      return (
        <Card
          title={i18next.t("main.settings")}
          size="small"                
          bordered={false}
          style={{ background: '#0E0E0E', borderRadius: '8px'}}
        >      
          <div style={{ display: 'flex'}}>
            <FieldInvestment props={props} /> 
            <FieldMinProfit props={props}/>
          </div> 
        </Card>
      )
    } else if (props.currentStepRegister === 1) {
      return (
        <div style={{ display: 'flex', width: 855}}>
          <Card
            title={i18next.t("trades.view.columns_table_setup_details.label_compra")}
            size="small"                
            bordered={false}
            style={{ background: '#0E0E0E', borderRadius: '8px', width: '50%'}}
          >        
            <div style={{ display: 'flex'}}>
              <FieldMaxPrice  props={props} />
              <FieldWaitBuyLimit props={props} />              
            </div> 
          </Card>        
          <Card
            title={i18next.t("trades.view.columns_table_setup_details.label_venda")}
            size="small"                
            bordered={false}
            style={{ background: '#0E0E0E', borderRadius: '8px', marginLeft: '10px', width: '50%'}}
          >        
            <div style={{ display: 'flex'}}>
              <FieldPriceDrop props={props}/>              
            </div> 
          </Card>        
        </div>
      )
    } else {
      return (
        <div style={{ display: 'flex', width: 855}}>
          <Card
            title={i18next.t("main.settings")}
            size="small"                
            bordered={false}
            style={{ background: '#0E0E0E', borderRadius: '8px'}}
          >  
            <div style={{ display: 'flex'}}>
              <FieldInvestment props={props} /> 
              <FieldMinProfit props={props}/>
            </div>
          </Card>

          <Card
            title={i18next.t("trades.view.columns_table_setup_details.label_compra")}
            size="small"                
            bordered={false}
            style={{ background: '#0E0E0E', borderRadius: '8px', marginLeft: '10px'}}
          >          
            <div style={{ display: 'flex'}}>
              <FieldMaxPrice  props={props} />
              <FieldWaitBuyLimit props={props} />              
            </div>                   
          </Card>   
          <Card
            title={i18next.t("trades.view.columns_table_setup_details.label_venda")}
            size="small"                
            bordered={false}
            style={{ background: '#0E0E0E', borderRadius: '8px', marginLeft: '10px'}}
          >          
            <div style={{ display: 'flex'}}>
              <FieldPriceDrop props={props}/>          
            </div>                   
          </Card>           
        </div>        
      )
    }
  }
  
  const GetStepsPackage = steps;
  return (
    <div>
        <GetStepsPackage />
    </div>
  )
}

export const BotProfitPlus = (params) => {
  const props = params.props;

  const columnsTableProfitPlusSimulateResult = [
    { 
      title: `${i18next.t("trades.view.columns_table_profit_pg_simulate.grade")}`,
      key: "position",
      dataIndex: "position",
      width: "3rem",
    }, 
    { 
      title: `${i18next.t("trades.view.columns_table_details.maxPrice")}`,
      key: "maxPrice",
      dataIndex: "maxPrice",
      align: "right",      
    }, 
    { 
      title: `${i18next.t("trades.view.columns_table_details.minPrice")}`,
      key: "minPrice",
      dataIndex: "minPrice",
      align: "right",      
    }, 
    { 
      title: `
        ${i18next.t("trades.view.columns_table_profit_pg_simulate.investment")} 
        - ${params.props.coinSelected ? params.props.coinSelected.name.split(/\//)[1] : ''}
        `,
      key: "investment",
      dataIndex: "investment",
      align: "right",      
    }
  ];

  useEffect(() => {    
  }, [props])

  const steps = () => {
    if (props.currentStepRegister === 0) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', width: 855}}>
          <div style={{ display: 'flex', flexDirection: 'column'}}>
          <Card
              title={i18next.t("main.settings")}
              size="small"                
              bordered={false}
              style={{ background: '#0E0E0E', borderRadius: '8px', marginBottom: 10}}
            >              
              <div style={{ display: 'flex'}}>
                <FieldInvestment props={params.props} />
                <FieldMinProfit props={params.props}/>          
              </div>
            </Card>            
            <Card
              title="Grade"
              size="small"                
              bordered={false}
              style={{ background: '#0E0E0E', borderRadius: '8px'}}
            >      
              <div style={{ display: 'flex'}}>
                <FieldQuantity props={params.props}/>      
                <FieldDrop props={params.props}/>
              </div> 
            </Card>
          </div>  
          <div style={{ display: 'flex', flexDirection: 'column'}}>
          <Card
            size="small"                
            bordered={false}
            style={{ background: '#0E0E0E', 
                    borderRadius: '8px', 
                    marginLeft: 10,
                    height: '17.8rem',
                    width: '31rem'}}
            >    
              <div className="grades-simulateResults">
                <h3 className="grades-simulateResultsTitle">
                  {i18next.t("trades.register.label.resultTitle")}
                </h3>
                <Table                     
                  className="opTableCreateBot"
                  dataSource={params.props.plusSimulateResults} 
                  pagination={false}
                  columns={columnsTableProfitPlusSimulateResult} 
                  scroll={{ y: 200, x: false }}
                />
              </div>  
            </Card>
            
          </div>
        </div>      
      )
    } else if (props.currentStepRegister === 1) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', width: 855}}>
          <Card
            title={i18next.t("trades.view.columns_table_setup_details.label_compra")}
            size="small"                
            bordered={false}
            style={{ background: '#0E0E0E', borderRadius: '8px', marginBottom: 10}}
          >      
          <div style={{ display: 'flex'}}>
            <FieldMaxPrice props={params.props} />      
            <FieldWaitBuyLimit props={params.props} />
            <FieldBuyPriceDrop props={params.props} />            
          </div> 
        </Card>
        <Card
          title={i18next.t("trades.view.columns_table_setup_details.label_venda")}
          size="small"                
          bordered={false}
          style={{ background: '#0E0E0E', borderRadius: '8px'}}
        >              
          <div style={{ display: 'flex'}}>
            <FieldPriceDrop props={params.props}/> 
          </div>
        </Card>
      </div>  
      )
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', width: 855}}>
          <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Card
              title="Grade"
              size="small"                
              bordered={false}
              style={{ width: '30rem', background: '#0E0E0E', borderRadius: '8px', marginBottom: 10}}
            >      
              <div style={{ display: 'flex'}}>
                <FieldQuantity props={params.props}/>      
                <FieldDrop props={params.props}/>
              </div> 
            </Card>
            <Card
              title={i18next.t("main.settings")}
              size="small"                
              bordered={false}
              style={{ width: '30rem', background: '#0E0E0E', borderRadius: '8px', marginLeft: 10, marginBottom: 10}}
            >              
              <div style={{ display: 'flex'}}>
                <FieldInvestment props={params.props} />
                <FieldMinProfit props={params.props}/>   
                {/* <FieldInvestmentTotal props={params.props}/>          */}
              </div>
            </Card>
          </div>  
          <div style={{ display: 'flex', flexDirection: 'row'}}>
            <Card
              title={i18next.t("trades.view.columns_table_setup_details.label_compra")}
              size="small"                
              bordered={false}
              style={{ background: '#0E0E0E', borderRadius: '8px', width: '250%'}}
            >      
              <div style={{ display: 'flex'}}>
                <FieldMaxPrice props={params.props} />      
                <FieldWaitBuyLimit props={params.props} />
                <FieldBuyPriceDrop props={params.props} />                
              </div> 
            </Card>
            <Card
              title={i18next.t("trades.view.columns_table_setup_details.label_venda")}
              size="small"                
              bordered={false}
              style={{ background: '#0E0E0E', borderRadius: '8px', marginLeft: 10, width: '100%'}}
            >              
              <div style={{ display: 'flex'}}>
              <FieldPriceDrop props={params.props}/>       
              </div>
            </Card>
          </div>  
        </div>      
      )
    }
  }

  const GetStepsProfitPlus = steps;
  return (
    <div>
        <GetStepsProfitPlus />
    </div>
  )
}


