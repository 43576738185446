export const convertValuesCoins = (value, precision = 8) => {
  return parseFloat(value).toLocaleString("en-US", {
    maximumFractionDigits: precision === 8 ? 8 : precision - 1,
  });
};
export const convertMinPrecisionCoins = (value, precision = 8) => {
  return parseFloat(value).toLocaleString("en-US", {
    minimumFractionDigits: precision,
    maxFractionDigits: precision,
  });
};
