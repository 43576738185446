import api from "./api";

export function getAccount() {
  return new Promise((resolve) => {
    api.get("/exchange/getAccount").then((res) => {      
      if (res && (res.status === 200 || res.status === 304) && !!res.data) {
        resolve(res.data);
      } 
      resolve([]);
    })
    .catch(() => {
      resolve({statusCode: 404});
    });
  });
}

export function createAccount(exchange, account) {
  return new Promise((resolve) => {
    api
      .post(`/exchange/createAccount?exchange=${exchange}`, account)
      .then((res) => {
        if (res && res.status === 201 && !!res.data) {
          //TODO: não é bonito, mas é o que temos
          const message = res.data.message || res.data.error || "";
          if (!message || message === "") {
            resolve(res.data);
          } else {
            resolve({ error: true, message });
          }
        }
        resolve({
          error: true,
          message:
            res ||
            res.data ||
            res.data.message ||
            res.data.error ||
            "Internal Server Error",
        });
      })
      .catch((res) => {
        resolve({
          error: true,
          message:
            res ||
            res.data ||
            res.data.message ||
            res.data.error ||
            "Internal Server Error",
        });
      });
  });
}

export function editAccount(exchange, account) {
  return new Promise((resolve) => {
    api
      .post(`/exchange/editAccount?exchange=${exchange}`, account)
      .then((res) => {
        if (res && res.status === 201 && !!res.data) {
          //TODO: não é bonito, mas é o que temos
          const message = res.data.message || res.data.error || "";
          if (!message || message === "") {
            resolve(res.data);
          } else {
            resolve({ error: true, message });
          }
        }
        resolve([]);
      });
  });
}

export function deleteAccount(id) {
  //TODO: ajustar gambi assim que possivel
  return new Promise((resolve) => {
    api
      .delete("/exchange/deleteAccount", { exchange: "binance", id: id })
      .then((res) => {
        if (res && res.status === 201 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function getBalanceInfo(userId, exchange) {
  return new Promise((resolve) => {
    api.get("user/preferences/".concat(userId)).then((preferencesResponse) => {
      if (
        preferencesResponse &&
        preferencesResponse.status === 200 &&
        !!preferencesResponse.data
      ) {
        api
          .get(
            `/exchange/getBalanceInfo?exchange=${exchange}&coin=${preferencesResponse.data.defaultCoin}`
          )
          .then((res) => {
            if (res && res.status === 200 && !!res.data) {
              resolve(res.data);
            } else {
              resolve({});
            }
          });
      } else {
        resolve({});
      }
    });
  });
}
