import Default from "../../../assets/icons/icons8-bot-64-wh.png";
import Bug from "../../../assets/icons/icons8-inseto-64-wh.png";
import Tela from "../../../assets/icons/icons8-consultas-de-midia-64-wh.png";
import Melhoria from "../../../assets/icons/icons8-nao-testado-64-wh.png";
import Funcionalidade from "../../../assets/icons/icons8-codigo-64-wh.png";
import Modulo from "../../../assets/icons/icons8-modulo-64-wh.png";

const notesIcon = (type) => {
  switch (type) {
    case "Bug":
    case "Correção":
    case "Fix":
      return Bug;
    case "Melhoria":
    case "Improvement":
      return Melhoria;
    case "Funcionalidade":
    case "Functionality":
      return Funcionalidade;
    case "Tela":
    case "Screen":
      return Tela;
    case "Novo Sistema":
    case "New System":
      return Modulo;
    default:
      return Default;
  }
}

export default notesIcon;