import React, { useState } from "react";
import "../settings.css";
import i18next from "i18next";
import { message } from 'antd'

import {
  Button,
  Form,
  Input,
  Modal
} from "antd";

import {
  UnlockFilled
} from '@ant-design/icons';


const ChangePassword = ({ props, state, formAdvancedConfigRef, deleteAccount }) => {
  const layout = {
    labelCol: {
      span: "100%",
      offset: 0,
    },
  };

  const { loading } = state;

  const [visibleModal, setVisibleModal] = useState(false);

  const checkCloseModal = () => {
    let visible = false;
    if ((formAdvancedConfigRef.current.getFieldValue('password') === '') ||
      (formAdvancedConfigRef.current.getFieldValue('password') === null)) {
      visible = true;
    } else if ((formAdvancedConfigRef.current.getFieldValue('passwordCheck') === '') ||
      (formAdvancedConfigRef.current.getFieldValue('passwordCheck') === null)) {
      visible = true;
    } else if ((formAdvancedConfigRef.current.getFieldValue('oldPassword') === '') ||
      (formAdvancedConfigRef.current.getFieldValue('oldPassword') === null)) {
      visible = true;
    }

    if (visible) {
      setVisibleModal(true)
    } else {
      formAdvancedConfigRef.current.resetFields();
      setVisibleModal(false)
    }
  }

  const onFinishAdvancedConfig = (valueParam) => {
    checkCloseModal()
    if (valueParam.password !== valueParam.passwordCheck) {
      message.error(i18next.t("settings.advanced_config.same_password"));
      setVisibleModal(true)
    } else {
      delete valueParam.passwordCheck;

      const userPass = Object.assign(
        { email: state.user.email },
        valueParam
      );

      props.changePasswordAsync(userPass).then((response) => {
        if (response && response.error) {
          message.error(
            i18next.t("settings.advanced_config.change_pass_error")
          );
          setVisibleModal(true)
        } else {
          message.success(
            i18next.t("settings.advanced_config.change_pass_success")
          );
          setVisibleModal(false)
        }
      });
    }
  };

  return (
    <div>

      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <UnlockFilled className="icone-password" />
          <span>{i18next.t("settings.advanced_config.description")}</span>
        </div>
        <Button onClick={() => setVisibleModal(true)}>
          {i18next.t("settings.change")}
        </Button>
      </div>

      <Modal
        width={400}
        title={i18next.t("settings.tab_password")}
        visible={visibleModal}
        footer={null}
        onCancel={() => setVisibleModal(false)}
      >
        <Form
          {...layout}
          layout="vertical"
          ref={formAdvancedConfigRef}
          name="control-advanced-config-ref"
          onFinish={onFinishAdvancedConfig}
        >
          <div>
            <Form.Item
              name="password"
              className="password-format"
              label={i18next.t("settings.advanced_config.password")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="passwordCheck"
              className="password-format"
              label={i18next.t("settings.advanced_config.passwordCheck")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="oldPassword"
              className="password-format"
              label={i18next.t("settings.advanced_config.oldPassword")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password style={{ width: "100%" }} />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, alignItems: 'flex-end' }}>
              {/* <Form.Item>
                <div>
                  <Popconfirm
                    title={i18next.t(
                      "settings.advanced_config.delete_account_question"
                    )}
                    onConfirm={deleteAccount}
                    okText={i18next.t("button.yes")}
                    cancelText={i18next.t("button.cancel")}
                  >
                    <Button type="primary" style={{ padding: 5, background: '#bf0603', border: 0 }}>
                      {i18next.t("settings.advanced_config.delete_account")}
                    </Button>
                  </Popconfirm>
                </div>
              </Form.Item>               */}
              <Form.Item>
                <Button
                  style={{ marginTop: 10 }}
                  type="primary"
                  htmlType="submit"
                  loading={loading ? true : false}
                >
                  {i18next.t("button.change")}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ChangePassword;
