import { createSlice } from "@reduxjs/toolkit";

export const mainPortfolioSlice = createSlice({
  name: "mainPortfolio",
  initialState: {
    loading: true,
    currentMenu: "overview"
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCurrentMenu: (state, action) => {
      state.currentMenu = action.payload;
    }
  },
});

const { setCurrentMenu, setLoading } = mainPortfolioSlice.actions;

export const changeMenu = (key) => 
  (dispatch) => dispatch(setCurrentMenu(key));

export const changeLoading = (boo) => (dispatch) => {

  const timer = boo ? 0 : 3000; //workaround :(

  return setTimeout(() => {
    dispatch(setLoading(boo))
  }, timer);
};

export default mainPortfolioSlice.reducer;
