import React, { useEffect } from 'react';
import '../trades.css';

import TableBotsColumns from './TableBotsColumns';
import i18next from 'i18next';
import { Table } from 'antd';

const TableBotsOrders = (params) => {

  useEffect(() => {
    return;
  }, [params.props.bookBuy]);

  return (
    <div className="tab-container" style={{ maxHeight: 715, minHeight: 715}}>
      <h4 style={{ paddingTop: 9 }}>
        {i18next.t("trades.label.order_list")}
      </h4>
      <Table
      // style={{ height: '100%'}}
        key="tableSell"
        className="sell-trade"
        columns={TableBotsColumns('columnsBuySell', params.state)}
        showHeader={true}
        dataSource={params.props.bookSell}
        scroll={{y: "300px" }}
        size="small"
        rowClassName={() => "rowClass"}
        pagination={false}
      />
      <h5 style={{ padding: 0, marginTop: 6, marginBottom: 6 }}>
        {params.props.bookSell && params.props.bookSell.length > 0
          ? params.props.bookBuy[0].price
          : ""}
      </h5>
      <Table
      // style={{ height: '100%'}}
        key="tableBuy"
        className="buy-trade"
        columns={TableBotsColumns('columnsBuySell', params.state)}
        showHeader={false}
        dataSource={params.props.bookBuy}
        scroll={{y: "300px" }}
        size="small"
        rowClassName={() => "rowClass"}
        pagination={false}
      />
    </div>
  )
}

export default TableBotsOrders;