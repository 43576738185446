import i18next from "i18next";
import React from "react";
import "../robotViewer.css";
import { convertValuesCoins } from '../../../../shared/utils/convertValuesCoins';
import { Card } from "antd";
import { getUTCDate } from "../../../../shared/utils/dateUtility";

export const loadConvertCoin = (record) => {
  if (record) {
    const value = record.split(' ');
    return convertValuesCoins(value[0]) + ' ' + value[1];
  } else {
    return 0;
  }
}

const HeaderDetails = ({props, createCard, propsRobot, isProfit, options, profitOptions}) => {

  const getDataHeader1 = createCard ? (
    <div className="header-cab-1">
        <p className="data-details-operations">{getUTCDate(propsRobot.createdAt)}</p>
        <p className="data-details-operations">{props.robot.bot.name}</p>
        <p className="data-details-operations">{propsRobot.symbol}</p>
        <p className="data-details-operations">{loadConvertCoin(propsRobot.investment)}</p>
        <p className="data-details-operations">{props.robot.status}</p>
    </div>
    ) : (
      <></>
    )

  const getDataHeader2 = createCard ? (
    <div className="header-cab-1">
        <p className="data-details-operations">{propsRobot.maxPrice}</p>
        <p className="data-details-operations">{props.botDetails.buyPriceDrop ? 
                                                props.botDetails.buyPriceDrop : 
                                                props.botDetails.buyPosition}</p>        
        <p className="data-details-operations">{props.botDetails.waitBuyLimit}</p>
        <p className="data-details-operations">{propsRobot.quantity}</p>
        <p className="data-details-operations">{props.botDetails.drop}</p>          
    </div> 
  ) : (
    <></>
  )

  const getDataHeader3 = createCard ? (
    <div className="header-cab-1">
        <p className="data-details-operations">{isProfit ? propsRobot.minProfit : propsRobot.profit}</p>
        <p className="data-details-operations">{isProfit ? propsRobot.priceDrop : convertValuesCoins(props.robot.earning)}</p>
        <p className="data-details-operations">{isProfit ? convertValuesCoins(props.robot.earning) : props.robot.operations}</p>
        <p className="data-details-operations">{isProfit ? props.robot.operations : "⠀"}</p>
          <h5 className="title-label-details-operations">⠀</h5>
    </div>
  ) : (
    <></>
  )       

  const getMainHeaders = createCard ? (
    <div className="header-mobile">
      <div className="header-cab-1">
          <h5 className="title-label-details-operations">{i18next.t("trades.title.createdAt")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("operations.select.robot")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("operations.select.coin")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("trades.view.label.investment")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("trades.view.columns_table_setup_details.status")}</h5>
      </div>

      {getDataHeader1}

      <div className="header-cab-1">
          <h5 className="title-label-details-operations">{i18next.t("trades.title.buy_price_max")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("trades.title.buying_position")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("trades.title.purchase_expiration")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("trades.title.grade_quantity")}</h5>
          <h5 className="title-label-details-operations">{i18next.t("trades.title.interval_grid")}</h5>          
      </div>

      {getDataHeader2}

      <div className="header-cab-1">
          <h5 className="title-label-details-operations">{i18next.t(isProfit ? "trades.title.sale_min_profit" : "trades.title.sell_desired_profit")}</h5>
          <h5 className="title-label-details-operations">{i18next.t(isProfit ? "trades.title.sale_price_drop" : "trades.view.columns_table_details.operations_earning")}</h5>
          <h5 className="title-label-details-operations">{i18next.t(isProfit ? "trades.view.columns_table_details.operations_earning" : "operations.table_operations.operations_performed")}</h5>
          <h5 className="title-label-details-operations">{i18next.t(isProfit ? "trades.title.quantity_operations" : "⠀")}</h5>
          <h5 className="title-label-details-operations">⠀</h5>
      </div>

      {getDataHeader3}
    </div>
  ) : (
    <></>
  )

  return (
    <>  
      {props.robot.bot.type === 1 || props.robot.bot.type === 2 ? options : profitOptions}        
      <Card
        size="small"            
        bordered={false}
        className="card-header"
        style={{ background: '#141414', borderRadius: '8px', padding: "5px" }}
      >
        {getMainHeaders}      
      </Card>  
      <div className="space-setup-page-header-operation"></div>
    </> 
  );
}; 

export default HeaderDetails;