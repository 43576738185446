import React from "react";
import "./overview.css";
import { Card, Row, Col, Space, Tooltip, Button, Table, Modal, Form } from "antd";
import i18next from "i18next";
import { EyeOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
// import { getPlansUserAsync } from '../../plans/plansSlice';
import { setTActiveBotsAsync, setActiveOperationsAsync, findAccompanimentsAsync, findAccompanimentsDetailAsync } from './overviewSlice';
import ActivateBots from './components/ActivateBots';
import ActivateOperations from './components/ActivateOperations';

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
      // checkedList: [],
      // checkAll: false,
      // loading: false,
      // idPlan: null
    };
  }

  componentDidMount() {
    // this.props.getPlansUserAsync();
    this.props.setTActiveBotsAsync('binance');
    this.props.setActiveOperationsAsync('binance');
    this.props.findAccompanimentsAsync('binance');
  }

  formRef = React.createRef();

  openModal = (record) => {
    this.setState({ visible: true });
    this.props.findAccompanimentsDetailAsync('binance', record.id)
  }

  render() {

    const columns = [
      {
        title: `${i18next.t("trades.title.earning")}`,
        dataIndex: 'earning',
        key: 'earning',
        render: (record) => (
          <div style={{ fontSize: 16 }}>
            {record}
          </div>
        )
      },
      {
        title: `${i18next.t("general.participation")}`,
        dataIndex: 'participation',
        key: 'participation',
        render: (record) => (
          <div style={{ fontSize: 16 }}>
            {record}
          </div>
        )
      },
      {
        title: `${i18next.t("general.status")}`,
        dataIndex: 'status',
        key: 'status',
        render: (record) => (
          <div style={{ fontSize: 16 }}>
            {record}
          </div>
        )
      },
      {
        title: `${i18next.t("general.action")}`,
        key: 'action',
        width: '11%',
        render: (record) => (
          <Space size="middle">
            <Tooltip title={i18next.t("general.action")}>
              <Button
                onClick={() => { this.openModal(record) }}
                type="secondary"
                // shape="circle" 
                icon={<EyeOutlined />}
              />
            </Tooltip>
          </Space>
        ),
      },
    ];

    const columnsDetails = [
      {
        title: `${i18next.t("signature.modal.type")}`,
        dataIndex: 'botType',
        key: 'botType',
        render: (record) => (
          <div style={{ fontSize: 16 }}>
            {record}
          </div>
        )
      },
      {
        title: `${i18next.t("plans.columns.investiment")}`,
        dataIndex: 'investment',
        key: 'investment',
        render: (record) => (
          <div style={{ fontSize: 16 }}>
            {record}
          </div>
        )
      },
      {
        title: `${i18next.t("operations.tableResume.columns.profit")}`,
        dataIndex: 'earning',
        key: 'earning',
        render: (record) => (
          <div style={{ fontSize: 16 }}>
            {record}
          </div>
        )
      },
      {
        title: `${i18next.t("signature.modal.participation")}`,
        dataIndex: 'participationCharged',
        key: 'participationCharged',
        render: (record) => (
          <div style={{ fontSize: 16 }}>
            {record}
          </div>
        )
      },
    ];

    return (
      <>
        <Row gutter={[9, 12]}>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 24, order: 1 }}
            xl={{ span: 16, order: 1 }}
          >
            <Card
              size="small"
              bordered={false}
              style={{
                background: '#141414',
                borderRadius: 8,
                padding: 5,
                width: '100%',
                height: 700,
                minWidth: 690
              }}
            >

              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 25 }}>
                <h3> {i18next.t("plans.columns.info")}</h3>
                {/* <Button
                  // onClick={() => { this.openModal(record) }}
                  type="primary"
                  height={250}
                >
                  {i18next.t("plans.columns.confirm")}
                </Button> */}
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 500, color: '#0E83D8' }}>
                    {i18next.t("plans.modal.plan")}
                  </span>
                  {this.props.plansUser.plan ? this.props.plansUser.plan.name : '-'}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 500, color: '#0E83D8' }}>
                    {i18next.t("plans.columns.value")}
                  </span>
                  {this.props.plansUser.plan ? this.props.plansUser.plan.value : '-'}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 500, color: '#0E83D8' }}>
                    {i18next.t("plans.columns.value2")}
                  </span>
                  {this.props.plansUser.plan ? this.props.plansUser.plan.percentualValue : '-'}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 500, color: '#0E83D8' }}>
                    {i18next.t("plans.columns.validity")}
                  </span>
                  {this.props.plansUser.subscription ? this.props.plansUser.subscription.expiresAt : '-'}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 500, color: '#0E83D8' }}>
                    {i18next.t("operations.table_operations.status")}
                  </span>
                  {this.props.plansUser.subscription ? this.props.plansUser.subscription.status : '-'}
                </div>

              </div>

              <Table
                className="opTableOverview"
                dataSource={this.props.accompanimentsList}
                columns={columns}
                // style={{ height: 250}}
                // style={{ height: 725 }}
                rowKey={(record) => record.name}
                // scroll={{ x: "none", y: "300px" }}
                // scroll={{ x: true, y: "20vh" }}
                rowClassName={() => "rowClass"}
              // pagination={{ pageSize: 50 }}        
              />
            </Card>
          </Col>
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 24, order: 2 }}
            lg={{ span: 24, order: 2 }}
            xl={{ span: 8, order: 2 }}
          >
            <Card
              size="small"
              bordered={false}
              title={i18next.t("plans.columns.bots-active")}
              style={{
                background: '#141414',
                borderRadius: 8,
                padding: 5,
                width: '100%',
                height: '49%',
                marginBottom: 10
              }}
            >
              <ActivateBots props={this.props} />
            </Card>

            <Card
              size="small"
              bordered={false}
              title={i18next.t("plans.columns.active-operations")}
              style={{
                background: '#141414',
                borderRadius: 8,
                padding: 5,
                width: '100%',
                height: '49.5%',
                marginBottom: 10
              }}
            >
              <ActivateOperations props={this.props} />
            </Card>
          </Col>
        </Row>

        <Modal
          title={i18next.t("signature.modal.detail")}
          width={850}
          centered
          visible={this.state.visible}
          onOk={() => this.setState({ visible: false })}
          onCancel={() => this.setState({ visible: false })}
        >
          <Form
            // {...layout}
            ref={this.formRefDetails}
            name="control-ref"
            // onFinish={this.onFinish}
            size="small"
          >
            <Card
              title={i18next.t("signature.modal.transactions")}
              size="small"
              bordered={false}
              style={{ background: '#141414', borderRadius: '8px', width: '100%', marginRight: 10, marginBottom: 10 }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 500, color: '#0E83D8' }}>
                    {i18next.t("plans.modal.plan")}
                  </span>
                  {this.props.accompanimentsDetailList.clientId}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 500, color: '#0E83D8' }}>
                    {i18next.t("plans.columns.value")}
                  </span>
                  {this.props.accompanimentsDetailList.quoteAsset}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 500, color: '#0E83D8' }}>
                    {i18next.t("plans.modal.plan")}
                  </span>
                  {this.props.accompanimentsDetailList.quoteAsset}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 500, color: '#0E83D8' }}>
                    {i18next.t("plans.modal.plan")}
                  </span>
                  {this.props.accompanimentsDetailList.quoteAsset}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: 500, color: '#0E83D8' }}>
                    {i18next.t("plans.modal.plan")}
                  </span>
                  {this.props.accompanimentsDetailList.quoteAsset}
                </div>
              </div>
            </Card>
            <Card
              title={i18next.t("main.bot")}
              size="small"
              bordered={false}
              style={{ background: '#141414', borderRadius: '8px', width: '100%', marginBottom: 10 }}
            >
              <Table
                className="accompanimentsDetail"
                dataSource={this.props.accompanimentsDetailList.details}
                columns={columnsDetails}
                rowKey={(record) => record.name}
                scroll={{ x: "none", y: "400px" }}
                rowClassName={() => "rowClass"}
                pagination={{ pageSize: 50 }}
              />
            </Card>
          </Form>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  plansUser: state.plans.plansUser,
  activeBots: state.overview.activeBots,
  activeOperations: state.overview.activeOperations,
  accompanimentsList: state.overview.accompanimentsList,
  accompanimentsDetailList: state.overview.accompanimentsDetailList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // getPlansUserAsync: () => dispatch(getPlansUserAsync()),
    setTActiveBotsAsync: (exchangeName) => dispatch(setTActiveBotsAsync(exchangeName)),
    setActiveOperationsAsync: (exchangeName) => dispatch(setActiveOperationsAsync(exchangeName)),
    findAccompanimentsAsync: (exchangeName) => dispatch(findAccompanimentsAsync(exchangeName)),
    findAccompanimentsDetailAsync: (exchangeName, id) => dispatch(findAccompanimentsDetailAsync(exchangeName, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);