import React from "react";
import "./settings.css";
import { connect } from "react-redux";
import i18next from "i18next";

import {
  Modal,
  message,
  Col,
  Row,
  Card
} from "antd";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  getProfileAsync,
  saveProfileAsync,
  findAllAvatarPayload,
  getAvatarPayload,
  findAllCountryPayload,
  findAllCoinPayload,
  getPreferencesAsync,
  savePreferencesAsync,
  changePasswordAsync,
  deleteAccountAsync,
  getTwoFactorAsync,
  enableTwoFactorPayload,
  disableTwoFactorPayload,
  verifySixPassTwoFactorAsync,
  enableTwoFactorVerifyAsync,
  setToggleTwoFactorAsync,
  setToggleTwoFactorAuthAsync,
  visibleModalSecurityAsync
} from "./settingsSlice";

import Profile from './components/Profile';
import Preferences from './components/Preferences';
import ChangePassword from './components/ChangePassword';
import TwoFactor from './components/TwoFactor';

import {CardTicod} from './components/CardTicod';

import { getUser } from "../../services/apiAuthService";

const { confirm } = Modal;
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTabKey: "1",
      height: window.innerHeight,
      user: null,
      isModalAvatarVisible: false,
      loading: false,
      avatar: null,
      visibleModalProfile: false,
    };
    this.setProfile = this.setProfile.bind(this);
    this.setVisibilityModelAvatar = this.setVisibilityModelAvatar.bind(this);
    this.setAvatar = this.setAvatar.bind(this);
    this.onUndo = this.onUndo.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.handleVisibleModalProfile = this.handleVisibleModalProfile.bind(this);
    this.formProfileRef = React.createRef();
    this.formPreferencesRef = React.createRef();
    this.formPreferencesTwoFactorRef = React.createRef();
    this.formPreferencesTwoFactorAuthRef = React.createRef();
    this.formAdvancedConfigRef = React.createRef();
    this.setTwoFactorUser = this.setTwoFactorUser.bind(this);
  }

  componentDidMount() {
    this.setProfile();
  }

  setProfile() {
    this.setState({ user: getUser() }, () => {
      this.props.getProfileAsync(this.state.user.id).then(() => {
        this.props
          .getAvatarPayload(
            (this.props.profile && this.props.profile.avatarId) || 0
          )
          .then(() => {
            this.setState({ avatar: this.props.avatar });
          });

          if (this.formProfileRef.current) {
            this.formProfileRef.current.setFieldsValue({
              countryId: this.props.profile.countryId,
              nameCity: this.props.profile.nameCity,
              postBox: this.props.profile.postBox,
              adress: this.props.profile.adress,
              phone: this.props.profile.phone,
              avatarId: this.props.profile.avatarId,
            });
          }
        });      

        this.props.getPreferencesAsync(this.state.user.preferences.id).then(() => {
          if (this.formPreferencesRef.current) {
            this.formPreferencesRef.current.setFieldsValue({
              defaultCoin: this.props.preferences.defaultCoin,
              diaryBalanceMail: this.props.preferences.diaryBalanceMail,
              diaryOperationMail: this.props.preferences.diaryOperationMail,
            });
          }
        });

      this.props.findAllCountryPayload();
      this.props.findAllCoinPayload();
    });
  }

  setVisibilityModelAvatar(avatarVisibility = true) {
    this.props.findAllAvatarPayload().then(() => {
      this.setState({ isModalAvatarVisible: avatarVisibility });
    });
  }

  setAvatar(avatar) {
    this.setState({ avatar }, () => {
      this.setVisibilityModelAvatar(false);
    });
  }

  onFinishProfile = (valueParam) => {
    this.setState({ loading: true }, () => {
      const { user } = this.state;
      let profile = Object.assign({ userId: user.id }, valueParam);

      const propsAvatarId = (this.props.avatar && this.props.avatar.id) || 0;
      const stateAvatarId = (this.state.avatar && this.state.avatar.id) || 0;

      if (propsAvatarId !== stateAvatarId) {
        profile = Object.assign(profile, { avatarId: stateAvatarId });
      }

      if (this.props.profile && this.props.profile.id) {
        profile = Object.assign(profile, { id: this.props.profile.id });
      }

      this.props.saveProfileAsync(profile, this.state.avatar).then(() => {
        this.setState({ loading: false }, () => {
          message.success(i18next.t("settings.saved_success"));
        });
      });
    });
  };

  onFinishPreferences = (valueParam) => {
    const { user } = this.state;
    let preferences = Object.assign(
      { id: this.state.user.preferences.id, userId: user.id },
      valueParam
    );
    preferences.diaryBalanceMail = !!valueParam.diaryBalanceMail;
    preferences.diaryOperationMail = !!valueParam.diaryOperationMail;
    preferences.twoFactorLogin = this.props.preferences.twoFactorLogin;
    this.setState({ loading: true }, () => {
      this.props.savePreferencesAsync(preferences).then(() => {
        this.setState({ loading: false }, () => {
          message.success(i18next.t("settings.saved_success"));
        });
      });
    });
  };

  onFinishPreferencesTwoFactor = (valueParam) => {
    this.props.verifySixPassTwoFactorAsync({
      pass: valueParam.sixPass.toString(),
      token: this.props.twoFactor.token
    }).then(() => {
      if (this.props.verifySixPassTwoFactor.statusCode === 417) {
        message.error(i18next.t("settings.invalidated"));
      } else if (this.props.verifySixPassTwoFactor.statusCode === 202) {
        message.success(i18next.t("settings.validate"));
        this.setTwoFactorUser(this.props.toggleTwoFactor)
      }
    });
  };

  onFinishPreferencesTwoFactorAuth = (valueParam) => {
    this.props.enableTwoFactorVerifyAsync({
      token: valueParam.authCode
    }).then(() => {
      if (!this.props.enableTwoFactorVerify.validated) {
        message.error(i18next.t("settings.invalidated"));
      } else {
        message.success(i18next.t("settings.validate"));
        this.setTwoFactorAuthUser(this.props.toggleTwoFactorAuth)
      }
    });
  };

  onFinishPreferencesRemoveTwoFactorAuth = () => {
    this.props.disableTwoFactorPayload().then(() => {
      message.error('2FA removed');
      this.props.setToggleTwoFactorAuthAsync(false)
      this.setTwoFactorAuthUser(this.props.toggleTwoFactorAuth)
    }).catch((error) => {
      console.log(error);
      message.error('Error to remove 2FA');
    });
  }

  setTwoFactorAuthUser = (checked) => {
    this.setState({ loading: false }, () => {
      const userStorage = JSON.parse(localStorage.getItem("user"));

      if (userStorage) {
        userStorage.preferences.twoFactorAuth = checked;
        localStorage.setItem("user", JSON.stringify(userStorage));
      }

      this.setProfile();
      this.props.visibleModalSecurityAsync(false);
    });
  }

  setTwoFactorUser = (checked) => {
    const twoFactorLogin = {twoFactorLogin: checked}
    
    let preferences = Object.assign(
      { id: this.state.user.preferences.id, token: null },
      twoFactorLogin,      
    );

    this.props.savePreferencesAsync(preferences).then(() => {
      this.setState({ loading: false }, () => {
        const userStorage = JSON.parse(localStorage.getItem("user"));

        if (userStorage) {
          userStorage.preferences.twoFactorLogin = checked;
          localStorage.setItem("user", JSON.stringify(userStorage));
        }

        this.setProfile();
        this.props.visibleModalSecurityAsync(false);
        // this.formPreferencesTwoFactorRef.current.resetFields();
      });
    });
  }

  onUndo = () => {
    confirm({
      title: i18next.t("message.undo"),
      okText: i18next.t("button.yes"),
      cancelText: i18next.t("button.no"),
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        this.setProfile();
      },
    });
  };

  deleteAccount = () => {
    this.props.deleteAccountAsync(this.state.user.email).then(() => {
      if (this.props.accountDeleted) {
        message
          .success(
            i18next.t("settings.advanced_config.delete_account_success"),
            1.5
          )
          .then(() => {
            window.location = "/logout";
          });
      } else {
        message.error(
          i18next.t("settings.advanced_config.delete_account_error")
        );
      }
    });
  };

  handleVisibleModalProfile = (visible) => {
    this.setState({ visibleModalProfile: visible }, () => {
      this.setProfile();
    });
  }

  render() {
    return (
      <div className="settings-container"
      style={{
        maxWidth: 1680,
        margin: "0 auto",
        width: '100%',        
      }}> 
        <Row gutter={[9, 9]}> 
          <Col            
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 12, order: 1 }}
            xl={{ span: 12, order: 1 }}
          >     
            <Card
              title={i18next.t("settings.tab_profile")}
              size="small"                
              bordered={false}      
              style={{ 
                background: '#141414', 
                borderRadius: 8,           
                padding: 5,          
                width: '100%',
                height: '100%'
              }}
            >   
              <div style={{ height: 60, maxHeight: 90}} />
                <Profile 
                  props={this.props} 
                  state={this.state} 
                  formProfileRef={this.formProfileRef}
                  setVisibilityModelAvatar={this.setVisibilityModelAvatar}
                  onFinishProfile={this.onFinishProfile}
                  onUndo={this.onUndo}
                  setProfile={() => this.setProfile()}
                  setAvatar={this.setAvatar}/>            
              <div style={{ height: 60,  maxHeight: 90}} />              
            </Card>
          </Col>
          <Col            
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 24, order: 2 }}
            lg={{ span: 12, order: 2 }}
            xl={{ span: 12, order: 2 }}
          >  
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
              <CardTicod title={i18next.t("settings.tab_password")}>
                <ChangePassword 
                  props={this.props} 
                  state={this.state}                
                  formAdvancedConfigRef={this.formAdvancedConfigRef}                
                  deleteAccount={this.deleteAccount} />
              </CardTicod>          

              <CardTicod title={i18next.t("settings.tab_preferences")} marginTop={10}>
                <Preferences 
                  props={this.props} 
                  state={this.state}  
                  formPreferencesRef={this.formPreferencesRef}                                              
                  onFinishPreferences={this.onFinishPreferences}
                  onUndo={this.onUndo}
                  setProfile={() => this.setProfile()}/>
              </CardTicod>
              <CardTicod title={i18next.t("settings.tab_security")} marginTop={10}>
                <TwoFactor 
                  props={this.props} 
                  state={this.state}  
                  formPreferencesTwoFactorAuthRef={this.formPreferencesTwoFactorAuthRef}                                              
                  onFinishPreferencesTwoFactorAuth={this.onFinishPreferencesTwoFactorAuth}
                  onFinishPreferencesRemoveTwoFactorAuth={this.onFinishPreferencesRemoveTwoFactorAuth}
                />
              </CardTicod>            
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.settings.profile,
  avatar: state.settings.avatar,
  avatarList: state.settings.avatarList,
  countryList: state.settings.countryList,
  coinList: state.settings.coinList,
  preferences: state.settings.preferences,
  accountDeleted: state.settings.accountDeleted,
  verifySixPassTwoFactor: state.settings.verifySixPassTwoFactor,
  enableTwoFactorVerify: state.settings.enableTwoFactorVerify,
  toggleTwoFactor: state.settings.toggleTwoFactor,
  toggleTwoFactorAuth: state.settings.toggleTwoFactorAuth,
  twoFactor: state.settings.twoFactor,
  visibleModalSecurity: state.settings.visibleModalSecurity,
  enableTwoFactor: state.settings.enableTwoFactor,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileAsync: (userId) => dispatch(getProfileAsync(userId)),
    saveProfileAsync: (profile, avatar) =>
      dispatch(saveProfileAsync(profile, avatar)),
    findAllAvatarPayload: () => dispatch(findAllAvatarPayload()),
    getAvatarPayload: (id) => dispatch(getAvatarPayload(id)),
    findAllCountryPayload: () => dispatch(findAllCountryPayload()),
    findAllCoinPayload: () => dispatch(findAllCoinPayload()),
    getPreferencesAsync: (userId) => dispatch(getPreferencesAsync(userId)),
    savePreferencesAsync: (preferences) =>
      dispatch(savePreferencesAsync(preferences)),
    changePasswordAsync: (userPass) => dispatch(changePasswordAsync(userPass)),
    deleteAccountAsync: (email) => dispatch(deleteAccountAsync(email)),
    visibleModalSecurityAsync: (visible) => dispatch(visibleModalSecurityAsync(visible)),
    setToggleTwoFactorAsync: (checked) => dispatch(setToggleTwoFactorAsync(checked)),
    setToggleTwoFactorAuthAsync: (checked) => dispatch(setToggleTwoFactorAuthAsync(checked)),
    verifySixPassTwoFactorAsync: (passInfo) => dispatch(verifySixPassTwoFactorAsync(passInfo)),   
    enableTwoFactorVerifyAsync: (params) => dispatch(enableTwoFactorVerifyAsync(params)), 
    getTwoFactorAsync: () => dispatch(getTwoFactorAsync()),
    enableTwoFactorPayload: () => dispatch(enableTwoFactorPayload()),
    disableTwoFactorPayload: () => dispatch(disableTwoFactorPayload()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
