import api from "./api";

export function getProfile(userId) {
  return new Promise((resolve) => {
    api.get("user/profile/".concat(userId)).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function saveProfile(profile) {
  return new Promise((resolve) => {
    api
      .post("user/profile/save", profile)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201) && !!res.data) {
          //TODO: não é bonito, mas é o que temos
          const message = res.data.message || res.data.error || "";
          if (!message || message === "") {
            resolve(res.data);
          } else {
            resolve({ error: true, message });
          }
        }
        resolve({
          error: true,
          message:
            res ||
            res.data ||
            res.data.message ||
            res.data.error ||
            "Internal Server Error",
        });
      })
      .catch((res) => {
        resolve({
          error: true,
          message:
            res ||
            res.data ||
            res.data.message ||
            res.data.error ||
            "Internal Server Error",
        });
      });
  });
}

export function findAllAvatar() {
  return new Promise((resolve) => {
    api.get("avatar/findAll/").then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function getAvatar(id) {
  return new Promise((resolve) => {
    api.get("avatar/get/", { id }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function findAllCountry() {
  return new Promise((resolve) => {
    api.get("country/findAll/").then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function findAllCoin() {
  return new Promise((resolve) => {
    api.get("exchange/coin/findAll/").then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function getPreferences(userId) {
  return new Promise((resolve) => {
    api.get("user/preferences/".concat(userId)).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function getTwoFactor() {
  return new Promise((resolve) => {
    api.get("two-factor?method=preference2FA").then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function enableTwoFactor() {
  return new Promise((resolve) => {
    api.get("auth/enable-two-factor").then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function disableTwoFactor() {
  return new Promise((resolve) => {
    api.delete("auth/disable-two-factor").then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function verifySixPassTwoFactor(passInfo) {
  return new Promise((resolve) => {
    api.post("two-factor", passInfo)
    .then((res) => {
      if (res && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function enableTwoFactorVerify(params) {
  return new Promise((resolve) => {
    api.post("auth/first-two-factor", params)
    .then((res) => {
      if (res) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function savePreferences(preferences) {
  return new Promise((resolve) => {
    api
      .post("user/preferences/save", preferences)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201) && !!res.data) {
          const message = res.data.message || res.data.error || "";
          if (!message || message === "") {
            resolve(res.data);
          } else {
            resolve({ error: true, message });
          }
        }
        resolve({
          error: true,
          message:
            res ||
            res.data ||
            res.data.message ||
            res.data.error ||
            "Internal Server Error",
        });
      })
      .catch((res) => {
        resolve({
          error: true,
          message:
            res ||
            res.data ||
            res.data.message ||
            res.data.error ||
            "Internal Server Error",
        });
      });
  });
}

export function changePassword(userPass) {
  return new Promise((resolve) => {
    api
      .post("user/changePassword", userPass)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201) && !!res.data) {
          //TODO: cada endpoint tem um tratamento diferente, ficar atento
          if (res.data.message) {
            resolve(res.data);
          } else {
            resolve({
              error: true,
              message:
                (res && res.data && res.data.error) || "Internal Server Error",
            });
          }
        }
      })
      .catch((res) => {
        resolve({
          error: true,
          message:
            res ||
            res.data ||
            res.data.message ||
            res.data.error ||
            "Internal Server Error",
        });
      });
  });
}

export function deleteAccount(email) {
  return new Promise((resolve) => {
    api.delete("user/", { email }).then((res) => {
      if (
        res &&
        (res.status === 200 || res.status === 201 || res.status === 204) &&
        !!res.data
      ) {
        resolve(true);
      }
      resolve(false);
    });
  });
}
