import { createSlice } from "@reduxjs/toolkit";
import {
  getEnabledOrderList,
  getDisabledOrderList,
  newOrder,
  cancelOrder,
} from "../../services/apiOperationsService";

export const operationsSlice = createSlice({
  name: "operations",
  initialState: {
    enabledOrderList: [],
    disabledOrderList: [],
    enabledOrderSearch: 0,
    disabledOrderSearch: 0,
    order: {},
  },
  reducers: {
    addAllEnabledOrderList: (state, action) => {
      state.enabledOrderList = action.payload;
    },
    addAllDisabledOrderList: (state, action) => {
      state.disabledOrderList = action.payload;
    },
    addEnabledOrderList: (state, action) => {
      state.enabledOrderList = [...state.enabledOrderList, action.payload];
    },
    addDisabledOrderList: (state, action) => {
      state.enabledOrderList = state.enabledOrderList.filter(
        (order) => order.id !== action.payload.id
      );
      state.disabledOrderList = [...state.disabledOrderList, action.payload];
    },
    addEnabledOrderSearch: (state, action) => {
      state.enabledOrderSearch = action.payload;
    },
    addDisabledOrderSearch: (state, action) => {
      state.disabledOrderSearch = action.payload;
    },
    stopEnabledOrderSearch: (state) => {
      clearInterval(state.enabledOrderSearch);
    },
    stopDisabledOrderSearch: (state) => {
      clearInterval(state.disabledOrderSearch);
    },
    addOrder: (state, action) => {
      state.order = action.payload;
    },
  },
});

const {
  addAllEnabledOrderList,
  addAllDisabledOrderList,
  addEnabledOrderList,
  addDisabledOrderList,
  addEnabledOrderSearch,
  addDisabledOrderSearch,
  stopEnabledOrderSearch,
  stopDisabledOrderSearch,
  addOrder,
} = operationsSlice.actions;

export const findAllEnabledOrderListAsync = (exchangeName) => (dispatch) => {
  let running = false;
  dispatch(stopEnabledOrderSearch());
  getEnabledOrderList(exchangeName).then((recordList) => {
    dispatch(addAllEnabledOrderList(recordList));
  });
  dispatch(
    addEnabledOrderSearch(
      setInterval(() => {
        if (!running) {
          running = true;
          getEnabledOrderList(exchangeName).then((recordList) => {
            dispatch(addAllEnabledOrderList(recordList));
            running = false;
          });
        }
      }, 6000)
    )
  );
};

export const findAllDisabledOrderListAsync = (exchangeName) => (dispatch) => {
  let running = false;
  dispatch(stopDisabledOrderSearch());
  getDisabledOrderList(exchangeName).then((recordList) => {
    dispatch(addAllDisabledOrderList(recordList));
  });
  dispatch(
    addDisabledOrderSearch(
      setInterval(() => {
        if (!running) {
          running = true;
          getDisabledOrderList(exchangeName).then((recordList) => {
            dispatch(addAllDisabledOrderList(recordList));
            running = false;
          });
        }
      }, 10000)
    )
  );
};

export const newOrderAsync = (exchangeName, order) => (dispatch) => {
  return new Promise((resolve) => {
    newOrder(exchangeName, order).then((record) => {
      const error = record.error;
      if (!!error) {
        resolve({ error });
      } else {
        dispatch(addEnabledOrderList(record));
        dispatch(addOrder(record));
        resolve();
      }
    });
  });
};

export const cancelOrderAsync = (exchangeName, partOrder) => (dispatch) => {
  return new Promise((resolve) => {
    cancelOrder(exchangeName, partOrder).then((record) => {
      const error = record.error;
      if (!!error) {
        resolve({ error });
      } else {
        dispatch(addDisabledOrderList(record));
        dispatch(addOrder(record));
        resolve();
      }
    });
  });
};

export const stopOrderListSearchInterval = () => (dispatch) => {
  dispatch(stopEnabledOrderSearch());
  dispatch(stopDisabledOrderSearch());
};

export default operationsSlice.reducer;
