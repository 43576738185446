import React from "react";
import i18next from "i18next";
import "./robotViewer.css";
import { connect } from "react-redux";
import { StopOutlined, CopyOutlined } from "@ant-design/icons";
import { DescriptionModal } from './components/DescriptionModal';
import { Popconfirm, Row, Col, Modal, message, Tooltip } from "antd";
import TOne from './components/robos/TOne';
import TPackage from './components/robos/TPackage';
import TProfit from './components/robos/TProfit';
import TProfitPG from './components/robos/TProfitPG';
import TProfitPlus from './components/robos/TProfitPlus';

import {
  findPackageDetailsAsync,
  findPackageSetupDetailsAsync,
  findProfitDetailsAsync,
  findProfitSetupDetailsAsync,
  findProfitPlusDetailsAsync,
  findProfitPlusSetupDetailsAsync,
  findProfitPgDetailsAsync,
  findProfitPgSetupDetailsAsync,
  findOneDetailsAsync,
  findOneSetupDetailsAsync,
  findReinvestmentAsync,
  findPartialReinvestmentAsync,
  showChild,
  showReinvestingDetails,  
} from "./robotViewerSlice";

import {
  stopBotAsync,
  setProfitAsync,
  setInvestmentAsync,
  simulatePartialReinvestAsync,
  stopBotPartialReinvestAsync,
} from "../robotStop/robotStopSlice";

const { confirm } = Modal;

const mapBotComponentsTypes = {  
  1: TOne,
  2: TPackage,
  3: TProfit,
  4: TProfitPlus,
  7: TProfitPG
}

class RobotViewer extends React.Component {
  constructor(props) {
    super(props);
    this.onViewProfitPlusDetails = this.onViewProfitPlusDetails.bind(this);
    this.onViewPGDetails = this.onViewPGDetails.bind(this);
    this.onReinvestRecord = this.onReinvestRecord.bind(this);
    this.onViewProfitDetails = this.onViewProfitDetails.bind(this);    
    this.getReinvestmentOperations = this.getReinvestmentOperations.bind(this);    
    this.onProfitChange = this.onProfitChange.bind(this);
    this.onInvestmentChange = this.onInvestmentChange.bind(this);  
    this.onViewPackageDetails = this.onViewPackageDetails.bind(this); 
    this.onViewOneDetails = this.onViewOneDetails.bind(this); 

    this.initialState = {
      selling: false,
      duplicating: false,
      visiblePopStopByMarket: false,
      visiblePopDuplicate: false,
      modal: null,
      calculating: false,
      partReinvestRecord: null,
      loadingDrawer: true,
    };

    this.state = this.initialState;   
    this.formRef = React.createRef();
  }  

  componentDidMount() {
    this.findDetails();
    
    window.TICODRobotView = {};
    window.TICODRobotView.onViewProfitPlusDetails = this.onViewProfitPlusDetails; 
    window.TICODRobotView.onViewPGDetails = this.onViewPGDetails; 
    window.TICODRobotView.onReinvestRecord = this.onReinvestRecord; 
    window.TICODRobotView.onViewProfitDetails = this.onViewProfitDetails; 
    window.TICODRobotView.getReinvestmentOperations = this.getReinvestmentOperations;
    window.TICODRobotView.onViewPackageDetails = this.onViewPackageDetails;
    window.TICODRobotView.onViewOneDetails = this.onViewOneDetails;
  }

   componentDidUpdate(prevProps) {    
    const prevDateTime = prevProps.date_time;
    const currentDateTime = this.props.date_time;    
    if (prevDateTime !== currentDateTime) {
      this.setState(this.initialState, () => {
        this.findDetails();
      })
    }
  }

  findDetails() {
    const _findMain = (params) => { 

      const mainMethod = this.props[params.asyncMethod];

      return mainMethod(
        this.props.exchangeName,
        this.props.robot.id
      )
        .then(() => {
          this.setState({ loadingDrawer: false });
          if (
            this.props.robot.status === "REINVESTING" ||
            this.props.robot.status === "REINVESTED"
          ) {
            this.props.findReinvestmentAsync(
              this.props.exchangeName,
              this.props.robot.id,
              this.props.robot.bot.type
            );
          }
        })
    };
    
    if (this.props.robot.bot.type === 1) {
      _findMain({ asyncMethod: "findOneDetailsAsync" })
    } else if (this.props.robot.bot.type === 2) {
      _findMain({ asyncMethod: "findPackageDetailsAsync" })
    } else if (this.props.robot.bot.type === 3) {
      _findMain({ asyncMethod: "findProfitDetailsAsync" })
    } else if (this.props.robot.bot.type === 4) {
      _findMain({ asyncMethod: "findProfitPlusDetailsAsync" })
    } else if (this.props.robot.bot.type === 7) {
      _findMain({ asyncMethod: "findProfitPgDetailsAsync" })
    }
  }

  onProfitChange = (profit) => {
    this.props.setProfitAsync(profit);
  };

  onInvestmentChange = (investment) => {
    this.props.setInvestmentAsync(investment);
  };

  onDescriptionModalFinish(valueParam, state) {
    if (state.partReinvestRecord) {
      this.setState({ calculating: true }, () => {
        this.props
          .simulatePartialReinvestAsync(
            this.props.robot.id,
            this.props.robot.bot.type,
            this.state.partReinvestRecord.id,
            valueParam.investment,
            valueParam.profit
          )
          .then((response) => {
            this.setState({ calculating: false });
            if (response && response.error) {
              message.error(response.message.toString());
            }
          });
      });
    }
  }

  onReinvestRecord(record) {
    if (record && record.status === "PARTIAL_REINVESTING") {
      this.props.findPartialReinvestmentAsync(
        this.props.exchangeName,
        this.props.robot.id,
        record.id,
        this.props.robot.bot.type
      );
    } else {
      this.setState({ partReinvestRecord: record }, () => {
        const Description = DescriptionModal(this.formRef, this.onDescriptionModalFinish, this.props, this.state, this.onProfitChange, this.onInvestmentChange)
        this.setState(
          {
            modal: confirm({
              style: { top: 9 },
              icon: <></>,
              content: Description,
              width: 750,
              okText: i18next.t("trades.stop.options.btn_recovery"),
              cancelText: i18next.t("button.cancel"),
              onOk: () => {
                this.setState({ modal: null }, () => {
                  this.props
                    .stopBotPartialReinvestAsync(
                      this.props.robot.id,
                      "PARTIAL_REINVEST",
                      this.props.robot.bot.type,
                      this.state.partReinvestRecord.id,
                      this.props.investment,
                      this.props.profit
                    )
                    .then(() => {
                      this.setState({ loading: false }, () => {
                        this.props.closeDrawer();
                      });
                    });
                });
              },
              onCancel: () => {
                this.setState({ modal: null });
              },
            }),
          },
          () => {
            if (
              this.state.partReinvestRecord &&
              this.state.partReinvestRecord.id &&
              this.props.investment &&
              this.props.profit
            ) {
              this.setState({ calculating: true }, () => {
                this.props
                  .simulatePartialReinvestAsync(
                    this.props.robot.id,
                    this.props.robot.bot.type,
                    this.state.partReinvestRecord.id,
                    this.props.investment,
                    this.props.profit
                  )
                  .then((response) => {
                    this.setState({ calculating: false });
                    if (response && response.error) {
                      message.error(response.message.toString());
                    }
                  });
              });
            }
          }
        );
      });
    }
  }

  onViewProfitPlusDetails(record) {
    this.props.findProfitPlusSetupDetailsAsync(
      this.props.exchangeName,
      record,
      this.props.profitPlusDetails
    );
    this.props.showChild(true);
  }  

  onViewPGDetails(record) {
    this.props.findProfitPgSetupDetailsAsync(
      this.props.exchangeName,
      record,
      this.props.profitPgDetails
    );
    this.props.showChild(true);
  }

  onViewProfitDetails(record) {
    this.props.findProfitSetupDetailsAsync(
      this.props.exchangeName,
      record,
      this.props.profitDetails
    );
    this.props.showChild(true);
  }

  onViewPackageDetails(record) {
    this.props.findPackageSetupDetailsAsync(
      this.props.exchangeName,
      record,
      this.props.packageDetails
    );
    this.props.showChild(true);
  }

  onViewOneDetails(record) {
    this.props.findOneSetupDetailsAsync(
      this.props.exchangeName,
      record,
      this.props.oneDetails
    );
    this.props.showChild(true);
  }

  getReinvestmentOperations() {
    return this.props.reinvestment.operations;
  }
  
  render() {
    if (this.state.modal) {
      const Description = DescriptionModal(this.formRef, this.onDescriptionModalFinish, this.props, this.state, this.onProfitChange, this.onInvestmentChange)
      this.state.modal.update((prevConfig) => ({
        ...prevConfig,
        content: Description
      }));
    }

    const showPopDuplicate = () => {
      this.setState({ visiblePopDuplicate: true });
    };

    const handleOkStopByMarket = () => {
      this.setState({ selling: true }, () => {
        this.props
          .stopBotAsync(
            this.props.robot.id,
            "SELL_MARKET",
            this.props.robot.bot.type
          )
          .then(() => {
            this.setState(
              { selling: false, visiblePopStopByMarket: false },
              () => {
                this.props.closeDrawer();
              }
            );
          });
      });
    };

    const handleOkDuplicate = () => {
      this.setState({ duplicating: true }, () => {
        this.setState(
          { duplicating: false, visiblePopDuplicate: false },
          () => {
            this.props.closeDrawer();
            this.props.duplicate(this.props.robot);
          }
        );
      });
    };

    const handleCancelStopByMarket = () => {
      this.setState({ visiblePopStopByMarket: false });
    };

    const handleCancelDuplicate = () => {
      this.setState({ visiblePopDuplicate: false });
    };

    const showPopStopByMarket = () => {
      this.setState({ visiblePopStopByMarket: true });
    };    

    const options = (
      <>        
        <Row key="row_options" style={{ 
          display: "flex", 
          justifyContent: 'space-between',
          padding: "0px 56px 0px 10px",
          right: "0",
          height: "57px",
          margin: "-9px",
          alignItems: "center"  
          }}>          
          <h4 style={{ fontSize: "18px" }}>{i18next.t("trades.view.label.config")}</h4>
          <div style={{ display: "flex"}}>            
            <Tooltip mouseEnterDelay="1" title={i18next.t("trades.stop.options.duplicate")} placement="left">
              <Col key="col_duplicate">
                <Popconfirm
                  title={i18next.t("trades.view.message.duplicate")}
                  visible={this.state.visiblePopDuplicate}
                  okButtonProps={{ loading: this.state.duplicating }}
                  okText={i18next.t("button.yes")}
                  cancelText={i18next.t("button.no")}
                  onConfirm={handleOkDuplicate}
                  onCancel={handleCancelDuplicate}
                  placement="bottom"
                >
                  <CopyOutlined     
                  style={{ fontSize: "16px" }}         
                    disabled={this.state.duplicating}
                    onClick={showPopDuplicate}
                  >
                    {i18next.t("trades.stop.options.duplicate")}
                  </CopyOutlined>
                </Popconfirm>
              </Col>  
            </Tooltip>
          </div>
        </Row>        
      </>
    );
  
    const profitOptions = (
      <>        
        <Row key="row_options" style={{          
          display: "flex", 
          justifyContent: 'space-between',
          padding: "7px 51px 0px 0px",
          right: "0"       
          }}>
          <h4 style={{ fontSize: "18px" }}>{i18next.t("trades.view.label.config")}</h4>
          <div style={{ display: "flex"}}>
            <Tooltip mouseEnterDelay="1" title={i18next.t("trades.stop.options.duplicate")} placement="left">
              <Col key="col_duplicate">
                <Popconfirm
                  title={i18next.t("trades.view.message.duplicate")}
                  visible={this.state.visiblePopDuplicate}
                  okButtonProps={{ loading: this.state.duplicating }}
                  okText={i18next.t("button.yes")}
                  cancelText={i18next.t("button.no")}
                  onConfirm={handleOkDuplicate}
                  onCancel={handleCancelDuplicate}
                  placement="bottom"
                >
                  <CopyOutlined
                    style={{ padding: "4px 2px 4px 10px" }}
                    disabled={this.state.duplicating}
                    onClick={showPopDuplicate}
                  >
                    {i18next.t("trades.stop.options.duplicate")}
                  </CopyOutlined>
                </Popconfirm>
              </Col>
            </Tooltip>
            <Tooltip mouseEnterDelay="1" title={i18next.t("trades.stop.options.sell_market")} placement="left">              
              <Col style={{ marginLeft: 9 }} key="col_sell_by_market">                
                  <Popconfirm
                    title={i18next.t("trades.view.message.sellByMarket")}
                    visible={this.state.visiblePopStopByMarket}
                    okButtonProps={{ loading: this.state.selling }}
                    okText={i18next.t("button.yes")}
                    cancelText={i18next.t("button.no")}
                    onConfirm={handleOkStopByMarket}
                    onCancel={handleCancelStopByMarket}
                    placement="bottom"
                  >
                    <StopOutlined
                      style={{ color: "#ff2424", marginLeft: "20px" }}
                      disabled={this.state.selling}
                      onClick={showPopStopByMarket}
                    >
                      {i18next.t("trades.stop.options.sell_market")}
                    </StopOutlined>
                  </Popconfirm>                
              </Col>
            </Tooltip>
          </div>                           
        </Row>        
      </>
    );

    const RobotTypes = mapBotComponentsTypes[this.props.robot.bot.type]; 

    return (
      <RobotTypes props={this.props} 
            state={this.state} 
            options={options} 
            profitOptions={profitOptions}              
      />
    )
  }
}

const mapStateToProps = (state) => ({
  packageDetails: state.robotViewer.packageDetails,
  packageSetupDetails: state.robotViewer.packageSetupDetails,
  profitDetails: state.robotViewer.profitDetails,
  profitSetupDetails: state.robotViewer.profitSetupDetails,
  profitPlusDetails: state.robotViewer.profitPlusDetails,
  profitPlusSetupDetails: state.robotViewer.profitPlusSetupDetails,
  profitPgDetails: state.robotViewer.profitPgDetails,
  profitPgSetupDetails: state.robotViewer.profitPgSetupDetails,
  oneDetails: state.robotViewer.oneDetails,
  oneSetupDetails: state.robotViewer.oneSetupDetails,
  reinvestment: state.robotViewer.reinvestment,
  partialReinvestment: state.robotViewer.partialReinvestment,
  profit: state.robotStop.profit,
  investment: state.robotStop.investment,
  partialReinvestInfo: state.robotStop.partialReinvestInfo,
  childVisible: state.robotViewer.childVisible,
  reinvestingDetailsVisible: state.robotViewer.reinvestingDetailsVisible,
  stopLoadingDataDrawer: state.trades.stopLoadingData,
  botDetails: state.robotViewer.botDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {
    findPackageDetailsAsync: (exchangeName, robotId) =>
      dispatch(findPackageDetailsAsync(exchangeName, robotId)),
    findPackageSetupDetailsAsync: (exchangeName, robot, robotFather) =>
      dispatch(findPackageSetupDetailsAsync(exchangeName, robot, robotFather)),
    findProfitDetailsAsync: (exchangeName, robotId) =>
      dispatch(findProfitDetailsAsync(exchangeName, robotId)),
    findProfitSetupDetailsAsync: (exchangeName, robot, robotFather) =>
      dispatch(findProfitSetupDetailsAsync(exchangeName, robot, robotFather)),
    findProfitPlusDetailsAsync: (exchangeName, robotId) =>
      dispatch(findProfitPlusDetailsAsync(exchangeName, robotId)),
    findProfitPlusSetupDetailsAsync: (exchangeName, robot, robotFather) =>
      dispatch(findProfitPlusSetupDetailsAsync(exchangeName, robot, robotFather)),
    findProfitPgDetailsAsync: (exchangeName, robotId) =>
      dispatch(findProfitPgDetailsAsync(exchangeName, robotId)),
    findProfitPgSetupDetailsAsync: (exchangeName,robot, robotFather) =>
      dispatch(findProfitPgSetupDetailsAsync(exchangeName,robot, robotFather)),
    findOneDetailsAsync: (exchangeName, robotId) =>
      dispatch(findOneDetailsAsync(exchangeName, robotId)),
    findOneSetupDetailsAsync: (exchangeName,  robot, robotFather) =>
      dispatch(findOneSetupDetailsAsync(exchangeName, robot, robotFather)),
    showChild: (show) => dispatch(showChild(show)),
    showReinvestingDetails: (reinvestingDetailsVisible) =>
      dispatch(showReinvestingDetails(reinvestingDetailsVisible)),
    stopBotAsync: (robotId, action, type) =>
      dispatch(stopBotAsync(robotId, action, type)),
    findReinvestmentAsync: (exchangeName, robotId, robotType) =>
      dispatch(findReinvestmentAsync(exchangeName, robotId, robotType)),
    setProfitAsync: (profit) => dispatch(setProfitAsync(profit)),
    setInvestmentAsync: (investment) =>
      dispatch(setInvestmentAsync(investment)),
    simulatePartialReinvestAsync: (robotId, robotType, setupId, investment, profit) =>
      dispatch(simulatePartialReinvestAsync(robotId, robotType, setupId, investment, profit)),
    stopBotPartialReinvestAsync: (robotId, action, robotType, setupId, investment, profit) =>
      dispatch(stopBotPartialReinvestAsync(robotId, action, robotType, setupId, investment, profit)),
    findPartialReinvestmentAsync: (exchangeName, robotId, setupId, robotType) =>
      dispatch(findPartialReinvestmentAsync(exchangeName, robotId, setupId, robotType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RobotViewer);
