import { createSlice } from "@reduxjs/toolkit";
import { getBook, getCurrentPriceCoin } from "../../services/apiOrderBookService";

export const orderBookSlice = createSlice({
  name: "orderBook",
  initialState: {
    bookBuy: [],
    bookSell: [],
    bookSearch: 0,
    currentValueCoin: 0
  },
  reducers: {
    addBookBuy: (state, action) => {
      state.bookBuy = action.payload;
    },
    addBookSell: (state, action) => {
      state.bookSell = action.payload;
    },
    addBookSearch: (state, action) => {
      state.bookSearch = action.payload;
    },
    setCurrentValueCoin: (state, action) => {
      state.currentValueCoin = action.payload;
    },    
    stopBookSearch: (state) => {
      clearInterval(state.bookSearch);
    },
  },
});

const {
  addBookBuy,
  addBookSell,
  addBookSearch,
  stopBookSearch,
  setCurrentValueCoin
} = orderBookSlice.actions;

export const findBookAsync = (
  exchangeName,
  symbol,
  pricePrecision,
  amountPrecision,
  totalPrecision
) => (dispatch) => {
  return new Promise((resolve) => {
    let running = false;
    dispatch(stopBookSearch());
    dispatch(
      addBookSearch(
        setInterval(() => {
          if (!running) {
            running = true;
            getBook(exchangeName, symbol).then((recordList) => {
              if (exchangeName === "binance") {
                recordList.asks = recordList.asks.reverse();
                const binanceFormat = (item) => {
                  const price = parseFloat(item[0]).toFixed(pricePrecision);
                  const amount = parseFloat(item[1]).toFixed(amountPrecision);
                  const total = (item[0] * item[1]).toFixed(totalPrecision);
                  return {
                    key:
                      exchangeName +
                      "_" +
                      symbol +
                      "_" +
                      price +
                      "_" +
                      amount +
                      "_" +
                      total +
                      "_" +
                      new Date().getTime(),
                    price,
                    amount,
                    total,
                  };
                };
                dispatch(addBookBuy(recordList.bids.map(binanceFormat)));
                dispatch(addBookSell(recordList.asks.map(binanceFormat)));
              }
              running = false;
              resolve();
            });
          }
        }, 1800)
      )
    );
  });
};

export const stopBookSearchInterval = () => (dispatch) => {
  dispatch(stopBookSearch());
};

export const findCurrentPriceCoinAsync = (symbol) => (dispatch) => {
  return new Promise((resolve) => {
    getCurrentPriceCoin(symbol).then((record) => {
      const error = record.error;
      if (!!error) {
        resolve({ error });
      } else {
        dispatch(setCurrentValueCoin(record));        
        resolve();
      }
    });
  });
};

export default orderBookSlice.reducer;
