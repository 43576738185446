import { createSlice } from "@reduxjs/toolkit";
import {
  createConfigPackageBot,
  startBot,
  editConfigPackageBot,
  editBot,
  getBot,
  simulateProfitPgGrids,
  simulatePackageGrids,
  simulateProfitPlusGrids

} from "../../../services/apiRobotRegisterService";

export const robotSlice = createSlice({
  name: "robotSlice",
  initialState: {
    packageBot: {},
    currentBot: 0,
    currentStepRegister: 0,
    profitPgStep: 1,
    pgSimulateResults: [],
    plusSimulateResults: [],
    packageSimulateResults: [],
    loading: false,
    valueParam: {},  
  },

  reducers: {
    addPackageBot: (state, action) => {
      state.packageBot = action.payload;
    },
    setCurrentBot: (state, action) => {
      state.currentBot = action.payload;
    },
    setCurrentStepRegister: (state, action) => {
      state.currentStepRegister = action.payload;
    },    
    setProfitPgStep: (state, action) => {
      state.profitPgStep = action.payload;
    },
    setPgSimulateResults: (state, action) => {
      state.pgSimulateResults = action.payload;
    },
    setPlusSimulateResults: (state, action) => {
      state.plusSimulateResults = action.payload;
    },
    setPackageSimulateResults: (state, action) => {
      state.packageSimulateResults = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setValueParam: (state, action) => {
      state.valueParam = action.payload;
    },
  },
});

const { 
  addPackageBot, 
  setCurrentBot, 
  setProfitPgStep, 
  setPgSimulateResults,
  setPlusSimulateResults,
  setPackageSimulateResults,
  setLoading,
  setValueParam,
  setCurrentStepRegister
} = robotSlice.actions;

export const createConfigPackageBotAsync = (exchangeName, robot) => () => {
  return createConfigPackageBot(exchangeName, robot);
};

export const startBotAsync = (exchangeName, robot) => () => {
  return startBot(exchangeName, robot);
};

export const editConfigPackageBotAsync = (exchangeName, robot) => () => {
  return editConfigPackageBot(exchangeName, robot);
};

export const editBotAsync = (exchangeName, robot) => () => {
  return editBot(exchangeName, robot);
};

export const findBotAsync = (exchange, id, type) => (dispatch) => {
  return getBot(exchange, id, type).then((record) => {
    record = {...record, total: (record.investment * record.quantity)}
    dispatch(addPackageBot(record));
  });
};

export const simulatePgProfitAsync = (exchangeName, robot) => (dispatch) => {
  return simulateProfitPgGrids(exchangeName, robot);
};

export const simulatePlusProfitAsync = (exchangeName, robot) => (dispatch) => {
  return simulateProfitPlusGrids(exchangeName, robot);
};

export const simulatePackageAsync = (exchangeName, robot) => (dispatch) => {
  return simulatePackageGrids(exchangeName, robot);
};

export const changeBot = (key) => (dispatch) =>
  dispatch(setCurrentBot(key))

export const changeProfitPgStep = (key) => (dispatch) =>
  dispatch(setProfitPgStep(key))  

export const changeCurrentStepRegister = (key) => (dispatch) =>
  dispatch(setCurrentStepRegister(key))   

export const updatePgSimulateResults = (results) => (dispatch) =>
  dispatch(setPgSimulateResults(results))  

export const updatePlusSimulateResults = (results) => (dispatch) =>
dispatch(setPlusSimulateResults(results))  

export const updatePackageSimulateResults = (results) => (dispatch) =>
dispatch(setPackageSimulateResults(results))  

export const clearSettings = () => (dispatch) =>{
    dispatch(setCurrentBot(0))
    dispatch(setProfitPgStep(1))
    dispatch(setValueParam([]))
    dispatch(setPgSimulateResults([]))
    dispatch(setPlusSimulateResults([]))
    dispatch(setPackageSimulateResults([]))
    dispatch(setCurrentStepRegister(0))
    dispatch(setLoading(false))
};

export const updateLoading = (loading) => (dispatch) =>
  dispatch(setLoading(loading))  
  
export const updateValueParam = (params) => (dispatch) =>
  dispatch(setValueParam(params)) 

export default robotSlice.reducer;