import React, { useEffect, useState } from "react";
import moment from "moment";
import i18next from "i18next"; 
import { DatePicker, Space, Select, Button, Tooltip, Form, Row, Col, Card } from 'antd';
import { SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import './filters.css'

const { Option } = Select;

const formRef = React.createRef();

const { RangePicker } = DatePicker;

const Filters = (props) => {

  const { 
    setOperationsMainAsync,
    changeLoading
  } = props;

  const [bots, setBots] = useState([]);
  const [symbols, setSymbols] = useState([]);

  useEffect(() => {
    let { 
      bots, 
      symbols 
    } = props.operationsFiltersContent;

    setBots(bots);
    setSymbols(symbols);
  }, [props])


  const onFinish = (filters) => {
    changeLoading(true);
    if (filters.clients && filters.clients.length === 0) {
      filters.clients = undefined;
    }
    setOperationsMainAsync(JSON.stringify(filters));
  }

  const handleBack = (currentComponent) =>  {
    if (currentComponent === "resume") {
      props.setOperationsComponentAsync("main");
    } else if (currentComponent === "details") {
      props.setOperationsComponentAsync("resume");       
    } 
  }

  const changeTitle = (currentComponent) => {
    if (currentComponent === "main") {
      return i18next.t("portfolio.operations.tableMain.title")
    } else if (currentComponent === "resume") {
      return i18next.t("portfolio.operations.tableResume.title")
    } else if (currentComponent === "details") {
      return i18next.t("portfolio.operations.tableDetails.title")
    } else {
      return i18next.t("portfolio.operations.tableMain.title")
    }
  }

  const typeButton = (currentComponent) => {
    if ((currentComponent !== "main") && (currentComponent !== "")) {
      return (
        <Tooltip mouseEnterDelay="1" title="Voltar">
          <Button 
            onClick={() => handleBack(props.operationsComponent)} 
            type="secondary" 
            shape="circle" 
            icon={<ArrowLeftOutlined />} 
          />
        </Tooltip>
      )
    }
  }

  return (
    <div>
      <Row>
        <Col span={12}>
          <Space direction="horizontal" style={{marginBottom:12, display: "flex", alignItems: "center"}}>
            {typeButton(props.operationsComponent)}
            <h1 style={{fontSize:18, color:'#fff', margin: 0 }}>
              {changeTitle(props.operationsComponent)}
            </h1>
          </Space>
        </Col>     
      </Row>

      <Card
        size="small"
        bordered={false}
        style={{ alignItems: 'center', background: '#141414', borderRadius: '5px', marginBottom: '12px'}}
      >
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', height: '35px'}}> */}
          <Form ref={formRef} onFinish={onFinish}>
            <Row className="filtersForm">
              <Col xs={24} md={12} lg={8}>
                <Form.Item name="rangeDate"
                  rules={[{ required: true, message: i18next.t("operations.filters.erroMessage") }]}>
                  <RangePicker

                  ranges={{
                    [`${i18next.t("portfolio.operations.filters.placeholders.1day")}`]: [moment().subtract(1, 'days'), moment()],
                    [`${i18next.t("portfolio.operations.filters.placeholders.1week")}`]: [moment().subtract(7, 'days'), moment()],
                    [`${i18next.t("portfolio.operations.filters.placeholders.1month")}`]: [moment().startOf('month'), moment().endOf('month')],
                    [`${i18next.t("portfolio.operations.filters.placeholders.6months")}`]: [moment().subtract(6, 'months'), moment()],
                    [`${i18next.t("portfolio.operations.filters.placeholders.1year")}`]: [moment().subtract(12, 'months'), moment()],
                  }}

                  format="DD/MM/YYYY"

                  placeholder={[
                    i18next.t("portfolio.operations.filters.placeholders.startDate"),
                    i18next.t("portfolio.operations.filters.placeholders.endDate")
                  ]}

                  style={{ width: '100%' }}
                  dateRender={current => {
                    const style = {};
                    if (current.date() === 1) {
                      style.border = '1px solid #666';
                      style.borderRadius = '50%';
                    }
                    return (
                      <div className="ant-picker-cell-inner" style={style}>
                        {current.date()}
                      </div>
                    );
                  }}
                />
              </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={4}>
                <Form.Item 
                  name="bot"
                  rules={[{ required: false, message: i18next.t("portfolio.operations.filters.erroMessage") }]}>
                  
                  <Select
                    allowClear
                    // style={{ width: 150 }}
                    placeholder={i18next.t("portfolio.operations.filters.placeholders.bot")}>
                    {
                      bots && bots.length > 0 ?
                      bots.map((bot) => <Option key={bot.id}>{bot.name}</Option>)
                      : <Option key={0}></Option>
                    }
                  </Select>
                </Form.Item>  
              </Col>        

              <Col xs={24} md={12} lg={4}>
                <Form.Item 
                  name="symbol" rules={[{ required: false, message: i18next.t("portfolio.operations.filters.erroMessage") }]}>
                  <Select
                    showSearch
                    allowClear
                    // style={{ width: 150, marginRight: 8, marginBottom: 0 }}
                    placeholder={i18next.t("portfolio.operations.filters.placeholders.symbol")}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      symbols && symbols.length > 0 ?
                      symbols.map((symbol) => <Option value={symbol.name} key={symbol.id}>{symbol.name}</Option>)
                      : <Option key={0}></Option>
                    }
                  </Select>
                </Form.Item>
              </Col>
                
              <Col xs={24} md={12} lg={2} >
                <Form.Item>
                  <Tooltip mouseEnterDelay="1" title={i18next.t("portfolio.operations.tooltips.submit")}>
                    <Button htmlType="submit" 
                      type="primary" shape="circle" 
                      icon={<SearchOutlined />} />
                  </Tooltip>
                </Form.Item>     
                {/* <ExportButtonMain {...props} /> */}
              </Col>     
            </Row>        
          </Form>
        {/* </div> */}
      </Card>
    </div>
  )
}
  
export default Filters;