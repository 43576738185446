import React from "react";
import "./operations.css";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import i18next from "i18next";
import { connect } from "react-redux";
import { convertValuesCoins } from '../shared/utils/convertValuesCoins';
import { getAccountAsync }from "../exchanges/exchangeSlice";

import {
  Tabs,
  Table,
  Button,
  Row,
  Col,
  Select,
  Empty,
  Slider,
  InputNumber,
  message,
  Tag,
  notification,
  Spin
} from "antd";

import {
  SyncOutlined,
  CloseCircleOutlined,
  AlertOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import {
  findAllEnabledOrderListAsync,
  findAllDisabledOrderListAsync,
  newOrderAsync,
  cancelOrderAsync,
  stopOrderListSearchInterval,  
} from "./operationsSlice";
import { findAllExchangeAsync } from "../shared/exchangeListSlice";
import { findAllSymbolAsync } from "../shared/coinSlice";
import {
  findBalanceAsync,
  stopBalanceSearchInterval,
} from "../shared/walletSlice";
import {
  findBookAsync,
  stopBookSearchInterval,
  findCurrentPriceCoinAsync
} from "../shared/orderBookSlice";

const { TabPane } = Tabs;
const { Option } = Select;

const FILTER_COIN_ID = 1;
const FILTER_COIN_NAME = 2;

const OP_BUY = "BUY";
const OP_SELL = "SELL";

let isMountedComponent = true;

class Operations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      coinPercent: null,
      coinValue: null,
      coinPrice: null,
      exchangeSelected: {},
      coinSelected: {},
      loadingPage: false,
      loadingPageCoin: false,
      operationType: OP_BUY,
    };

    this.operationsRef = React.createRef();

    this.onChangeExchangeSelect = this.onChangeExchangeSelect.bind(this);
    this.onChangeCoinSelect = this.onChangeCoinSelect.bind(this);
    this.getStepPrecision = this.getStepPrecision.bind(this);
    this.buySell = this.buySell.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.columnsBuySellPrecision = this.columnsBuySellPrecision.bind(this);    
  } 

  async componentDidMount() {
    isMountedComponent = true;
    await this.props.getAccountAsync();
    let indexCoin = {};
    const coinName = JSON.parse(localStorage.getItem("coinName"));

    this.setState({ loadingPage: true }, () => {
      this.props.findAllExchangeAsync().then(() => {
        if (isMountedComponent) { 
          this.setState({ exchangeSelected: this.props.allExchange[0] }, () => {
            const exchangeName = this.state.exchangeSelected.id;

            this.props.findAllEnabledOrderListAsync(this.state.exchangeSelected.id);
            this.props.findAllDisabledOrderListAsync(
              this.state.exchangeSelected.id
            );

            this.props.findAllSymbolAsync(exchangeName).then(() => {

              if (coinName) {
                this.props.allSymbol.forEach((element) => {
                  if (element.id === coinName.id) {
                    indexCoin = element
                  }
                });
              } else {
                indexCoin = this.props.allSymbol[0];
              }
              
              if (isMountedComponent) {  
                this.setState({ coinSelected: indexCoin }, () => {                          
                  this.props
                    .findBalanceAsync(exchangeName, this.state.coinSelected.name)
                    .then(() => {
                      this.props
                      .findBookAsync(
                        exchangeName,
                        this.state.coinSelected.pair,
                        this.state.coinSelected.price_precision,
                        this.state.coinSelected.quantity_precision,
                        this.state.coinSelected.fee_precision
                      )
                      .then(() => {
                        this.props.findCurrentPriceCoinAsync(this.state.coinSelected.pair).then(() => {
                          this.setState({coinPrice: this.props.currentValueCoin.price})
                        }); 
                        this.setState({ loadingPage: false });
                    });
                  });
                });                 
              }
            });
          });
        }
      });
    });       
  }

  componentWillUnmount() {
    isMountedComponent = false;
    this.props.stopBookSearchInterval();
    this.props.stopBalanceSearchInterval();
    this.props.stopOrderListSearchInterval();
  }

  onChangeExchangeSelect(value) {
    this.setState({
      exchangeSelected: this.props.allExchange.find(
        (item) => item.id === value
      ),
    });
  }

  onChangeCoinSelect(value) {
    this.updateCoin(value, FILTER_COIN_ID);
  }

  updateCoin(value, type) {
    return new Promise((resolve) => {
      const exchangeName = this.state.exchangeSelected.id;      
      this.setState(
        {
          coinPercent: null,
          coinValue: null,
          coinPrice: null,          
          coinSelected: this.props.allSymbol.find((item) => {
            if (type === FILTER_COIN_ID) {
              return item.id === value;
            } else if (type === FILTER_COIN_NAME) {
              return item.name === value;
            } else {
              return false;
            }
          }),
        },
      () => {          
        this.setState({loadingPageCoin: true});
        this.props
          .findBalanceAsync(exchangeName, this.state.coinSelected.name)
          .then(() => {
            this.props
              .findBookAsync(
                exchangeName,
                this.state.coinSelected.pair,
                this.state.coinSelected.price_precision,
                this.state.coinSelected.quantity_precision,
                this.state.coinSelected.fee_precision
              )
              .then(() => {
                if (!(JSON.stringify(this.state.coinSelected) === JSON.stringify({}))) {
                  localStorage.setItem("coinName", JSON.stringify(this.state.coinSelected));
                }
                this.setState({ loadingPageCoin: false }, () => {
                  resolve();
                });
              });
          });
          this.props.findCurrentPriceCoinAsync(this.state.coinSelected.pair).then(() => {
            this.setState({coinPrice: this.props.currentValueCoin.price})
          });          
        }        
      );      
    });
  }

  onChangeAmount = (coinPercent) => {
    const criptoFiat = this.state.coinSelected.name.split("/");
    const cripto = criptoFiat[0];
    const fiat = criptoFiat[1];
    const quantityPrecision = this.state.coinSelected.quantity_precision;

    let available = 0;
    if (this.state.operationType === OP_BUY) {
      this.props.balance.forEach((element) => {
        if (element.asset === fiat) {
          available = element.available;
        }
      });
    } else {
      this.props.balance.forEach((element) => {
        if (element.asset === cripto) {
          available = element.available;
        }
      });
    }
    if (
      available > 0 &&
      coinPercent > 0 &&
      this.state.coinPrice &&
      this.state.coinPrice > 0
    ) {
      let coinValue = 0;
      if (this.state.operationType === OP_BUY) {
        coinValue = (coinPercent * available) / 100 / this.state.coinPrice;
      } else {
        coinValue = (coinPercent * available) / 100;
      }
      const numberDecimal = coinValue.toString().split(".");
      if (numberDecimal.length === 2) {
        const numer = numberDecimal[0];
        const decimal = numberDecimal[1].slice(0, quantityPrecision);
        coinValue = parseFloat(numer + "." + decimal);
      }
      this.setState({ coinPercent, coinValue });
    } else if (coinPercent > 0) {
      this.setState({ coinPercent });
    } else {
      this.setState({ coinPercent: null, coinValue: null });
    }
  };

  onChangeAmountValue = (coinValue) => {
    const criptoFiat = this.state.coinSelected.name.split("/");
    const cripto = criptoFiat[0];
    const fiat = criptoFiat[1];
    let available = 0;
    if (this.state.operationType === OP_BUY) {
      this.props.balance.forEach((element) => {
        if (element.asset === fiat) {
          available = element.available;
        }
      });
    } else {
      this.props.balance.forEach((element) => {
        if (element.asset === cripto) {
          available = element.available;
        }
      });
    }
    if (
      available > 0 &&
      coinValue > 0 &&
      this.state.coinPrice &&
      this.state.coinPrice > 0
    ) {
      let total_pagar = coinValue * this.state.coinPrice;
      let total_percentual = (total_pagar * 100) / available;
      if (total_percentual > 100) {
        this.setState({ coinPercent: 100, coinValue });
      } else if (total_percentual < 0) {
        this.setState({ coinPercent: 0, coinValue });
      } else {
        this.setState({ coinPercent: total_percentual, coinValue });
      }
    } else if (coinValue > 0) {
      this.setState({ coinValue });
    } else {
      this.setState({ coinPercent: null, coinValue: null });
    }
  };

  getStepPrecision = (precision) => {
    let increaseString = "0.";
    for (let i = 0; i < precision; i++) {
      if (i === precision - 1) {
        increaseString = increaseString + "1";
      } else {
        increaseString = increaseString + "0";
      }
    }
    return parseFloat(increaseString);
  };

  updateBalance() {
    this.props.findBalanceAsync(
      this.state.exchangeSelected.id,
      this.state.coinSelected.name
    );
  }

  buySell() {
    if (this.props.listAccount[0] === 1) {
      const key = `open_${Date.now()}`;
      notification.error({
        key,
        message: i18next.t("trades.warning.title"),
        description: i18next.t("trades.warning.message_warning"),
        duration: 0,
        className: "message-without-exchange"
      });
    }

    const quantity = this.state.coinValue;
    const price = this.state.coinPrice;
    if (quantity && quantity > 0 && price && price > 0) {
      let order = {
        symbol: this.state.coinSelected.pair,
        type: "LIMIT",
        side: this.state.operationType,
        quantity: this.state.coinValue,
        price: this.state.coinPrice,
      };
      this.props
        .newOrderAsync(this.state.exchangeSelected.id, order)
        .then((response) => {
          if (response && response.error) {
            message.error(
              i18next.t("operations.mesage.operation_created_error")
            );
          } else {
            message.success(
              i18next.t("operations.mesage.operation_created_success")
            );
            this.updateBalance();
          }
        });
    }
  }

  cancelOrder(order) {
    this.props
      .cancelOrderAsync(this.state.exchangeSelected.id, {
        id: order.id,
        orderId: order.orderId,
        symbol: order.symbol,
      })
      .then((response) => {
        if (response && response.error) {
          message.error(
            i18next.t("operations.mesage.operation_canceled_error")
          );
        } else {
          message.success(
            i18next.t("operations.mesage.operation_canceled_success")
          );
          this.updateBalance();
        }
      });
  }

  columnsBuySellPrecision = (state) => {      
    return [
      {
        title: `${i18next.t("operations.table_order.price")}`,
        dataIndex: "price",
        key: "price",
        width: "29%",
      },
      {
        title: `${i18next.t("operations.table_order.amount")}`,
        dataIndex: "amount",
        key: "amount",
        width: "32%",
        align: "right",  
      },
      {
        title: `${i18next.t("operations.table_order.total")}`,
        dataIndex: "total",
        key: "total",
        width: "40%",
        align: "right",        
        render: (_, record) => convertValuesCoins(record.total, state.coinSelected ? state.coinSelected.price_precision : 8 ),
      },
    ];
  }  

  render() {
    const { coinPercent } = this.state;

    const statusTableOp = {
      NEW: {
        caption: `${i18next.t("operations.status.NEW")}`,
        color: "default",
        icon: <SyncOutlined spin />,
      },
      PARTIALLY_FILLED: {
        caption: `${i18next.t("operations.status.PARTIALLY_FILLED")}`,
        color: "default",
        icon: <SyncOutlined spin />,
      },
      FILLED: {
        caption: `${i18next.t("operations.status.FILLED")}`,
        color: "default",
        icon: <CheckCircleOutlined />,
      },
      CANCELED: {
        caption: `${i18next.t("operations.status.CANCELED")}`,
        color: "default",
        icon: <CloseCircleOutlined />,
      },
      REJECTED: {
        caption: `${i18next.t("operations.status.REJECTED")}`,
        color: "default",
        icon: <CloseCircleOutlined />,
      },
      EXPIRED: {
        caption: `${i18next.t("operations.status.EXPIRED")}`,
        color: "default",
        icon: <CloseCircleOutlined />,
      },
      ERROR: {
        caption: `${i18next.t("operations.status.ERROR")}`,
        color: "default",
        icon: <AlertOutlined />,
      },
    };

    const opColumns = [
      {
        title: `${i18next.t("operations.table_operations.status")}`,
        dataIndex: "status",
        key: "status",
        width: "21%",
        render: (_, record) => {
          let status;
          if (record && record.status in statusTableOp) {
            status = record.status;
          } else {
            status = "ERROR";
          }
          const caption = statusTableOp[status].caption;
          const color = statusTableOp[status].color;
          const icon = statusTableOp[status].icon;
          return (
            <Tag className="tag-status-bot" icon={icon} color={color} style={{ width: "80%" }}>
              {caption}
            </Tag>
          );
        },
      },
      {
        title: `${i18next.t("operations.table_operations.exchange")}`,
        dataIndex: "exchange",
        key: "exchange",
      },
      {
        title: `${i18next.t("operations.table_operations.coin")}`,
        dataIndex: "symbol",
        key: "symbol",
      },
      {
        title: `${i18next.t("operations.table_operations.operation")}`,
        dataIndex: "side",
        key: "side",
      },
      {
        title: `${i18next.t("operations.table_operations.price")}`,
        dataIndex: "price",
        key: "price",
        render: (_, record) => convertValuesCoins(record.price),
      },
      {
        title: `${i18next.t("operations.table_operations.amount")}`,
        dataIndex: "quantity",
        key: "quantity",
        width: "15%",
        render: (_, record) => convertValuesCoins(record.quantity),
      },
    ];

    const opColumnsEnabled = [
      ...opColumns,
      {
        title: `${i18next.t("operations.table_operations.action")}`,
        dataIndex: "action",
        key: "action",
        align: "center",
        render: (_, record) => (
          <Button
            type="link"
            onClick={() => {
              this.cancelOrder(record);
            }}
          >
            {i18next.t("button.cancel")}
          </Button>
        ),
      },
    ];

    const exchangeChartParam =
      this.state.exchangeSelected.id === "novadax"
        ? "mercado"
        : this.state.exchangeSelected.id;

    const pricePrecision = this.state.coinSelected.price_precision;
    const quantityPrecision = this.state.coinSelected.quantity_precision;

    const criptoFiat =
      this.state.coinSelected &&
      this.state.coinSelected.name &&
      this.state.coinSelected.name.split("/");

    let cripto = "";
    let fiat = "";
    if (criptoFiat && criptoFiat.length === 2) {
      cripto = criptoFiat[0];
      fiat = criptoFiat[1];
    }

    return (
      <Spin className="screen-loader-container-trades-coins" spinning={this.state.loadingPageCoin}>      
        <Spin className="screen-loader-container-trades" spinning={this.state.loadingPage}>
          <div
            className="operations-container"
            style={{
              maxWidth: 1680,
              margin: "0 auto",
            }}
          >
            <Row gutter={[9, 9]}>
              <Col            
                xs={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                md={{ span: 24, order: 3 }}
                lg={{ span: 12, order: 1 }}
                xl={{ span: 12, order: 1 }}
              > 
                <div className="tranding-view"> 
                  <TradingViewWidget
                    symbol={`${exchangeChartParam}:${this.state.coinSelected.pair}`}
                    interval={1}              
                    theme={Themes.DARK}
                    locale="en"
                    height={440}
                    width={989.81}
                  />
                </div>            
                <div
                  className="tab-container-operation" 
                  style={{ width: "100%", marginTop: 9, height: 400, maxHeight: 400 }}
                >
                  <Tabs>
                    <TabPane
                      tab={i18next.t("operations.tab_pane.active_orders")}
                      key="1"
                    >
                      <Table
                        className="usrTable" 
                        rowKey="id"
                        columns={opColumnsEnabled}
                        dataSource={this.props.enabledOrderList}                    
                        locale={{
                          emptyText: (
                            <Empty
                              description={i18next.t("message.nothing")}
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                          ),
                        }}
                        size="small"
                        pagination={false}
                        scroll={{  y: "290px" }}
                      />
                    </TabPane>
                    <TabPane
                      tab={i18next.t("operations.tab_pane.orders_log")}
                      key="2"
                    >
                      <Table
                        className="usrTable" 
                        rowKey="id"
                        columns={opColumns}
                        dataSource={this.props.disabledOrderList}                    
                        locale={{
                          emptyText: (
                            <Empty
                              description={i18next.t("message.nothing")}
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                          ),
                        }}
                        size="small"
                        pagination={false}
                        scroll={{y: "290px" }}
                      />
                    </TabPane>
                  </Tabs>
                </div>
              </Col>
              <Col                
                xs={{ span: 24, order: 3 }}
                sm={{ span: 24, order: 3 }}
                md={{ span: 12, order: 2 }}
                lg={{ span: 6, order: 2 }}
                xl={{ span: 6, order: 2 }}
              >
                <div className="tab-container-operation" style={{ height: "100%"}}>
                  <h4 style={{ paddingTop: 9 }}>
                    {i18next.t("operations.label.order_list")}
                  </h4>
                  <Table
                    key="tableSell"
                    className="sell"
                    columns={this.columnsBuySellPrecision(this.state)}
                    dataSource={this.props.bookSell}
                    showHeader={true}
                    size="small"
                    pagination={false}
                    scroll={{y: "375px" }}
                    rowClassName={() => "rowClass"}
                    onRow={(record) => {
                      return {
                        onClick: event => {
                          this.setState({coinPrice: record.price})
                        }, 
                      };
                    }}
                  />
                  <h5 style={{ padding: 0, marginTop: 6, marginBottom: 6 }}>
                    {this.props.bookSell && this.props.bookSell.length > 0
                      ? this.props.bookBuy[0].price
                      : ""}
                  </h5>
                  <Table
                    key="tableBuy"
                    className="buy"
                    columns={this.columnsBuySellPrecision(this.state)}
                    dataSource={this.props.bookBuy}
                    showHeader={false}
                    size="small"
                    pagination={false}
                    scroll={{y: "375px" }}
                    rowClassName={() => "rowClass"}
                    onRow={(record) => {
                      return {
                        onClick: event => {
                          this.setState({coinPrice: record.price})
                        }, 
                      };
                    }}
                  />
                </div>
              </Col>
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 6, order: 3 }}
                xl={{ span: 6, order: 3 }}            
              >
                <div
                  className="tab-container-main-operation"
                  style={{ height: "100%" }}
                >
                  <Row className="select-container-operation">
                    <Select
                      showSearch
                      placeholder={i18next.t("operations.select.exchange")}
                      optionFilterProp="children"
                      style={{ width: "50%" }}
                      onChange={this.onChangeExchangeSelect}
                      defaultValue={"binance"}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.props.allExchange.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      showSearch
                      placeholder={i18next.t("operations.select.coin")}
                      optionFilterProp="children"
                      style={{ width: "50%", paddingLeft: 9 }}
                      onChange={this.onChangeCoinSelect}
                      value={this.state.coinSelected.id}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={this.state.loadingPageCoin ? true : false}
                    >
                      {this.props.allSymbol.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Row>
                  <div className="line-operation-select"></div>
                  {this.props.balance && this.props.balance.length > 0 ? (
                    <>
                      <div style={{ padding: "0px 9px", width: "100%" }}>
                        <h4 style={{ paddingTop: 9 }}>
                          {i18next.t("trades.label.wallet")}
                        </h4>
                        {this.props.balance.map((item) => (
                          <Row key={`row_${item.asset}`}>
                            <Col key={`col_caption_${item.asset}`} span={12}>
                              {item.asset}
                            </Col>
                            <Col
                              key={`col_value_${item.asset}`}
                              span={12}
                              style={{ textAlign: "right" }}
                            >
                              {item.available}
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="line-operation"></div>
                  <Row>
                    <div style={{ padding: "0px 9px", width: '99%' }}>
                      <h4 style={{ paddingTop: 9, paddingBottom: 3 }}>
                        {i18next.t("operations.label.create_order")}
                      </h4>
                      <Row key={"row_create_order_options"} style={{ paddingTop: 9}}>
                        <Button
                          type="text"
                          style={{ 
                            width: "49%", 
                            height: '42px',
                            backgroundColor: "#215e2c",
                            fontWeight: 500 }}
                          onClick={() => {
                            this.setState({ operationType: OP_BUY, coinPercent: null, coinValue: 0});
                          }}
                        >
                          {i18next.t("operations.label.buy")}
                        </Button>
                        <Button
                          type="text"
                          style={{
                            width: "49%",
                            height: '42px',
                            backgroundColor: "#c84040",
                            marginLeft: "2%",
                            fontWeight: 500
                          }}
                          onClick={() => {
                            this.setState({ operationType: OP_SELL, coinPercent: null, coinValue: 0 });                      
                          }}
                        >
                          {i18next.t("operations.label.sell")}
                        </Button>
                      </Row>
                      <Row
                        className="sell-input"
                        key={"row_create_order_register_price"}
                        style={{ marginTop: 20}}
                      >
                        <div 
                          style={{ 
                            border: '1px solid #434343', 
                            borderRight: 5, 
                            paddingLeft: 5,
                            paddingTop: 4,
                            width: '22%',
                            color: 'rgb(132, 142, 156)'}} 
                          >
                        {i18next.t("operations.label.price")}
                        </div>
                          <InputNumber
                            className="input-amount"
                            style={{ width: "60%", borderLeft: 0, borderRight: 0 }}
                            min={0}
                            step={this.getStepPrecision(pricePrecision)}
                            decimalSeparator="."
                            precision={pricePrecision}
                            value={this.state.coinPrice}                        
                            onChange={(coinPrice) => {
                              this.setState({ coinPrice });
                            }}
                          />
                        <div 
                          style={{ 
                            border: '1px solid #434343', 
                            borderLeft: 0,
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 4,
                            textAlign: 'center',
                            width: '18%',
                            color: 'rgb(132, 142, 156)'}} 
                            >
                          {fiat}
                        </div>
                      </Row>
                      <Row
                        className="sell-input"
                        key={"row_create_order_register_amount"}
                        style={{ marginTop: 20}}
                      >
                        <div 
                          style={{ 
                            border: '1px solid #434343', 
                            borderRight: 5, 
                            paddingLeft: 5,
                            paddingTop: 4,
                            width: '22%',
                            color: 'rgb(132, 142, 156)'}} 
                          > 
                            {i18next.t("operations.label.amount")}
                          </div>
                          <InputNumber
                            className="input-amount"
                            style={{ width: "60%", borderLeft: 0, borderRight: 0 }}
                            min={0}
                            step={this.getStepPrecision(quantityPrecision)}
                            decimalSeparator="."
                            precision={quantityPrecision}
                            value={this.state.coinValue}
                            onChange={this.onChangeAmountValue}
                          />
                        <div 
                          style={{ 
                            border: '1px solid #434343', 
                            borderLeft: 0,
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 4,
                            textAlign: 'center'                        ,
                            width: '18%',
                            color: 'rgb(132, 142, 156)',}}>
                          {cripto}
                        </div>
                      </Row>

                      <Row
                        key={"row_create_order_register_amount_slider"}
                        style={{ marginTop: 20, height: 35 }}
                      >
                        <Slider                      
                          min={0}
                          max={100}                      
                          value={typeof coinPercent === "number" ? coinPercent : 0}
                          onChange={this.onChangeAmount}
                          style={{ width: "100%" }}
                          marks={{
                            0: "",
                            25: "",
                            50: "",
                            75: "",
                            100: "",
                          }}
                        />
                      </Row>
                      <Row
                        className="sell-input"
                        key={"row_create_order_register_coinValue"}
                        style={{ marginTop: 20}}
                      >
                        <div 
                          style={{ 
                            border: '1px solid #434343', 
                            borderRight: 5, 
                            paddingLeft: 5,
                            paddingTop: 4,
                            width: '22%',
                            color: 'rgb(132, 142, 156)'}} 
                          > 
                            Total
                          </div>
                          <InputNumber  
                            disabled                      
                            className="input-amount"
                            style={{ background: '#141414', color: '#FFF', width: "60%", borderLeft: 0, borderRight: 0 }}
                            min={0}
                            step={this.getStepPrecision(quantityPrecision)}
                            decimalSeparator="."
                            value={convertValuesCoins(this.state.coinValue * this.state.coinPrice)}                         
                          />
                        <div 
                          style={{ 
                            border: '1px solid #434343', 
                            borderLeft: 0,
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 4,
                            textAlign: 'center'                        ,
                            width: '18%',
                            color: 'rgb(132, 142, 156)',}}>
                          {fiat}
                        </div>
                      </Row>
                      <Row
                        key={"rol_create_order_register_submit"}
                        style={{ marginTop: 20, marginBottom: 9 }}
                      >
                        <Button
                          type="text"
                          style={{
                            width: "100%",
                            height: '42px',
                            backgroundColor:
                              this.state.operationType === OP_BUY
                                ? "#215e2c"
                                : "#c84040",
                            fontWeight: 500
                          }}
                          onClick={this.buySell}
                        >
                          {i18next.t(
                            `operations.label.${this.state.operationType === OP_BUY
                              ? "buy_param"
                              : "sell_param"
                            }`,
                            {
                              coin: `${cripto}`,
                            }
                          )}
                        </Button>
                      </Row>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Spin>        
    );
  }
}

const mapStateToProps = (state) => ({
  listAccount: state.exchanges.listAccount,
  allExchange: state.exchangeList.allExchange,
  allSymbol: state.coin.allSymbol,
  balance: state.wallet.balance,
  bookBuy: state.orderBook.bookBuy,
  bookSell: state.orderBook.bookSell,
  currentValueCoin: state.orderBook.currentValueCoin,
  enabledOrderList: state.operations.enabledOrderList,
  disabledOrderList: state.operations.disabledOrderList,
  order: state.operations.order,
});

const mapDispatchToProps = (dispatch) => {
  return {
    findAllExchangeAsync: () => dispatch(findAllExchangeAsync()),
    findAllSymbolAsync: (exchangeName) =>
      dispatch(findAllSymbolAsync(exchangeName)),
    findBalanceAsync: (exchangeName, symbol) =>
      dispatch(findBalanceAsync(exchangeName, symbol)),
    stopBalanceSearchInterval: () => dispatch(stopBalanceSearchInterval()),
    findBookAsync: (
      exchangeName,
      symbol,
      pricePrecision,
      amountPrecision,
      totalPrecision
    ) =>
      dispatch(
        findBookAsync(
          exchangeName,
          symbol,
          pricePrecision,
          amountPrecision,
          totalPrecision
        )
      ),
    stopBookSearchInterval: () => dispatch(stopBookSearchInterval()),
    findAllEnabledOrderListAsync: (exchangeName) =>
      dispatch(findAllEnabledOrderListAsync(exchangeName)),
    findAllDisabledOrderListAsync: (exchangeName) =>
      dispatch(findAllDisabledOrderListAsync(exchangeName)),
    newOrderAsync: (exchangeName, order) =>
      dispatch(newOrderAsync(exchangeName, order)),
    cancelOrderAsync: (exchangeName, partOrder) =>
      dispatch(cancelOrderAsync(exchangeName, partOrder)),
    stopOrderListSearchInterval: () => dispatch(stopOrderListSearchInterval()),
    findCurrentPriceCoinAsync: (symbol) => dispatch(findCurrentPriceCoinAsync(symbol)),
    getAccountAsync: () => dispatch(getAccountAsync()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Operations);
