import React from "react";
import { connect } from "react-redux";
import { Steps, Button, Form } from 'antd';
import i18next from "i18next";
import { createConfigPackageBotAsync,
  startBotAsync,
  editConfigPackageBotAsync,
  editBotAsync,
  findBotAsync,
  simulatePgProfitAsync,
  changeBot,
  changeProfitPgStep,
  updatePgSimulateResults,
  updatePlusSimulateResults,
  updatePackageSimulateResults,
  clearSettings,
  updateLoading,
  updateValueParam,
  changeCurrentStepRegister,
  simulatePackageAsync,
  simulatePlusProfitAsync
} from "../robotSlice";

import { BotOne, FieldsDefault, BotProfit, BotProfitPlus, BotPackages, BotProfitPG } from './components/robotCreateType';
import { findAllBotTypeAsync } from "../../../shared/botParamSlice";
import { validateNextStep } from './utils/validateFields';
import { registerDefaultBot } from './utils/persistData';
import { RobotCreateFooter } from './components/robotCreateFooter';

const layout = {
  labelCol: {
    span: 9,
    offset: 0,
  },
};

const mapBotComponentsSteps = {
  0: FieldsDefault,
  1: BotOne,
  2: BotPackages,
  3: BotProfit,
  4: BotProfitPlus,
  7: BotProfitPG
}

const { Step } = Steps;

class RobotCreate extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef(); 
  }

  componentDidMount() {
    this.props.findAllBotTypeAsync(this.props.exchangeName); 
  }

  componentDidUpdate() {    
    if (this.props.currentBot === 0) {
      this.formRef.current.resetFields();      
    }
  }

  onClose(props) {
    props.closeModalBot();
  }

  onFinish = (valueParam) => {
    this.props.updateValueParam(valueParam);
    this.props.updateLoading(true);
    this.props.updatePgSimulateResults([]);
    this.props.changeBot(valueParam.type);
    registerDefaultBot(this.props, valueParam, this.onClose);
  }

  onChangeTypeBot = (value) => {
      this.props.updateValueParam([]);
      this.props.updatePgSimulateResults([]);
      this.props.changeBot(value);
      this.props.changeProfitPgStep(1);      
      this.props.changeCurrentStepRegister(0);
      this.formRef.current.resetFields();
      this.formRef.current.setFieldsValue({type: value,});
  }

  handleProfitPgGoStep = (step) => {
    this.props.updateLoading(false);
    this.props.changeProfitPgStep(step);    
  }

  next = (goStep = true) => {
    if (validateNextStep(this.props, this.formRef)) {
      this.formRef.current.submit();          
      return false;
    } else if (goStep) {
      this.props.changeCurrentStepRegister(this.props.currentStepRegister + 1);
      if (this.props.currentBot === 7 || this.props.currentBot === 2 || this.props.currentBot === 4) {
        if (this.props.currentStepRegister === 1) {
          this.props.changeProfitPgStep(3);  
        }
      }
      return false;
    }
    return true;
  };

  prev = () => {
    this.props.changeCurrentStepRegister(this.props.currentStepRegister - 1);
    if (this.props.currentBot === 7 || this.props.currentBot === 2 || this.props.currentBot === 4) {
      if (this.props.currentStepRegister === 1 || this.props.currentStepRegister === 2) {
        this.props.changeProfitPgStep(2);
      }
    }
  };

  render() {
    const RobotFieldsDefault = mapBotComponentsSteps[0]; 
    const RobotFieldsSteps = mapBotComponentsSteps[this.props.currentBot]; 

    const steps = [
    { 
      id: 1,
      title: i18next.t("trades.view.columns_table_details.investment"),
    },
    { 
      id: 2,
      title: i18next.t("button.order"), 
    },
    { 
      id: 3,
      title: i18next.t("portfolio.operations.tableResume.title"), 
    },
   ]; 

    return (
      <div>
         <Form             
            {...layout}
            ref={this.formRef}
            name="control-ref"
            onFinish={this.onFinish}
            size="small"
          >
            <RobotFieldsDefault
              props={this.props} 
              onChangeTypeBot={this.onChangeTypeBot}
              refFields={this.formRef}
            />
            {
              this.props.currentBot !== 0 ? 
              <div style={{ paddingTop: 10}}>
                <Steps current={this.props.currentStepRegister}>
                  {steps.map(item => (
                    <Step key={item.id} title={item.title}/>
                  ))}
                </Steps>
                <div className="steps-content">
                  <RobotFieldsSteps 
                    props={this.props} 
                    refFields={this.formRef}
                  />  
                </div> 
                {
                  this.props.currentBot === 1 ||
                  this.props.currentBot === 3 ?
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      {this.props.currentStepRegister > 0 && (
                        <Button style={{ margin: '0 8px', marginTop: 15 }} onClick={() => this.prev()}>
                           {i18next.t("button.back")}
                        </Button>
                      )}
                      {this.props.currentStepRegister < steps.length - 1 && (
                        <Button style={{ marginTop: 15}} type="primary" onClick={() => this.next()}>
                          {i18next.t("button.nextStep")}
                        </Button>
                      )}
                      {this.props.currentStepRegister === steps.length - 1 && (
                        <Button style={{ marginTop: 15}} type="primary" htmlType="submit">
                          {i18next.t("button.save")}
                        </Button>
                      )}
                    </div> 
                  :
                    <div style={{marginTop: 18}}>
                      <RobotCreateFooter
                        props={this.props} 
                        onClose={this.onClose} 
                        handleProfitPgGoStep={this.handleProfitPgGoStep}
                        refFields={this.formRef}
                        next={this.next}
                        prev={this.prev} />
                    </div>                     
                }                         
              </div> :
              <> </>
            }
          </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allSymbol: state.coin.allSymbol,
  botTypeList: state.botParam.botTypeList,
  currentBot: state.robotSlice.currentBot,
  profitPgStep: state.robotSlice.profitPgStep,
  pgSimulateResults: state.robotSlice.pgSimulateResults,
  packageSimulateResults: state.robotSlice.packageSimulateResults,
  plusSimulateResults: state.robotSlice.plusSimulateResults,
  loading: state.robotSlice.loading,
  valueParam: state.robotSlice.valueParam,
  currentStepRegister: state.robotSlice.currentStepRegister,
});

const mapDispatchToProps = (dispatch) => {
  return {
    findAllBotTypeAsync: (exchangeName) => dispatch(findAllBotTypeAsync(exchangeName)),
    createConfigPackageBotAsync: (exchangeName, robot) => dispatch(createConfigPackageBotAsync(exchangeName, robot)),
    startBotAsync: (exchangeName, robot) => dispatch(startBotAsync(exchangeName, robot)),
    editConfigPackageBotAsync: (exchangeName, robot) => dispatch(editConfigPackageBotAsync(exchangeName, robot)),
    editBotAsync: (exchangeName, robot) => dispatch(editBotAsync(exchangeName, robot)),
    findBotAsync: (exchange, id, type) => dispatch(findBotAsync(exchange, id, type)),
    simulatePgProfitAsync: (exchange, robot) => dispatch(simulatePgProfitAsync(exchange, robot)),
    simulatePackageAsync: (exchange, robot) => dispatch(simulatePackageAsync(exchange, robot)),
    simulatePlusProfitAsync: (exchange, robot) => dispatch(simulatePlusProfitAsync(exchange, robot)),
    changeBot: key => dispatch(changeBot(key)),
    changeProfitPgStep: key => dispatch(changeProfitPgStep(key)),
    updateLoading: key => dispatch(updateLoading(key)),
    changeCurrentStepRegister: key => dispatch(changeCurrentStepRegister(key)),
    clearSettings: () => dispatch(clearSettings()),
    updateValueParam: (params) => dispatch(updateValueParam(params)),
    updatePgSimulateResults: (results) => dispatch(updatePgSimulateResults(results)),
    updatePlusSimulateResults: (results) => dispatch(updatePlusSimulateResults(results)),
    updatePackageSimulateResults: (results) => dispatch(updatePackageSimulateResults(results)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RobotCreate);