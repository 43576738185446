import { createSlice } from "@reduxjs/toolkit";
import {
  getPackageDetails,
  getPackageSetupDetails,
  getProfitSetupDetails,
  getProfitDetails,
  getProfitPlusDetails,
  getProfitPlusSetupDetails,
  getProfitPgDetails,
  getProfitPgSetupDetails,
  getBot,
  getBotDetails,
  getOneDetails,
  getOneSetupDetails,
  getBotPartialReinvest,
} from "../../../../services/apiRobotViewerService";

export const robotViewerSlice = createSlice({
  name: "robotViewer",
  initialState: {
    packageDetails: {},
    enablePackageDetails: 0,
    packageSetupDetails: {},
    oneDetails: {},
    enableOneDetails: 0,
    oneSetupDetails: {},
    profitDetails: {},
    enableProfitDetails: 0,
    profitSetupDetails: {},
    profitPlusDetails: {},
    enableProfitPlusDetails: 0,
    profitPlusSetupDetails: {},
    profitPgDetails: {},
    enabledProfitPgDetails: 0,
    profitPgSetupDetails: {},
    reinvestment: {},
    partialReinvestment: {},
    childVisible: false,
    reinvestingDetailsVisible: false,
  },
  reducers: {
    addPackageDetails: (state, action) => {
      state.packageDetails = action.payload;
    },
    addPackageDetailsInterval: (state, action) => {
      state.enablePackageDetails = action.payload;
    },
    stopPackageDetailsInterval: (state) => {
      clearInterval(state.enablePackageDetails);  
    },    
    addPackageSetupDetails: (state, action) => {
      state.packageSetupDetails = action.payload;
    },
    addProfitDetails: (state, action) => {
      state.profitDetails = action.payload;
    },
    addProfitDetailsInterval: (state, action) => {
      state.enableProfitDetails = action.payload;
    },
    stopProfitDetailsInterval: (state) => {
      clearInterval(state.enableProfitDetails);
    },
    addProfitSetupDetails: (state, action) => {
      state.profitSetupDetails = action.payload;
    },
    addProfitPlusDetails: (state, action) => {
      state.profitPlusDetails = action.payload;
    },
    addProfitPlusDetailsInterval: (state, action) => {
      state.enableProfitPlusDetails = action.payload;
    },
    stopProfitPlusDetailsInterval: (state) => {
      clearInterval(state.enableProfitPlusDetails);
    },
    addProfitPlusSetupDetails: (state, action) => {
      state.profitPlusSetupDetails = action.payload;
    },
    addProfitPgDetails: (state, action) => {
      state.profitPgDetails = action.payload;
    },
    addProfitPgDetailsInterval: (state, action) => {
      state.enabledProfitPgDetails = action.payload;
    },
    stopProfitPgDetailsInterval: (state) => {
      clearInterval(state.enabledProfitPgDetails);
    },
    addProfitPgSetupDetails: (state, action) => {
      state.profitPgSetupDetails = action.payload;
    },
    addReinvestment: (state, action) => {
      state.reinvestment = action.payload;
    },
    addPartialReinvestment: (state, action) => {
      state.partialReinvestment = action.payload;
    },
    addOneDetails: (state, action) => {
      state.oneDetails = action.payload;
    },
    addOneDetailsInterval: (state, action) => {
      state.enableOneDetails = action.payload;
    },
    stopOneDetailsInterval: (state) => {
      clearInterval(state.enableOneDetails);
    },
    addOneSetupDetails: (state, action) => {
      state.oneSetupDetails = action.payload;
    },
    showChild: (state, action) => {
      state.childVisible = action.payload;
    },
    showReinvestingDetails: (state, action) => {
      state.reinvestingDetailsVisible = action.payload;
    },
    addBotDetails: (state, action) => {
      state.botDetails = action.payload
    },
    addStatusLoading: (state, action) => {
      state.statusLoading = action.payload;
    }
  },
});

const {
  addPackageDetails,
  addPackageDetailsInterval,
  stopPackageDetailsInterval,
  addPackageSetupDetails,
  addProfitDetails,
  addProfitDetailsInterval,  
  stopProfitDetailsInterval,
  addProfitSetupDetails,
  addProfitPlusDetails,
  addProfitPlusDetailsInterval,
  stopProfitPlusDetailsInterval,
  addProfitPlusSetupDetails,
  addProfitPgDetails,
  addProfitPgDetailsInterval,
  stopProfitPgDetailsInterval,
  addProfitPgSetupDetails,
  addOneDetails,
  addOneDetailsInterval,
  stopOneDetailsInterval,
  addOneSetupDetails,
  addReinvestment,
  addPartialReinvestment,
  addBotDetails,
} = robotViewerSlice.actions;
export const { showChild, showReinvestingDetails } = robotViewerSlice.actions;

const findBotDetails = (exchangeName, robotId, robotType) => (dispatch) => {
  return getBotDetails(exchangeName, robotId, robotType).then((record) => {
    dispatch(addBotDetails(record));
  });
};

export const findPackageDetailsAsync = (exchangeName, robotId) => (dispatch) => {
  return new Promise((resolve) => {
    let running = false;     
    dispatch(addReinvestment({}))
    dispatch(addPartialReinvestment({}))

    getPackageDetails(exchangeName, robotId).then((recordList) => {
      resolve();
      dispatch(addPackageDetails(recordList));  
      dispatch(findBotDetails(exchangeName, robotId, recordList.bot.type))      
    })
    
    dispatch(addProfitDetailsInterval(
    setInterval(() => {
      if (!running) {
        running = true;
          getPackageDetails(exchangeName, robotId).then((recordList) => {            
            dispatch(addPackageDetails(recordList));              
            running = false;          
          })  
        }
      }, 2000)
    ))
  });
};

export const findPackageSetupDetailsAsync = (exchangeName, robot, robotFather) => (dispatch) => {
  return getPackageSetupDetails(exchangeName, robot.id).then((robotOperations) => {
    dispatch(addPackageSetupDetails({robot, robotOperations, robotFather}));
  });
};

export const findProfitDetailsAsync = (exchangeName, robotId) => (dispatch) => {
  return new Promise((resolve) => {
    let running = false;     
    dispatch(addReinvestment({}))
    dispatch(addPartialReinvestment({}))

    getProfitDetails(exchangeName, robotId).then((recordList) => {
      dispatch(addProfitDetails(recordList));
      dispatch(findBotDetails(exchangeName, robotId, recordList.bot.type))
      resolve();
    })

    dispatch(addPackageDetailsInterval(
    setInterval(() => {
      if (!running) {
        running = true;
        getProfitDetails(exchangeName, robotId).then((recordList) => {          
          dispatch(addProfitDetails(recordList));          
          running = false;          
        })  
      }
    }, 2000)
    ))
  });
};

export const findProfitSetupDetailsAsync = (exchangeName, robot, robotFather) => (dispatch) => {
  dispatch(addReinvestment({}));
  dispatch(addPartialReinvestment({}));
  return getProfitSetupDetails(exchangeName, robot.id).then((robotOperations) => {
    dispatch(addProfitSetupDetails({robot, robotOperations, robotFather}));
  });
};

export const findProfitPlusDetailsAsync = (exchangeName, robotId) => (dispatch) => {
  return new Promise((resolve) => {
    let running = false;
    dispatch(addReinvestment({}));
    dispatch(addPartialReinvestment({}));

    getProfitPlusDetails(exchangeName, robotId).then((recordList) => {                
      dispatch(addProfitPlusDetails(recordList));
      dispatch(findBotDetails(exchangeName, robotId, recordList.bot.type))
      resolve();
    })

    dispatch(addProfitPlusDetailsInterval(
    setInterval(() => {
      if (!running) {
        running = true;
        getProfitPlusDetails(exchangeName, robotId).then((recordList) => {                    
          dispatch(addProfitPlusDetails(recordList));                    
          running = false;
        })  
      }
    }, 2000)
    ))    
  });
};

export const findProfitPlusSetupDetailsAsync = (exchangeName, robot, robotFather) => (
  dispatch
) => {
  return getProfitPlusSetupDetails(exchangeName, robot.id).then((robotOperations) => {
    dispatch(addProfitPlusSetupDetails({robot, robotOperations, robotFather}));
  });
};

export const findProfitPgDetailsAsync = (exchangeName, robotId) => (dispatch) => {
  return new Promise((resolve) => {
    let running = false;
    dispatch(addReinvestment({}));
    dispatch(addPartialReinvestment({}));

    getProfitPgDetails(exchangeName, robotId).then((recordList) => {                
      dispatch(addProfitPgDetails(recordList));
      dispatch(findBotDetails(exchangeName, robotId, recordList.bot.type))    
      resolve();
    })    
  
    dispatch(addProfitPgDetailsInterval(
      setInterval(() => {
      if (!running) {
        running = true;
        getProfitPgDetails(exchangeName, robotId).then((recordList) => {                    
          dispatch(addProfitPgDetails(recordList));                    
          running = false;
        })  
      }
    }, 2000)    
    ))    
  });
};

export const findProfitPgSetupDetailsAsync = (exchangeName, robot, robotFather) => (
  dispatch
) => {
  return getProfitPgSetupDetails(exchangeName, robot.id).then((robotOperations) => {
    dispatch(addProfitPgSetupDetails({robot, robotOperations, robotFather}));
  });
};

export const findReinvestmentAsync = (exchangeName, robotId, robotType) => (
  dispatch
) => {
  return getBot(exchangeName, robotId, robotType, 5).then((record) => {
    dispatch(addReinvestment(record));
  });
};

export const findOneDetailsAsync = (exchangeName, robotId) => (dispatch) => {
  return new Promise((resolve) => {
    let running = false;
    dispatch(addReinvestment({}));
    dispatch(addPartialReinvestment({}));

    getOneDetails(exchangeName, robotId).then((recordList) => {      
      dispatch(addOneDetails(recordList));
      dispatch(findBotDetails(exchangeName, robotId, recordList.bot.type))   
      resolve();
    })

    dispatch(addOneDetailsInterval(
    setInterval(() => {
      if (!running) {
        running = true;
        getOneDetails(exchangeName, robotId).then((recordList) => {          
          dispatch(addOneDetails(recordList));
          running = false;
        })  
      }
    }, 2000)
    ))
  });
};

export const findOneSetupDetailsAsync = (exchangeName, robot, robotFather) => (
  dispatch
) => {
  return getOneSetupDetails(exchangeName, robot.id).then((robotOperations) => {
    dispatch(addOneSetupDetails({robot, robotOperations, robotFather}));
  });
};

export const findPartialReinvestmentAsync = (
  exchangeName,
  robotId,
  setupId,
  robotType
) => (dispatch) => {
  return getBotPartialReinvest(exchangeName, robotId, setupId, robotType).then(
    (record) => {
      dispatch(addPartialReinvestment(record));
      dispatch(showReinvestingDetails(true));
      
    }
  );
};

export const stopBotDetailInterval = () => (dispatch) => {
  dispatch(stopProfitDetailsInterval());
  dispatch(stopPackageDetailsInterval());
  dispatch(stopProfitPlusDetailsInterval());
  dispatch(stopProfitPgDetailsInterval());
  dispatch(stopOneDetailsInterval());  
};

export default robotViewerSlice.reducer;
