import axios from "axios";
import config from "../config"

const urlApi = config.URL_API;
const urlLogin = config.URL_LOGIN;
export const wsUrlApi = config.WS_URL_API;

const axiosInstance = axios.create({
  baseURL: urlApi + "/",
  headers: {
    "Content-Type": "application/json",
    "Set-Cookie": "HttpOnly;Secure;SameSite=Strict",
  },
});
 
axiosInstance.interceptors.request.use((req) => {
  const token = localStorage.getItem("x-access-token");
  if (token) {
    req.headers["x-access-token"] = token;
    return req;
  }
  window.location.href = urlLogin;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      !!error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = urlLogin;
    }
    return Promise.reject(error);
  }
);

const api = {
  get(endpoint, params) {
    if (arguments.length === 1) {
      return axiosInstance.get(endpoint);
    } else if (arguments.length === 2) {
      return axiosInstance.get(endpoint, { params });
    }
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint, params) {
    if (arguments.length === 1) {
      return axiosInstance.delete(endpoint);
    } else if (arguments.length === 2) {
      return axiosInstance.delete(endpoint, { params });
    }
  },
};

export default api;