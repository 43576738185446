import api from "./api";

export function findAllBotType(exchange) {
  return new Promise((resolve) => {
    api.get("/exchange/getBotTypeList", { exchange }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}
