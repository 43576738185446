import React from "react";
import i18next from "i18next";
import { Button, Tooltip, Tag, Space } from "antd";

import {
  EditOutlined,
  FileTextOutlined,
  StopOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  AlertOutlined,
} from "@ant-design/icons";

import { getUTCDate, getUTCTime } from "../../shared/utils/dateUtility";
import {
  convertValuesCoins,
  convertMinPrecisionCoins,
} from "../../shared/utils/convertValuesCoins";

const loadConvertCoin = (record) => {
  if (record) {
    const value = record.split(" ");
    const converted = convertValuesCoins(value[0]) + " " + value[1];
    return (
      <div className="earnFlash">
        <span>{converted}</span>
      </div>
    );
  } else {
    return 0;
  }
};

const loadPrecisionCoin = (record, state) => {
  if (record && record.limits) {
    const symbol = state.allSymbol.find(
      (symbol) => symbol.name === record.symbol
    );
    return `${convertMinPrecisionCoins(
      record.limits.limitPrice,
      symbol.price_precision
    )} - ${convertMinPrecisionCoins(
      record.limits.maxPrice,
      symbol.price_precision
    )} - ${convertMinPrecisionCoins(
      record.limits.minPrice,
      symbol.price_precision
    )}`;
  }
};

const stringSorter = (a, b) => {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
};

const TableBotsColumns = (method, state) => {
  const statusTableBot = {
    PROCESSING: {
      caption: `${i18next.t("trades.status.PROCESSING")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    STOPPING: {
      caption: `${i18next.t("trades.status.STOPPING")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    CANCELING: {
      caption: `${i18next.t("trades.status.CANCELING")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    REINVESTING: {
      caption: `${i18next.t("trades.status.REINVESTING")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    WAITING_LIMIT: {
      caption: `${i18next.t("trades.status.WAITING_LIMIT")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    WATCHING_MARKET: {
      caption: `${i18next.t("trades.status.WATCHING_MARKET")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    STARTING: {
      caption: `${i18next.t("trades.status.STARTING")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    ERROR_BUY: {
      caption: `${i18next.t("trades.status.ERROR_BUY")}`,
      color: "default",
      icon: <SyncOutlined spin />,
    },
    CANCELED: {
      caption: `${i18next.t("trades.status.CANCELED")}`,
      color: "default",
      icon: <CloseCircleOutlined />,
    },
    STOPPED: {
      caption: `${i18next.t("trades.status.STOPPED")}`,
      color: "default",
      icon: <StopOutlined />,
    },
    REINVESTED: {
      caption: `${i18next.t("trades.status.REINVESTED")}`,
      color: "default",
      icon: <SyncOutlined />,
    },
    ERROR: {
      caption: `${i18next.t("trades.status.ERROR")}`,
      color: "default",
      icon: <AlertOutlined />,
    },
  };

  const botColumns = [
    {
      title: `${i18next.t("trades.status.caption")}`,
      dataIndex: "status",
      key: "status",
      width: "80px",
      render: (_, record) => {
        let status;
        if (record && record.status in statusTableBot) {
          status = record.status;
        } else {
          status = "ERROR";
        }
        const caption = statusTableBot[status].caption;
        const color = statusTableBot[status].color;
        const icon = statusTableBot[status].icon;
        return (
          <Tag className="tag-status-bot" icon={icon} color={color}>
            {caption}
          </Tag>
        );
      },
    },
    {
      title: `${i18next.t("trades.title.bot")}`,
      dataIndex: "bot",
      width: "88px",
      key: "bot",
      sorter: (a, b) => stringSorter(a.bot.name, b.bot.name),
      render: (_, record) => `${record.bot.name}`,
    },
    {
      title: `${i18next.t("trades.title.createdAt")}`,
      dataIndex: "createdAt",
      key: "createdAt",
      width: "128px",
      sorter: (a, b) =>
        stringSorter(getUTCDate(a.createdAt), getUTCDate(b.createdAt)),
      render: (_, record) => `${getUTCDate(record.createdAt)}`,
    },
    {
      title: `${i18next.t("trades.title.symbol")}`,
      dataIndex: "symbol",
      key: "symbol",
      width: "88px",
      sorter: (a, b) => stringSorter(a.symbol, b.symbol),
    },

    {
      title: `${i18next.t("trades.title.investment")}`,
      dataIndex: "investment",
      key: "investment",
      width: "180px",
      sorter: (a, b) => stringSorter(a.investment, b.investment),
      render: (_, record) => loadConvertCoin(record.investment),
    },
    {
      title: `${i18next.t("trades.title.earning")}`,
      dataIndex: "earning",
      key: "earning",
      width: "180px",
      sorter: (a, b) => stringSorter(a.earning, b.earning),
      render: (_, record) => loadConvertCoin(record.earning),
    },
    {
      title: `${i18next.t("trades.title.time")}`,
      dataIndex: "time",
      key: "time",
      width: "88px",
      render: (_, record) => {
        const time =
          record && record.createdAt
            ? getUTCTime(record.createdAt, record.updatedAt)
            : "";
        return time;
      },
    },
    // {
    //   title: `${i18next.t("trades.title.exchange")}`,
    //   dataIndex: "exchange",
    //   key: "exchange",
    //   width: "80px",
    //   sorter: (a, b) => stringSorter(a.exchange, b.exchange),
    // },
    {
      title: `${i18next.t("trades.title.operations")}`,
      dataIndex: "operations",
      key: "operations",
      width: "80px",
      sorter: (a, b) => a.operations - b.operations,
    },
  ];

  const disabledBotColumns = [
    ...botColumns,
    {
      title: `${i18next.t("trades.title.action")}`,
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Tooltip mouseEnterDelay="1" title={i18next.t("trades.hint.view")}>
          <Button
            ghost
            className="table-btn"
            onClick={() => {
              window.TICOD.tradesOpenRobotViewer(record);
            }}
          >
            <FileTextOutlined />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const enabledBotColumns = [
    ...botColumns,
    {
      title: `${i18next.t("trades.title.action")}`,
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "90px",
      render: (_, record) => (
        <Space size={3}>
          {record.status === "SELLING_MARKET" ||
          record.status === "CANCELING" ||
          record.status === "REINVESTING" ? (
            <Button ghost className="table-btn" disabled>
              <EditOutlined />
            </Button>
          ) : (
            <Tooltip mouseEnterDelay="1" title={i18next.t("trades.hint.edit")}>
              <Button
                ghost
                className="table-btn"
                onClick={() => {
                  window.TICOD.tradesOpenRobotRegister(record);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          )}
          <Tooltip mouseEnterDelay="1" title={i18next.t("trades.hint.view")}>
            <Button
              ghost
              className="table-btn"
              onClick={() => {
                window.TICOD.tradesOpenRobotViewer(record);
              }}
            >
              <FileTextOutlined />
            </Button>
          </Tooltip>
          <Tooltip mouseEnterDelay="1" title={i18next.t("trades.hint.stop")}>
            <Button
              ghost
              className="table-btn"
              onClick={() => {
                window.TICOD.tradesStopRobot(record);
              }}
            >
              <StopOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  enabledBotColumns.splice(6, 0, {
    title: `${i18next.t("trades.title.limits")}`,
    dataIndex: "limits",
    key: "limits",
    width: "270px",
    render: (_, record) => loadPrecisionCoin(record, state),
  });

  const columnsBuySell = [
    {
      title: `${i18next.t("trades.label.price")}`,
      dataIndex: "price",
      key: "price",
      width: "30%",
    },
    {
      title: `${i18next.t("trades.label.amount")}`,
      dataIndex: "amount",
      key: "amount",
      width: "30%",
      align: "right",
    },
    {
      title: `${i18next.t("trades.label.total")}`,
      dataIndex: "total",
      key: "total",
      width: "40%",
      align: "right",
      render: (_, record) =>
        convertValuesCoins(
          record.total,
          state.coinSelected ? state.coinSelected.price_precision : 8
        ),
    },
  ];

  switch (method) {
    case "disabledBotColumns":
      return disabledBotColumns;
    case "enabledBotColumns":
      return enabledBotColumns;
    case "columnsBuySell":
      return columnsBuySell;
    default:
      break;
  }
};

export default TableBotsColumns;
