import { useEffect, useState } from "react";
import "./tables.css"
import { Table, Card, Row, Col } from "antd";
import { tableMainColumns } from "./columnsConfig"
import { ExportButtonMain } from "./exportButton";
import { convertValuesCoins } from '../../../shared/utils/convertValuesCoins';
import i18next from "i18next";

let globalProps = {};


 
const TableMain = (props) => {
  const [opacity, setOpacity] = useState(0);
  const [columns, setColumns] = useState(false);

  const columnsTotalResume = [
    {
      title: i18next.t("trades.title.symbol"),
      dataIndex: 'coin',
      key: 'coin',
      render: (record) => (
        <div style={{ fontSize: 16 }}>
          {record}
        </div>
      )
    },
    {
      title: i18next.t("trades.title.earning"),
      dataIndex: 'earning',
      key: 'earning',
      render: (record) => (
        <div style={{ fontSize: 16 }}>
          {convertValuesCoins(record)}
        </div>
      )
    },
  ]

  useEffect(() => {
    globalProps = props.data;
    globalProps.changeLoading(false);
    setColumns(tableMainColumns(globalProps));
    const interval = setInterval(() => setOpacity(1), 100)
    return () => clearInterval(interval);
  }, [props.data])

  return (
    <div className="opContent" style={{ opacity: opacity, position: 'relative' }}>
      <Card
        size="small"
        bordered={false}
        style={{ background: '#141414', borderRadius: 8, marginBottom: 12, height: 55 }}
      >
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col span={12}>
            <div>
              <ExportButtonMain {...props} />
            </div>
          </Col>
        </Row>
      </Card>

      <Row>
        <Col span={16}>
          <Card
            size="small"
            bordered={false}
            style={{ background: '#141414', borderRadius: 8 }}
          >
            <Table
              className="opTable"
              columns={columns}
              dataSource={props.data.operationsMain}
              size="small"
              footer={() => `Total: ${props.data.operationsMain.length}`}
              scroll={{ x: "none", y: "370px" }}
              pagination={{ pageSize: 20 }}
              rowClassName={() => "rowClass"}
            />
          </Card>
        </Col>
        <Col span={8} >
          <Card
            size="small"
            bordered={false}
            style={{ background: '#141414', borderRadius: 8, marginLeft: 12, height: '100%'}}
          >
            <Table
              className="opTable"
              columns={columnsTotalResume}
              dataSource={props.data.operationsMainTotal}
              size="small"
              footer={() => `Total: ${props.data.operationsMainTotal.length}`}
              scroll={{ x: "none", y: "370px" }}
              pagination={false}
              rowClassName={() => "rowClass"}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default TableMain;