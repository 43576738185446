import i18next from "i18next";
import "../robotStop.css";
import {
  Button,
  Row,
  Col,
  InputNumber,
  Form,
  Spin,
  Card,
  Skeleton,
  Input
} from "antd";

export function DescriptionModal(formRef, props, state, onProfitChange, onFinishStop) {  
  return (    
  <div style={{ minHeight: 190 }}>
    <Form
      ref={formRef}
      name="control-ref"
      layout="inline"
      onFinish={(valueParam) => {
        onFinishStop(valueParam)
      }}
    >          
      <div className="header-format-stop">
        <Form.Item 
            label={i18next.t("trades.register.label.symbol")}
            className="format-stop">
          <Input disabled={true} value={props.robot.symbol}/> 
        </Form.Item>
      </div>

      <div className="header-format-stop" style={{display: 'flex', alignItems: 'flex-end', width: 466, justifyContent: 'space-between'}}>
        <Form.Item
          className="format-stop"
          name="profit"
          label={i18next.t("trades.stop.reinvest.market_correction")}
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={props.profit || null}
        >
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            max={100}
            step={0.1}
            decimalSeparator="."
            precision={2}
            onChange={onProfitChange}
          />
        </Form.Item>            
        <Button
          type="primary"
          htmlType="submit"
          loading={state.calculating}
        >
          {i18next.t("trades.stop.reinvest.calculate")}
        </Button>
      </div>
    </Form>          

      <div style={{ paddingTop: 21 }}>
        <Row>
          <Col>
            <Spin
              spinning={state.calculating}
              tip={i18next.t("trades.stop.reinvest.calculating")}
            >
              <Card
                size="small"
                title={i18next.t("trades.stop.reinvest.buy")}
                style={{ width: 200 }}
              >
                {props.reinvestInfo && props.reinvestInfo.buy ? (
                  <>
                    <Row>
                      <Col span={12}>
                        {i18next.t("trades.stop.reinvest.amount")}
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        {props.reinvestInfo.buy.quantity}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t("trades.stop.reinvest.price")}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.reinvestInfo.buy.price}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t("trades.stop.reinvest.value")}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.reinvestInfo.buy.value}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Skeleton title={false} paragraph={{ rows: 2 }} />
                )}
              </Card>
            </Spin>
          </Col>
          <Col style={{ alignSelf: "center", fontSize: 21, margin: 9 }}>+</Col>
          <Col>
            <Spin
              spinning={state.calculating}
              tip={i18next.t("trades.stop.reinvest.calculating")}
            >
              <Card
                size="small"
                title={i18next.t("trades.stop.reinvest.investiment")}
                style={{ width: 200 }}
              >
                {props.reinvestInfo && props.reinvestInfo.bought ? (
                  <>
                    <Row>
                      <Col span={12}>
                        {i18next.t("trades.stop.reinvest.amount")}
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        {props.reinvestInfo.bought.quantity}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t("trades.stop.reinvest.price")}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.reinvestInfo.bought.avgPrice}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t("trades.stop.reinvest.value")}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.reinvestInfo.bought.value}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Skeleton title={false} paragraph={{ rows: 2 }} />
                )}
              </Card>
            </Spin>
          </Col>
          <Col style={{ alignSelf: "center", fontSize: 21, margin: 9 }}>=</Col>
          <Col>
            <Spin
              spinning={state.calculating}
              tip={i18next.t("trades.stop.reinvest.calculating")}
            >
              <Card
                size="small"
                title={i18next.t("trades.stop.reinvest.result")}
                style={{ width: 200 }}
              >
                {props.reinvestInfo && props.reinvestInfo.results ? (
                  <>
                    <Row>
                      <Col span={12}>
                        {i18next.t("trades.stop.reinvest.amount")}
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        {props.reinvestInfo.results.quantity}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t("trades.stop.reinvest.price")}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.reinvestInfo.results.price}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t("trades.stop.reinvest.value")}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.reinvestInfo.results.value}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Skeleton title={false} paragraph={{ rows: 2 }} />
                )}
              </Card>
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  );
}