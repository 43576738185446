import { Card, Spin } from 'antd';
import i18next from "i18next";
import { CardTicod } from './CardTicod';
import {
  FieldInvestment,
  FieldQuantity,
  FieldMinProfit,
  FieldPriceDrop,
  FieldBuyPriceDrop,
  FieldDrop,
  FieldMaxPrice,
  FieldWaitBuyLimit,
  FieldProfit,
  FieldInvestmentTotal 
} from "./robotRegisterFields";

export const fieldsDefault = (params) => {
  return (
    <div style={{ display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '36rem'
      }}>
      <Spin spinning={params.props.currentBot === 0} />
    </div>
  )
}

  const HeaderRobotEditing = (params) => {
    const param = params.props;
  return (    
    <Card      
      size="small"                
      bordered={false}
      className="card-editing"
      style={{ background: '#141414', borderRadius: '8px'}}
    >   
      <div style={{display: 'flex', marginTop: 7}}>
        {
        !param.props.duplicating ? 
        <div style={{display: 'flex', flexDirection: 'column', marginRight: 20}}>
          <h5 style={{ color: '#3392eb'}}>{i18next.t("trades.title.createdAt")}</h5>
          <p style={{ fontWeight: 500 }}>{param.props.packageBot.createdAt}</p>
        </div>
        : <> </>
        }

        <div style={{display: 'flex', flexDirection: 'column', marginRight: 20}}>
          <h5 style={{ color: '#3392eb'}}>{i18next.t("trades.register.label.type")}</h5>
          <p style={{ fontWeight: 500 }}>{param.props.robot.bot.name}</p>
        </div>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <h5 style={{ color: '#3392eb'}}>{i18next.t("trades.register.label.symbol")}</h5>
          <p style={{ fontWeight: 500 }}>{param.props.packageBot.symbol}</p>            
        </div>
      </div>
    </Card>
  )
}


export const botOne = (params) => {
  return (
    <div>
      <HeaderRobotEditing props={params}/>
      <CardTicod title={'main.settings'}>
        <div className="header-steps-editing">
          <FieldProfit props={params.props} state={params.state}/>
          <FieldInvestment props={params.props} state={params.state}/>
        </div>                
      </CardTicod>

      <CardTicod title={'trades.view.columns_table_setup_details.label_compra'}>
        <div className="header-steps-editing">          
          <FieldMaxPrice  props={params.props} state={params.state}/>          
          <FieldWaitBuyLimit props={params.props} state={params.state}/>
        </div>
      </CardTicod>
    </div>
  )
}

export const editBotOne = (formRef, packageBot) => {
  formRef.current.setFieldsValue({
    symbol: packageBot.symbol,
    type: 1,
    investment: packageBot.investment,
    profit: packageBot.profit,    
    maxPrice: packageBot.maxPrice,
    drop: packageBot.drop,
    waitBuyLimit: packageBot.waitBuyLimit,
  });
}

export const botPackage = (params) => {
  return (
    <div>
      <HeaderRobotEditing props={params}/>
      <CardTicod title={'Grade'}>
        <div className="header-steps-editing">
          <FieldQuantity props={params.props} state={params.state}/>
          <FieldDrop props={params.props} state={params.state}/>
        </div>                
      </CardTicod>

      <CardTicod title={'main.settings'}>
        <div className="header-steps-editing">
          <FieldInvestmentTotal props={params.props} state={params.state}/>
          <FieldProfit props={params.props} state={params.state}/>
        </div>                
      </CardTicod>

      <CardTicod title={'trades.view.columns_table_setup_details.label_compra'}>
        <div className="header-steps-editing">
          <FieldMaxPrice props={params.props} state={params.state} />
          <FieldWaitBuyLimit props={params.props} state={params.state} />
        </div>         
      </CardTicod>
    </div>
  )
}

export const editBotPackage = (formRef, packageBot) => {
  formRef.current.setFieldsValue({
    symbol: packageBot.symbol,
    type: 2,
    investment: packageBot.investment,
    profit: packageBot.profit,    
    maxPrice: packageBot.maxPrice,
    quantity: packageBot.quantity,
    drop: packageBot.drop,
    waitBuyLimit: packageBot.waitBuyLimit,
    total:  packageBot.total,
  });
}

export const botProfit = (params) => {
  return (
    <div>
      <HeaderRobotEditing props={params}/>
      <CardTicod title={'main.settings'}>
        <div className="header-steps-editing">
          <FieldMinProfit props={params.props} state={params.state}/>      
          <FieldInvestment props={params.props} state={params.state}/>
        </div>                
      </CardTicod>

      <CardTicod title={'trades.view.columns_table_setup_details.label_compra'}>
        <div className="header-steps-editing">          
          <FieldMaxPrice  props={params.props} state={params.state}/>          
          <FieldWaitBuyLimit props={params.props} state={params.state}/>
        </div>      
      </CardTicod>  


      <CardTicod title={'trades.view.columns_table_setup_details.label_venda'}>
        <div className="header-steps-editing">
          <FieldPriceDrop props={params.props} state={params.state}/>
        </div>                
      </CardTicod>

    </div>
  )
}

export const editBotProfit = (formRef, packageBot) => {
  formRef.current.setFieldsValue({
    symbol: packageBot.symbol,
    type: 3,
    investment: packageBot.investment,
    minProfit: packageBot.minProfit,    
    maxPrice: packageBot.maxPrice,
    drop: packageBot.drop,
    priceDrop: packageBot.priceDrop,
    waitBuyLimit: packageBot.waitBuyLimit,
  });
}

export const botProfitPlus = (params) => {
  return (
    <div>
      <HeaderRobotEditing props={params}/>
      <CardTicod title={'Grade'}>
        <div className="header-steps-editing">
          <FieldQuantity props={params.props} state={params.state}/>
          <FieldDrop props={params.props} state={params.state}/>
        </div>                
      </CardTicod>

      <CardTicod title={'main.settings'}>
        <div className="header-steps-editing">
          <FieldInvestmentTotal props={params.props} state={params.state}/>
          <FieldMinProfit props={params.props} state={params.state}/>
        </div>                
      </CardTicod>

      <CardTicod title={'trades.view.columns_table_setup_details.label_compra'}>
        <div className="header-steps-editing">
          <FieldMaxPrice props={params.props} state={params.state}/>
          <FieldWaitBuyLimit props={params.props} state={params.state} />
        </div>      
        <div className="header-steps-editing">
          <FieldBuyPriceDrop props={params.props} state={params.state}/>          
        </div>            
      </CardTicod>      
      <CardTicod title={'trades.view.columns_table_setup_details.label_venda'}>
        <div className="header-steps-editing">
          <FieldPriceDrop props={params.props} state={params.state}/>
        </div>      
      </CardTicod>
    </div>
  )
}

export const editBotProfitPlus = (formRef, packageBot) => {
  formRef.current.setFieldsValue({
    type: 4,
    symbol: packageBot.symbol,
    maxPrice: packageBot.maxPrice,
    investment: packageBot.investment,
    minProfit: packageBot.minProfit,
    drop: packageBot.drop,
    priceDrop: packageBot.priceDrop,
    buyPriceDrop: packageBot.buyPriceDrop,
    quantity: packageBot.quantity,
    waitBuyLimit: packageBot.waitBuyLimit, 
    total:  packageBot.total,   
  });
}

export const robotRegisterTypeProfitPG = (params) => {
return (
  <div>
    <HeaderRobotEditing props={params}/>
    <CardTicod title={'Grade'}>
      <div className="header-steps-editing">
        <FieldQuantity props={params.props} state={params.state}/>
        <FieldDrop props={params.props} state={params.state}/>
      </div>                
    </CardTicod>

    <CardTicod title={'main.settings'}>
      <div className="header-steps-editing">
        <FieldInvestment props={params.props} state={params.state}/>
        <FieldMinProfit props={params.props} state={params.state}/>
      </div>                
    </CardTicod>

    <CardTicod title={'trades.view.columns_table_setup_details.label_compra'}>
      <div className="header-steps-editing">
        <FieldMaxPrice props={params.props} state={params.state}/>
        <FieldWaitBuyLimit props={params.props} state={params.state}/>
      </div>      
      <div className="header-steps-editing">
        <FieldBuyPriceDrop props={params.props} state={params.state}/>         
      </div>            
    </CardTicod>
    <CardTicod title={'trades.view.columns_table_setup_details.label_venda'}>
      <div className="header-steps-editing">
        <FieldPriceDrop props={params.props} state={params.state}/>        
      </div>      
    </CardTicod>
  </div>
  )
}

export const editBotProfitPG = (formRef, packageBot) => {
  formRef.current.setFieldsValue({
    type: 7,
    symbol: packageBot.symbol,
    maxPrice: packageBot.maxPrice,
    minProfit: packageBot.minProfit,
    drop: packageBot.drop,
    priceDrop: packageBot.priceDrop,
    waitBuyLimit: packageBot.waitBuyLimit,
    buyPriceDrop: packageBot.buyPriceDrop,
    investment: packageBot.investment,
    quantity: packageBot.quantity,    
  });
}

export const registerDefaultBot = (props, valueParam, onCLose) => {
  props.startBotAsync(props.exchangeName, valueParam).then(() => {
    props.updateLoading(false);
    onCLose();
  });
}