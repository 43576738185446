import React from "react";
import "./main.css";
import { changeMenu, changeLoading } from "./mainPortfolioSlice";
import { connect } from "react-redux";

import { Layout, Spin } from 'antd';
// import { MainHeader } from "./components/header";
import { MainSidebar } from "./components/sidebar";
// import { MainBreadcrumb } from "./components/breadcrumb";

import Overview from "../overview/overview";
import Results from "../results/results";
// import Users from "../users";

const mapMenuComponent = {
  overview: Overview,
  results: Results,
  // users: Users
};

class mainPortfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  loadingComponent = () => {

    if (!this.props.loading) {
      return <div></div>
    }

    return (
      <div className="mainLoading">
        <Spin />
      </div>
    )
  }
  
  componentDidMount() {
    this.props.changeLoading(false);
  }

  render() {
    const Loading = this.loadingComponent;

    const ContentMenu = 
      this.props.currentMenu ? 
      mapMenuComponent[this.props.currentMenu] : 
      Results
    ;
   
    const { Content } = Layout;

    return (
      <div>
        <Loading />
        <Layout className="mainLayout">
          <Layout>
            <MainSidebar {...this.props} />
            <Layout className="contentLayout">
              {/* <MainBreadcrumb /> */}
              <Content
                className="mainContent">
                  <ContentMenu {...this.props} />
              </Content>
            </Layout>
          </Layout>
          {/* <MainFooter /> */}
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.mainPortfolio.loading,
  currentMenu: state.mainPortfolio.currentMenu
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeMenu: key => dispatch(changeMenu(key)),
    changeLoading: boo => dispatch(changeLoading(boo))
  };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(mainPortfolio);