import { Component } from "react";
import ApexCharts from "react-apexcharts";
import '../overview.css';

class ActivateBots extends Component {
  constructor(props) {
    super(props);

    this.state = { 
        series: [0],
        options: {
          labels: [''],
          dataLabels: {
            enabled: false 
          },
          tooltip: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    showAlways: false,
                    label: 'Total',
                    fontSize: '22px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    color: '#fff',
                    formatter: function (w) {
                      return 0
                    }
                  }
                }                  
              }
            }
          },

          // plotOptions: {
          //   pie: {
          //     customScale: 0.6
          //   }
          // },
          theme: {
            mode: 'dark',
            palette: 'palette2',
          },        
          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: ['black'] ,
            width: 0.5,
            dashArray: 0,      
          },
          // legend: {
          //   position: 'top',
          //   offsetY: 20,
          //   floating: true,              
          // },
          chart: {
            width: '100%',
            type: 'donut',
            height: 'auto'
          },
          responsive: [{
            breakpoint: 485,
            options: {
              chart: {
                width: 300,                
              },
              legend: {
                position: 'bottom'
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        showAlways: false,
                        label: 'Total',
                        fontSize: '10px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 500,
                        color: '#fff',
                        formatter: function (w) {
                          return props.activeBots?.total
                        }
                      }
                    }                  
                  }
                }
              },                
            }
          }]
        }
      }
    }

    componentDidUpdate() {
      this.updateChart();
    }
  
    updateChart() {
      if (this.props.props.activeBots && this.props.props.activeBots.labels && this.props.props.activeBots.labels.length > 0) {
        if ((this.state.series) !== (this.props.props.activeBots.series)) {
          this.setState({series: this.props.props.activeBots.series});
          this.setState({options: {
            labels: this.props.props.activeBots.labels,
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    total: {
                      formatter: () => this.props.props.activeBots.total
                    }
                  }
                }
              }
            },
            responsive: [{
              options: {
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        total: {
                          formatter: () => this.props.props.activeBots.total
                        }
                      }
                    }
                  }
                }
              }
            }]
          }});
        }
      }
    }    

    render() {      
      return (      
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <ApexCharts
            options={this.state.options}
            series={this.state.series}
            type="donut"        
            style={{ height: 250, width: 400}}
          />
        </div>       
      )      
    };  
};

export default ActivateBots;

