import { Card } from 'antd';
import i18next from "i18next";

const CardTicod = ({children, title, marginRight = 0, marginTop = 0, height = '100%'}) => {
  return (
    <Card
      title={i18next.t(title)}
      size="small"                
      bordered={false}      
      style={{ 
          background: '#141414', 
          borderRadius: 8,           
          marginRight: marginRight,
          marginTop: marginTop,
          padding: 5,          
          width: '100%',
          height: height,          
        }}
    >    
      {children}
    </Card>
    )
  }

  export default CardTicod;