import api from "./api";

export function stopBot(id, action, type) {
  return new Promise((resolve) => {
    api
      .post("/exchange/stopBot?exchange=binance", { id, action, type })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function stopBotReinvest(id, action, type, profit, quantity = 1) {
  return new Promise((resolve) => {
    api
      .post("/exchange/stopBot?exchange=binance", {
        id,
        action,
        type,
        profit,
        quantity,
      })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function simulateReinvest(id, type, profit, quantity = 1) {
  return new Promise((resolve) => {
    api
      .get("/exchange/simulateReinvest?exchange=binance", {
        id,
        type,
        profit,
        quantity,
      })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      });
  });
}

export function simulatePartialReinvest(id, type, setupId, investment, profit) {
  return new Promise((resolve) => {
    api
      .get("/exchange/simulatePartialReinvest?exchange=binance", {
        id,
        type,
        setupId,
        investment,
        profit,
      })
      .then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve({
          error: true,
          message:
            res ||
            res.data ||
            res.data.message ||
            res.data.error ||
            "Internal Server Error",
        });
      })
      .catch((res) => {
        resolve({
          error: true,
          message:
            res ||
            res.data ||
            res.data.message ||
            res.data.error ||
            "Internal Server Error",
        });
      });
  });
}

export function stopBotPartialReinvest(
  id,
  action,
  type,
  setupId,
  investment,
  profit
) {
  return new Promise((resolve) => {
    api
      .post("/exchange/stopBot?exchange=binance", {
        id,
        action,
        type,
        setupId,
        investment,
        profit,
      })
      .then((res) => {
        if (res && res.status === 201 && !!res.data) {
          resolve(res.data);
        }
        resolve({
          error: true,
          message:
            res ||
            res.data ||
            res.data.message ||
            res.data.error ||
            "Internal Server Error",
        });
      })
      .catch((res) => {
        resolve({
          error: true,
          message:
            res ||
            res.data ||
            res.data.message ||
            res.data.error ||
            "Internal Server Error",
        });
      });
  });
}
