import React from 'react';
import TradingViewWidget, { Themes } from "react-tradingview-widget";

const TradingWidget = (props) => {

  const { exchangeChartParam, state } = props;

  return (
    <TradingViewWidget
      symbol={`${exchangeChartParam}:${state.coinSelected.pair}`}
      interval={1}
      theme={Themes.DARK}
      locale="en"
      height={351}
      width={989.81}
      isTransparent="true"
      allowSymbolChange="true"
    />
  );
};

export default TradingWidget;