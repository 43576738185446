export const registerDefaultBot = (props, valueParam, onCLose) => {
  props.startBotAsync(props.exchangeName, valueParam).then(() => {
    props.updateLoading(false);
    onCLose(props);
  });
}

export const registerBotsGrades = (props, refFields, onCLose, newSimulation, typeBot) => {
  if (newSimulation) {
      props.changeProfitPgStep(1)
    return;
  }
  props.updateLoading(true);
  let fields = {
    type: typeBot,
    quantity: refFields.current.getFieldValue('quantity'),
    investment: refFields.current.getFieldValue('investment'),
    symbol: refFields.current.getFieldValue('symbol'),
    drop: refFields.current.getFieldValue('drop'),
    minProfit: refFields.current.getFieldValue('minProfit'),  
    profit: refFields.current.getFieldValue('profit'),

    maxPrice: refFields.current.getFieldValue('maxPrice'),
    waitBuyLimit: refFields.current.getFieldValue('waitBuyLimit'),
    buyPosition: refFields.current.getFieldValue('buyPosition'),
    buyPriceDrop: refFields.current.getFieldValue('buyPriceDrop'),
    priceDrop: refFields.current.getFieldValue('priceDrop'),
  }

  let results = [];

  if (props.profitPgStep === 1 || props.profitPgStep === 2) {
    if (typeBot === 7) {
      fields.symbol = fields.symbol.replace('/','');
      props.simulatePgProfitAsync(props.exchangeName, fields)
      .then((data) => {
          
        data.map(item =>
          results.push({
            key: item.position,
            position: item.position,
            investment: item.investment,
            minPrice: item.minPrice,
            maxPrice: item.maxPrice
          })
        )
        
        setTimeout(() => {
          props.updateLoading(false);
          props.updatePgSimulateResults(results);
          props.changeProfitPgStep(2)
        }, 2000)
  
      })
    } else if (typeBot === 2) {
      fields.symbol = fields.symbol.replace('/','');
      props.simulatePackageAsync(props.exchangeName, fields)
      .then((data) => {

        data.map(item =>
          results.push({
            key: item.position,
            position: item.position,
            investment: item.investment,
            minPrice: item.minPrice,
            maxPrice: item.maxPrice
          })
        )
        
        setTimeout(() => {
          props.updateLoading(false);
          props.updatePackageSimulateResults(results);
          props.changeProfitPgStep(2)
        }, 2000)
  
      })
    } else if (typeBot === 4) {
      fields.symbol = fields.symbol.replace('/','');
      props.simulatePlusProfitAsync(props.exchangeName, fields)
      .then((data) => {
        
        data.map(item =>
          results.push({
            key: item.position,
            position: item.position,
            investment: item.investment,
            minPrice: item.minPrice,
            maxPrice: item.maxPrice
          })
        )
        
        setTimeout(() => {
          props.updateLoading(false);
          props.updatePlusSimulateResults(results);
          props.changeProfitPgStep(2)
        }, 2000)
  
      })
    }
  }
  /* SAVE  */
  if (props.profitPgStep === 3) {
    if (props.currentBot === 2 || props.currentBot === 4) {
      fields = {...fields, investment: (fields.investment / fields.quantity)}
    }  
    props.startBotAsync(props.exchangeName, fields).then(() => {
      props.updateLoading(false);
        onCLose(props);
      });
  }
}