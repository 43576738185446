import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import {
  getOperationsMainService,
  getOperationsResumeService,
  getOperationsDetailsService,
  getOperationsFiltersContent,
  getOperationsExport
} from "../../../services/apiOperationsService";

// import { getUserById } from "../../services/apiUsersService";

export const operationsResultsSlice = createSlice({
  name: "operations",
  initialState: {
    operationsMainTotal: [],
    operationsMain: {},
    operationsResume: {},
    operationsDetails: {},
    operationsFiltersContent: {},
    operationsFiltersApplyed: {},
    operationsInfo2: {},
    operationsInfo3: {},
    operationsComponent: "",
    operationsParamsType1: {},
    operationsParamsType2: {},
    operationsParamsType3: {}
  },
  reducers: {
    setOperationsFiltersContent: (state, action) => {
      state.operationsFiltersContent = action.payload;
    },
    setOperationsMain: (state, action) => {
      state.operationsMain = action.payload;

      let listTotal = [];

      if (state.operationsMain.length) {

        state.operationsMain.forEach(
          ({ symbol, earning }) => {
            const arraySymbol = symbol.split("/");
            const objIndex = listTotal.findIndex(
              (obj) => obj.coin === arraySymbol[1]
            );
            if (objIndex > -1) {
              listTotal[objIndex].earning = (
                listTotal[objIndex].earning +
                earning
              );
            } else {
              listTotal.push({
                coin: arraySymbol[1],
                earning: earning,
              });
            }
          }
        );

        listTotal.sort((a, b) => {
          if (a.coin < b.coin) {
            return -1;
          } else if (a.coin > b.coin) {
            return 1;
          } else {
            return 0;
          }
        });
        state.operationsMainTotal = listTotal;
      } else {
        state.operationsMainTotal = [];
      }
    },
    setOperationsResume: (state, action) => {
      state.operationsResume = action.payload;
    },
    setOperationsDetails: (state, action) => {
      state.operationsDetails = action.payload;
    },
    setOperationsFiltersApplyed: (state, action) => {
      state.operationsFiltersApplyed = JSON.parse(action.payload);
    },
    setOperationsInfo2: (state, action) => {
      state.operationsInfo2 = action.payload;
    },
    setOperationsInfo3: (state, action) => {
      state.operationsInfo3 = action.payload;
    },
    setOperationsComponent: (state, action) => {
      state.operationsComponent = action.payload;
    },
    setOperationsParamsType1: (state, action) => {
      state.operationsParamsType1 = action.payload;
    },
    setOperationsParamsType2: (state, action) => {
      state.operationsParamsType2 = action.payload;
    },
    setOperationsParamsType3: (state, action) => {
      state.operationsParamsType3 = action.payload;
    }
  },
});

const {
  setOperationsFiltersContent,
  setOperationsMain,
  setOperationsResume,
  setOperationsDetails,
  setOperationsFiltersApplyed,
  setOperationsInfo2,
  setOperationsInfo3,
  setOperationsComponent,
  setOperationsParamsType1,
  setOperationsParamsType2,
  setOperationsParamsType3
} = operationsResultsSlice.actions;

export const setOperationsFiltersContentAsync = () => async (dispatch) => {

  return getOperationsFiltersContent()
    .then((res) => {
      dispatch(setOperationsFiltersContent(res));
    })
}

export const setOperationsMainAsync = (filters) => async (dispatch) => {

  dispatch(setOperationsFiltersApplyed(filters));

  return getOperationsMainService(filters)
    .then((res) => {
      dispatch(setOperationsMain(res.data));
      dispatch(setOperationsParamsType1(res.params));
      dispatch(setOperationsComponent("main"));
    })
}

export const setOperationsResumeAsync = (globalProps, params) => async (dispatch) => {

  if (params.onlyExport) {
    return getOperationsResumeService(globalProps, params)
      .then((res) => dispatch(setOperationsParamsType2(res.config)))
  }

  return getOperationsResumeService(globalProps, params)
    .then((res) => {
      dispatch(setOperationsInfo2(params));
      dispatch(setOperationsResume(res.data));
      dispatch(setOperationsParamsType2(res.config));
      dispatch(setOperationsComponent("resume"));
    })
}

export const setOperationsDetailsAsync = (globalProps, params) => async (dispatch) => {

  if (params.onlyExport) {
    return getOperationsDetailsService(globalProps, params)
      .then((res) => dispatch(setOperationsParamsType3(res.config)))
  }

  return getOperationsDetailsService(globalProps, params)
    .then((res) => {
      dispatch(setOperationsInfo3(params));
      dispatch(setOperationsDetails(res.data));
      dispatch(setOperationsParamsType3(res.config));
      dispatch(setOperationsComponent("details"));
    })
}

export const setOperationsInfo2Async = (info) =>
  setOperationsInfo2(info);

export const setOperationsInfo3Async = (info) =>
  setOperationsInfo3(info);

export const setOperationsComponentAsync = (key) =>
  setOperationsComponent(key);

export const setOperationsExportAsync = (params) => async () => {

  if (params.fully) {
    params = { ...params, type: 2, symbol: null }
  }

  return getOperationsExport(params)
    .then((res) => {

      if (res.download) {
        const linkSource = 'data:application/pdf;base64,' + res.download;
        const downloadLink = document.createElement("a");

        const now = moment().format("DD-MM-YYYY--HH:mm:ss");
        const fileName = `reports-ticod-type${params.type}-${now}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        return true;
      }

      return false;
    })
}

export default operationsResultsSlice.reducer;