import i18next from "i18next";
import "../robotViewer.css";
import {
  Button,
  Row,
  Col,  
  Space,  
  InputNumber,
  Form,
  Spin,
  Card,
  Skeleton,
  Input 
} from "antd";

export function DescriptionModal(formRef, onDescriptionModalFinish, props, state, onProfitChange, onInvestmentChange) {  
  return (
    <div style={{ minHeight: 190 }}>
      <Form
        ref={formRef}
        name="control-ref"
        layout="horizontal"
        onFinish={(valueParam) => {
        onDescriptionModalFinish(valueParam, state)
        }}
      >
        <Space style={{ width: '100%' }} size={"small"} direction="vertical">
          <div className="header-format-reinvest">
            <Form.Item 
              className="format-reinvest"
              label={i18next.t("trades.register.label.symbol")}
              >
              <Input disabled={true} value={props.robot.symbol}/> 
            </Form.Item>
        
            <Form.Item
              className="format-reinvest"
              name="profit"
              label={i18next.t("trades.view.reinvest.market_correction")}
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={props.profit || null}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                max={100}
                step={0.1}
                decimalSeparator="."
                precision={2}
                onChange={onProfitChange}
              />
            </Form.Item>
              
            <Row className="header-format-reinvest"  style={{width: '100%'}}>
              <div className="header-format-reinvest" style={{width: '100%'}}>
                <Form.Item
                  className="format-reinvest"
                  name="investment"
                  label={i18next.t("trades.view.reinvest.investment")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  initialValue={props.investment || null}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    step={0.1}
                    decimalSeparator="."
                    precision={2}
                    onChange={onInvestmentChange}
                  />
                  </Form.Item>
                  <div className="button-simu">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={state.calculating}
                      style={{ marginLeft: 18 }}
                    >
                    {i18next.t("trades.view.reinvest.calculate")}
                  </Button>
                </div>
              </div>
            </Row>
          </div>
        </Space>
      </Form>

      <div style={{ paddingTop: 21 }}>
        <Row>
          <Col>
            <Spin
              spinning={state.calculating}
              tip={i18next.t("trades.stop.reinvest.calculating")}
            >
              <Card
                size="small"
                title={i18next.t("trades.view.reinvest.current_caption")}
                style={{ width: 330 }}
              >
                {props.partialReinvestInfo &&
                  props.partialReinvestInfo.calculation ? (
                  <>
                    <Row>
                      <Col span={12}>
                        {i18next.t("trades.view.reinvest.current.amount")}
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        {props.partialReinvestInfo.setupPosition.quantity}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t("trades.view.reinvest.current.investment")}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.partialReinvestInfo.setupPosition.value}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t("trades.view.reinvest.current.bought_price")}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.partialReinvestInfo.setupPosition.price}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t("trades.view.reinvest.current.price")}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.partialReinvestInfo.info.marketPrice}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t("trades.view.reinvest.current.position")}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.partialReinvestInfo.calculation.loss}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Skeleton title={false} paragraph={{ rows: 4 }} />
                )}
              </Card>
            </Spin>
          </Col>
          <Col style={{ alignSelf: "center", fontSize: 21, margin: 9 }}></Col>
          <Col>
            <Spin
              spinning={state.calculating}
              tip={i18next.t("trades.stop.reinvest.calculating")}
            >
              <Card
                size="small"
                title={i18next.t("trades.view.reinvest.recovery_caption")}
                style={{ width: 330 }}
              >
                {props.partialReinvestInfo &&
                  props.partialReinvestInfo.calculation ? (
                  <>
                    <Row>
                      <Col span={12}>
                        {i18next.t("trades.view.reinvest.recovery.amount")}
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        {props.partialReinvestInfo.buy.quantity}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t("trades.view.reinvest.recovery.investment")}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.partialReinvestInfo.info.investment}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t(
                          "trades.view.reinvest.recovery.bought_price"
                        )}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.partialReinvestInfo.info.marketPrice}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t(
                          "trades.view.reinvest.recovery.selling_price"
                        )}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.partialReinvestInfo.buy.sellPrice}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t(
                          "trades.view.reinvest.recovery.operation_profit"
                        )}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.partialReinvestInfo.calculation.gain}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t(
                          "trades.view.reinvest.recovery.operation_amount"
                        )}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {props.partialReinvestInfo.calculation.quantity}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>
                        {i18next.t(
                          "trades.view.reinvest.recovery.total_profit"
                        )}
                      </Col>
                      <Col span={14} style={{ textAlign: "right" }}>
                        {
                          props.partialReinvestInfo.calculation
                            .totalEarning
                        }
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Skeleton title={false} paragraph={{ rows: 4 }} />
                )}
              </Card>
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  );
};