import { createSlice } from "@reduxjs/toolkit";
import {
  getAccount,
  createAccount,
  editAccount,
  deleteAccount,
  getBalanceInfo,
} from "../../services/apiExchangeService";

export const exchangeSlice = createSlice({
  name: "exchanges",
  initialState: {
    listAccount: [0],    
    balance: {},
  },
  reducers: {
    setListAccountPayload: (state, action) => {
      state.listAccount = action.payload;
    },
    addAccountPayload: (state, action) => {
      state.listAccount = [...state.listAccount, action.payload];
    },
    deleteAccountPayload: (state, action) => {
      state.listAccount = state.listAccount.filter(
        (account) => account.id !== action.payload
      );
    },
    setBalancePayload: (state, action) => {
      state.balance = action.payload;
    },
  },
});

const {
  setListAccountPayload,
  addAccountPayload,
  deleteAccountPayload,
  setBalancePayload,  
} = exchangeSlice.actions;

export const getAccountAsync = () => (dispatch) => {   
  return new Promise((resolve) => {
    getAccount().then((recordList) => {    
      if (recordList.statusCode === 404) {
        dispatch(setListAccountPayload([1]));    
        resolve({ error: recordList.statusCode });      
      } else {
        dispatch(setListAccountPayload(recordList));
      }
      resolve();    
    });  
  });   
};

export const createAccountAsync = (exchange, account) => (dispatch) => {
  return new Promise((resolve) => {
    createAccount(exchange, account).then((record) => {
      const message = record.message;
      const error = record.error;
      if (!!error) {
        resolve({ error, message });
      } else {
        dispatch(addAccountPayload(record));
      }
      resolve();
    });
  });
};

export const editAccountAsync = (exchange, account) => () => {
  return editAccount(exchange, account);
};

export const deleteAccountAsync = (id) => (dispatch) => {
  return deleteAccount(id).then(() => {
    dispatch(deleteAccountPayload(id));
  });
};

//TODO: não tem rota ou coisas do gerero para retornar as exchanges
export const getBalanceInfoAsync = (userId, exchange = "binance") => (dispatch) => {
  return getBalanceInfo(userId, exchange).then((record) => {
    dispatch(setBalancePayload(record));
  });
};

export default exchangeSlice.reducer;
