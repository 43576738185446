//import api from "./api";

export function findAllExchange() {
  return new Promise((resolve) => {
    //TODO: simulando, ainda não há chamada para o back
    setTimeout(() => {
      resolve([
        { id: "binance", name: "Binance" },
        { id: "novadax", name: "NovaDax" },
        { id: "mercado", name: "Mercado Bitcoin" },
      ]);
    }, 1000);
  });
}
