import api from "./api";

export function getBotList(params) {
  return new Promise((resolve) => {
    api.get("/exchange/getBotList", params).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}
