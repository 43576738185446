import React from "react";
import i18next from "i18next";
// import './robotRegisterFields.css'
import { Form, InputNumber, Tooltip, Select } from "antd";
const { Option } = Select;

/** 
 * Field: Investimento
**/
export const FieldInvestment = (params) => {

  const { valueParam } = params.props;
  const isMobile = params.state.windowSize[0] < 800 ? "coin-format-editing-field-mobile" : "coin-format-editing-field" ;

  return (
    <Tooltip mouseEnterDelay="1"
      title={i18next.t("trades.register.hint.investment")}
      placement="topRight"
    >
      <Form.Item      
        name="investment"
        initialValue={ valueParam.investment ? valueParam.investment : '' }
        label={i18next.t("trades.register.label.investment")}           
        rules={[
          {
            required: true,
          },
        ]}
        className="coin-format-editing" 
        style={{ marginRight: 5}}       
      >
        <InputNumber
          className={isMobile}  
          style={{width: '100%'}}
          min={0}
          step={0.1}
          decimalSeparator="."
          precision={
            (params.props.coinSelected &&
              params.props.coinSelected.price_precision) ||
            9
          }
          disabled={!!params.props.editing ? true : false}
          placeholder={i18next.t(
            "trades.register.placeholder.investment"
          )}
        />
      </Form.Item>
    </Tooltip>
  )
}

/** 
 * Field: Investimento Total
**/
export const FieldInvestmentTotal = (params) => {

  const { valueParam } = params.props;
  const isMobile = params.state.windowSize[0] < 800 ? "coin-format-editing-field-mobile" : "coin-format-editing-field" ;

  return (
    <Tooltip mouseEnterDelay="1"
      title={i18next.t("trades.register.hint.investmentTotal")}
      placement="topRight"
    >
      <Form.Item
        name="total"
        initialValue={ valueParam.total ? valueParam.total : '' }
        label={i18next.t("trades.register.label.investmentTotal")}
        rules={[
          {
            required: true,
          },
        ]}
        className="coin-format-editing"
        style={{ marginRight: 5}}          
      >
        <InputNumber          
          className={isMobile}    
          min={0}
          step={0.1}
          decimalSeparator="."
          precision={
            (params.props.coinSelected &&
              params.props.coinSelected.price_precision) ||
            9
          }
          disabled={!!params.props.editing ? true : false}
          placeholder={i18next.t(
            "trades.register.placeholder.investmentTotal"
          )}
        />
      </Form.Item>
    </Tooltip>
  )
}

/** 
 * Field: Quantidade
**/
export const FieldQuantity = (params) => {

  const { valueParam } = params.props;
  const isMobile = params.state.windowSize[0] < 800 ? "coin-format-editing-field-mobile" : "coin-format-editing-field" ;

  return (
    <Tooltip mouseEnterDelay="1"
      title={i18next.t("trades.register.hint.quantity")}
      placement="topRight"
    >
      <Form.Item
        name="quantity"
        initialValue={ valueParam.quantity ? valueParam.quantity : '' }
        label={i18next.t("trades.register.label.quantity")}
        rules={[
          {
            required: true,
          },
        ]}
        className="coin-format-editing"
        style={{ marginRight: 5}}
      >
        <InputNumber          
          className={isMobile}   
          min={0}
          disabled={!!params.props.editing ? true : false}
          placeholder={i18next.t("trades.register.placeholder.quantity")}
        />
      </Form.Item>
    </Tooltip>
  )
}

/** 
 * Field: Lucro Mínimo
**/
export const FieldMinProfit = (params) => {

  const { valueParam } = params.props;
  const isMobile = params.state.windowSize[0] < 800 ? "coin-format-editing-field-mobile" : "coin-format-editing-field" ;

  return (
    <Tooltip mouseEnterDelay="1"
      title={i18next.t("trades.register.hint.minProfit")}
      placement="topRight"
    >
      <Form.Item
        name="minProfit"
        label={i18next.t("trades.register.label.minProfit")}
        initialValue={ valueParam.minProfit ? valueParam.minProfit : '' }
        rules={[
          {
            required: true,
          },
        ]}
        className="coin-format-editing"
        style={{ marginRight: 5}}
      >
        <InputNumber   
          className={isMobile}           
          min={0}
          max={100}
          step={0.1}
          decimalSeparator="."
          precision={2}
          placeholder={i18next.t("trades.register.placeholder.minProfit")}
        />
      </Form.Item>
    </Tooltip>
  )
}

/** 
 * Field: Queda de Preço %
**/
export const FieldPriceDrop = (params) => {

  const { valueParam } = params.props;
  const isMobile = params.state.windowSize[0] < 800 ? "coin-format-editing-field-mobile" : "coin-format-editing-field" ;

  return (
    <Tooltip mouseEnterDelay="1"
      title={i18next.t("trades.register.hint.priceDrop")}
      placement="topRight"
    >
      <Form.Item
        name="priceDrop"
        label={i18next.t("trades.register.label.priceDrop")}
        initialValue={ valueParam.priceDrop ? valueParam.priceDrop : '' }
        rules={[
          {
            required: true,
          },
        ]}
        className="coin-format-editing"
      >
        <InputNumber   
          className={isMobile}          
          min={0}
          max={100}
          step={0.1}
          decimalSeparator="."
          precision={2}
          placeholder={i18next.t("trades.register.placeholder.priceDrop")}
        />
      </Form.Item>
    </Tooltip>
  )
}

/** 
 * Field: Queda de preço % compra
**/
export const FieldBuyPriceDrop = (params) => {

  const { valueParam } = params.props;
  const isMobile = params.state.windowSize[0] < 800 ? "coin-format-editing-field-mobile" : "coin-format-editing-field" ;

  return (
    <Tooltip mouseEnterDelay="1"
      title={i18next.t("trades.register.hint.buyPriceDrop")}
      placement="topRight"
    >
      <Form.Item
        name="buyPriceDrop"
        label={i18next.t("trades.register.label.buyPriceDrop")}
        initialValue={ valueParam.buyPriceDrop ? valueParam.buyPriceDrop : '' }
        rules={[
          {
            required: true,
          },
        ]}
        className="coin-format-editing"
        style={{ marginRight: 5}}
      >
        <InputNumber
          className={isMobile}     
          style={{ width: "170px" }}
          min={0}
          max={100}
          step={0.1}
          decimalSeparator="."
          precision={2}
          placeholder={i18next.t(
            "trades.register.placeholder.buyPriceDrop"
          )}
        />
      </Form.Item>
    </Tooltip>
  )
}

/** 
 * Field: Queda
**/
export const FieldDrop = (params) => {

  const { valueParam } = params.props;
  const isMobile = params.state.windowSize[0] < 800 ? "coin-format-editing-field-mobile" : "coin-format-editing-field" ;

  return (
    <Tooltip mouseEnterDelay="1"
      title={i18next.t("trades.register.hint.drop")}
      placement="topRight"
    >
      <Form.Item
        name="drop"
        label={i18next.t("trades.register.label.drop")}
        initialValue={ valueParam.drop ? valueParam.drop : '' }
        rules={[
          {
            required: true,
          },
        ]}
        className="coin-format-editing"
      >
        <InputNumber
          className={isMobile}    
          min={0}
          max={100}
          step={0.1}
          decimalSeparator="."
          precision={2}
          disabled={!!params.props.editing ? true : false}
          placeholder={i18next.t("trades.register.placeholder.drop")}
        />
      </Form.Item>
    </Tooltip>
  )
}

/** 
 * Field: Posições
**/
export const FieldBuyPosition = (params) => {

  const { valueParam } = params.props;
  const isMobile = params.state.windowSize[0] < 800 ? "coin-format-editing-field-mobile" : "coin-format-editing-field" ;

  return (
    <Tooltip mouseEnterDelay="1"
      title={i18next.t("trades.register.hint.buyPosition")}
      placement="topRight"
    >
      <Form.Item
        name="buyPosition"
        label={i18next.t("trades.register.label.buyPosition")}
        initialValue={ valueParam.buyPosition ? valueParam.buyPosition : '' }
        rules={[
          {
            required: true,
          },
        ]}
        style={{ marginRight: 5}}
        className="coin-format-editing"
      >
        <InputNumber    
          className={isMobile}          
          min={0}
          placeholder={i18next.t(
            "trades.register.placeholder.buyPosition"
          )}
        />
      </Form.Item>
    </Tooltip>
  )
}

/** 
 * Field: Preço máximo
**/
export const FieldMaxPrice = (params) => {

  const { valueParam } = params.props;
  const isMobile = params.state.windowSize[0] < 800 ? "coin-format-editing-field-mobile" : "coin-format-editing-field" ;

  return (
    <Tooltip mouseEnterDelay="1"
      title={i18next.t("trades.register.hint.maxPrice")}
      placement="topRight"
    >
      <Form.Item
        name="maxPrice"
        label={i18next.t("trades.register.label.maxPrice")}
        initialValue={ valueParam.maxPrice ? valueParam.maxPrice : '' }        
        rules={[
          {
            required: true,
          },
        ]}
        className="coin-format-editing"
        style={{ marginRight: 5}}
      >
        <InputNumber          
          className={isMobile}          
          min={0}
          step={0.1}          
          decimalSeparator="."
          precision={
            (params.props.coinSelected &&
              params.props.coinSelected.price_precision) ||
            9
          }
          placeholder={i18next.t("trades.register.placeholder.maxPrice")}
        />
      </Form.Item>
    </Tooltip>
  )
}

/** 
 * Field: Limite de espera
**/
export const FieldWaitBuyLimit = (params) => {

  const { valueParam } = params.props;
  const isMobile = params.state.windowSize[0] < 800 ? "coin-format-editing-field-mobile" : "coin-format-editing-field" ;

  return (
    <Tooltip mouseEnterDelay="1"
      title={i18next.t("trades.register.hint.waitBuyLimit")}
      placement="topRight"
    >
      <Form.Item
        name="waitBuyLimit"
        label={i18next.t("trades.register.label.waitBuyLimit")}
        initialValue={ valueParam.waitBuyLimit ? valueParam.waitBuyLimit : '' }
        rules={[
          {
            required: true,
          },
        ]}
        className="coin-format-editing"
      >
        <InputNumber
          className={isMobile}     
          min={0}
          placeholder={i18next.t(
            "trades.register.placeholder.waitBuyLimit"
          )}
        />
      </Form.Item>
    </Tooltip>
  )
}

/** 
 * Field: Lucro
**/
export const FieldProfit = (params) => {

  const { valueParam } = params.props;
    const isMobile = params.state.windowSize[0] < 800 ? "coin-format-editing-field-mobile" : "coin-format-editing-field" ;

  return (  
    <Tooltip mouseEnterDelay="1"
      title={i18next.t("trades.register.hint.profit")}
      placement="topRight"
    >
      <Form.Item
        name="profit"
        label={i18next.t("trades.register.label.profit")}
        initialValue={ valueParam.profit ? valueParam.profit : '' }
        rules={[
          {
            required: true,
          },
        ]}
        className="coin-format-editing"
        style={{ marginRight: 5}}
      >
        <InputNumber
          className={isMobile}    
          min={0}
          max={100}
          step={0.1}
          decimalSeparator="."
          precision={2}
          placeholder={i18next.t("trades.register.placeholder.profit")}
        />
      </Form.Item>
    </Tooltip>
  )
}  

/** 
 * Field: Moeda
**/
export const FieldSymbol = (params) => {

  return (
    <Form.Item
    name="symbol"
    label={i18next.t("trades.register.label.symbol")}
    rules={[
      {
        required: true,
      },
    ]}
    initialValue={
      // (valueParam.symbol || 
      ((params.props.coinSelected) && (params.props.coinSelected.name)) || null
    }
    className="coin-format-editing"
    >
      <Select
        className="coin-format-editing-field"   
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled
      >
        {params.props.allSymbol.map((item) => (
          <Option key={item.name} value={item.name}>
            {item.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}  

/** 
 * Field: Tipo do Robo
**/
export const FieldTypeBot = (params) => {
  return (
  <Tooltip mouseEnterDelay="1"
    title={i18next.t("trades.register.hint.type")}
    placement="topRight"
    >
    <Form.Item
      name="type"
      label={i18next.t("trades.register.label.type")}      
      rules={[
        {
          required: true,
        },
      ]}
      initialValue={
        (params.props.botTypeList && params.props.botTypeList.id) || 
        (params.props.currentBot) || null
      }
      className="coin-format-editing"      
    >
      <Select
        className="coin-format-editing"      
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
        disabled={!!params.props.editing ? true : false}
        placeholder={i18next.t("trades.register.placeholder.type")}        
      >
        {params.props.botTypeList.map((item, key) => (
          <Option key={key} value={item.type}>
            {item.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  </Tooltip>
  )
}  