import React, {useState, useEffect} from "react";
import "../exchanges.css";
import i18next from "i18next";

import binance from "../../../assets/exchanges/binance.png";
import mercado from "../../../assets/exchanges/mercadobitcoin.jpeg";
import novadax from "../../../assets/exchanges/novadax.jpeg";

import {
  Row,
  Col,
  Card,
  Avatar,
  Button,
  Popconfirm 
} from "antd";

import {
  DeleteOutlined,
  EditOutlined,
  WalletOutlined  
} from "@ant-design/icons";

const { Meta } = Card;

const listLogoExchanges = {
  binance,
  mercado,
  novadax,
};  

const ListExchange = (params) => {  
  const [checkedExchange, setCheckedExchange] = useState(false);
  const props = params.props;

  const handleCheckedExchange = (id) => {
    setCheckedExchange(id)    
  }

  useEffect(() => {   
    if (props.listAccount.length > 0) {
      setCheckedExchange(props.listAccount[0].id)
    }         
  }, [props.listAccount])
  
  return (
    <Row className="list-exchanges">
      { props.listAccount[0] !== 0 && (props.listAccount[0] !== 1 ? props.listAccount.map((account) => (
        <Col
          key={`col_${account.name}_${new Date().getTime()}`}
          span={6}
          style={{ paddingTop: 9,  display: 'flex', flexDirection: 'column'}}
        >
          <Card
            style={{ width: '400%', border: `2px solid ${account.id === checkedExchange? '#0E83D8' : '#303030'}` }}
            actions={[
              <Popconfirm
                title={i18next.t("exchange.message.delete")}
                onConfirm={() => {
                  params.onDelete(account.id);
                }}
                okText={i18next.t("button.yes")}
                cancelText={i18next.t("button.no")}
              >
                <Button type="link">
                  <DeleteOutlined key="delete" />
                </Button>
              </Popconfirm>,
              <Button
                type="link"
                onClick={() => params.registerExchange(account)}
              >
                <EditOutlined key="edit" />
              </Button>,
              <Button type="link"   onClick={() => handleCheckedExchange(account.id)}>
                <WalletOutlined key="wallet" />
              </Button>,
            ]}
          >
            <Meta
              avatar={
                <Avatar
                  src={
                    listLogoExchanges[params.getExchangeId(account.name)]
                  }
                />
              }
              title={account.name}
              description={
                <>
                  <Row>
                    <span style={{ height: 18 }}>
                      {props.balance.btcQuantity}
                    </span>
                  </Row>
                  <Row>
                    <span style={{ height: 18 }}>
                      {props.balance.coinQuantity}
                    </span>
                  </Row>
                </>
              }
            />
          </Card>
        </Col>
      )): <> </>)} 
    </Row>
  )
}

export default ListExchange;