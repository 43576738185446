import { Fragment } from "react";
import i18next from "i18next";
import "./tables.css"
import { Table, Space, Button, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { convertValuesCoins } from "../../../shared/utils/convertValuesCoins";

import { 
  ExportMiniButtonMain, 
  ExportMiniButtonResume 
} from "./exportButton";

export const tableMainColumns = (globalProps) => {

  const handleViewClick = (params) => {
    globalProps.changeLoading(true);
    globalProps.setOperationsResumeAsync(globalProps, JSON.parse(params));
  }

  const stringSorter = (a, b) => {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }   
  
  return [
    { 
      title: `${i18next.t("portfolio.operations.tableMain.columns.exchange")}`,
      dataIndex: "exchange",
      key: "exchange" 
    },
    { 
      title: `${i18next.t("portfolio.operations.tableMain.columns.symbol")}`,
      dataIndex: "symbol",
      key: "symbol",
      sorter: (a, b) => stringSorter(a.symbol, b.symbol),
      },
    { 
      title: `${i18next.t("portfolio.operations.tableMain.columns.earning")}`,
      dataIndex: "earning",
      key: "earning",
      render: (text) => <span>{convertValuesCoins(text)}</span>,      
      sorter: (a, b) => a.earning - b.earning, 
    },
    {
      title: "",
      key: "action",
      width: "11%",
      render: (record) => (
        <Space size="middle" className="buttonsMain">
          <Tooltip mouseEnterDelay="1" title={i18next.t("portfolio.operations.tooltips.view")}>
            <Button 
              onClick={() => handleViewClick(JSON.stringify(record))} 
              type="secondary" 
              style={{ width: '26px'}} 
              // shape="round" 
              icon={<EyeOutlined />}               
            />
          </Tooltip>

          <Tooltip title={i18next.t("portfolio.operations.tooltips.download")}>
            <ExportMiniButtonMain {...{record, global: globalProps}} />
          </Tooltip>
        </Space>
      ),
    },
  ]
};

export const tableResumeColumns = (globalProps) => {

  const handleViewClick = (params) => {
    globalProps.changeLoading(true);
    globalProps.setOperationsDetailsAsync(globalProps, JSON.parse(params));
  }
  return [
    { 
      title: `${i18next.t("portfolio.operations.tableResume.columns.date")}`,
      dataIndex: "date",
      key: "date" 
    },
    { 
      title: `${i18next.t("portfolio.operations.tableResume.columns.duration")}`,
      dataIndex: "duration",
      key: "duration" 
    },
    { 
      title: `${i18next.t("portfolio.operations.tableResume.columns.exchange")}`,
      dataIndex: "exchange",
      key: "exchange" 
    },
    { 
      title: `${i18next.t("portfolio.operations.tableResume.columns.bot")}`,
      dataIndex: "bot",
      key: "bot" 
    },
    { 
      title: `${i18next.t("portfolio.operations.tableResume.columns.investment")}`,
      dataIndex: "investment",
      key: "investment" 
    },
    { 
      title: `${i18next.t("portfolio.operations.tableResume.columns.earning")}`,
      dataIndex: "earning",
      key: "earning",
      render: (text) => <span>{convertValuesCoins(text)}</span>,
      sorter: (a, b) => a.earning - b.earning, 
    },
    {
      title: "",
      key: "action",
      width: "11%",
      render: (record) => (
        <Space size="middle" className="buttonsResume">
          <Tooltip mouseEnterDelay="1" title={i18next.t("portfolio.operations.tooltips.view")}>
            <Button 
              onClick={() => handleViewClick(JSON.stringify(record))} 
              type="secondary"
              style={{ width: '26px'}} 
              icon={<EyeOutlined />} 
            />
          </Tooltip>

          <Tooltip title={i18next.t("portfolio.operations.tooltips.download")}>
            <ExportMiniButtonResume {...{record, global: globalProps}} />
          </Tooltip>
        </Space>
      ),
    },
  ];
};

export const tableDetailsColumns = () => {

  const { Column, ColumnGroup } = Table;

  return (
    <Fragment>      
        <ColumnGroup className="columnGroup" title={i18next.t("portfolio.operations.tableDetails.columns.buyGroup")}>
          <Column 
            className="columnDetailsBuy"
            title={i18next.t("portfolio.operations.tableDetails.columns.date")}
            dataIndex="buyDate"
            key="buyDate"
          />
          <Column 
            className="columnDetailsBuy"
            title={i18next.t("portfolio.operations.tableDetails.columns.qty")}
            dataIndex="buyQty"
            key="buyQty"
          />
          <Column 
            className="columnDetailsBuy"
            title={i18next.t("portfolio.operations.tableDetails.columns.price")}
            dataIndex="buyPrice"
            key="buyPrice"          
          />
          <Column 
            className="columnDetailsBuy"
            title={i18next.t("portfolio.operations.tableDetails.columns.total")}
            dataIndex="buyTotal"
            key="buyTotal"
          />
        </ColumnGroup>  

        <ColumnGroup className="columnGroup" title={i18next.t("portfolio.operations.tableDetails.columns.sellGroup")}>
          <Column 
            className="columnDetailsSell"
            title={i18next.t("portfolio.operations.tableDetails.columns.date")}
            dataIndex="sellDate"
            key="sellDate" 
          />
          <Column title={i18next.t("portfolio.operations.tableDetails.columns.qty")}
          className="columnDetailsSell"
            dataIndex="sellQty"
            key="sellQty" 
          />
          <Column 
            className="columnDetailsSell"
            title={i18next.t("portfolio.operations.tableDetails.columns.price")}
            dataIndex="sellPrice"
            key="sellPrice" 
          />
          <Column 
            className="columnDetailsSell"
            title={i18next.t("portfolio.operations.tableDetails.columns.total")}
            dataIndex="sellTotal"
            key="sellTotal" 
          />
       </ColumnGroup>  

      <ColumnGroup className="columnGroup" title={i18next.t("portfolio.operations.tableDetails.columns.resultGroup")}>
        <Column                     
          title={i18next.t("portfolio.operations.tableDetails.columns.resultTax")}
          dataIndex="resultTax"
          key="resultTax" 
        />
        <Column           
          title={i18next.t("portfolio.operations.tableDetails.columns.resultPercent")}
          dataIndex="resultPercent"
          key="resultPercent" 
        />
        <Column           
          title={i18next.t("portfolio.operations.tableDetails.columns.resultTotal")}
          dataIndex="resultTotal"
          key="resultTotal" 
        />
      </ColumnGroup>
    </Fragment>
  )
};