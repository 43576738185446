import api from "./api";

export function setPlanUser(planId) {
  return new Promise((resolve) => {
    api.post("user/setPlan", {planId: planId})
    .then((res) => {
      if (res && !!res.data) {
        resolve(res.data);
      }
      resolve([]);
    });
  });
}

export function getPlans() {
  return new Promise((resolve) => {    
    api.get("/user/plan/findAll").then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      }); 
  });
}

export function getPlansUser() {
  return new Promise((resolve) => {    
    api.get("/user/info").then((res) => {
        if (res && res.status === 200 && !!res.data) {
          resolve(res.data);
        }
        resolve([]);
      }); 
  });
}

export function getActiveBots(exchange) {
  return new Promise((resolve) => {
    api.get("/exchange/dashboardActiveBots", { exchange }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
        resolve([]);
      });
    });
  }

export function getActiveOperations(exchange) {
  return new Promise((resolve) => {
    api.get("/exchange/dashboardOrders", { exchange }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
        resolve([]);
      });
    });
  }

export function getAccompaniments(exchange) {
  return new Promise((resolve) => {
    api.get("/exchange/planResults", { exchange }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
        resolve([]);
      });
    });
  }
  
export function getAccompanimentsDetails(exchange, id) {
  return new Promise((resolve) => {
    api.get("/exchange/planResultsDetail", { exchange, id }).then((res) => {
      if (res && res.status === 200 && !!res.data) {
        resolve(res.data);
      }
        resolve([]);
      });
    });
  }  